export const BASE_URL =
    process.env.GATSBY_BASE_URL || 'https://approvals.netlify.app';
export const LAMBDA_URL = process.env.GATSBY_LAMBDA_URL || BASE_URL;
export const DEFAULT_REDIRECT_PATH = '/app/profile';

export const FILTER_NUM_MONTHS_REVIEW_MODE = 6;
export const FILTER_NUM_MONTHS = 3;

export const FRESHBOOKS_ACCESS_TOKEN_URI =
    'https://api.freshbooks.com/auth/oauth/token';
