import { Button, Dialog, Grid, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import React from 'react';

function ButtonWithConfirmation({
    buttonText,
    dialogTitle,
    dialogText,
    confirmText,
    cancelText = 'Cancel',
    onConfirm,
    component,
    startIcon,
}: {
    buttonText: string;
    dialogTitle: string;
    dialogText: string;
    confirmText: string;
    cancelText?: string;
    onConfirm: () => void;
    component?: any;
    startIcon?: React.ReactNode;
}) {
    const [invoiceDialogOpen, setInvoiceDialogOpen] = React.useState(false);

    const handleClickConfirm = () => {
        handleClose();
        onConfirm();
    };

    const handleClickOpen = () => {
        setInvoiceDialogOpen(true);
    };

    const handleClose = () => {
        setInvoiceDialogOpen(false);
    };

    return (
        <Grid>
            <Button component={component} startIcon={startIcon} onClick={handleClickOpen}>
                {buttonText}
            </Button>
            <Dialog
                open={invoiceDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{dialogText}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickConfirm} color="primary">
                        {confirmText}
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        {cancelText}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

export default ButtonWithConfirmation;
