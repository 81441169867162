import { UpdateData } from '@firebase/firestore-types';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
} from '@material-ui/core';
import {
    Delete as DeleteIcon,
    Edit as EditIcon,
    Save as SaveIcon,
} from '@material-ui/icons';
import classnames from 'classnames';
import {
    capitalize,
    debounce,
    isEmpty,
    merge,
    set,
    startCase,
} from 'lodash-es';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import {
    isLoaded,
    useFirestore,
    useFirestoreConnect,
} from 'react-redux-firebase';

import { DEFAULT_RATE_TYPES_MAP, RATES_COLLECTION } from '../../constants';
import { rateList } from '../../store/selectors';
import { RateObject, RateTabObject } from '../../types';
import { PageTitle } from '../PageTitle';
import Spinner from '../Spinner';
import { useStyles } from './RateManagement.styles';
import {
    RateActionBarProps,
    RateDetailsPanelProps,
    RateLineProps,
    RatePrefDiscountDollarProps,
    RatePrefDiscountPercentProps,
    RateSectionProps,
    RatesListPanelProps,
    RateTypeSelectionProps,
    RateTypeTabProps,
} from './RateManagement.types';

const RateManagement = () => {
    const classes = useStyles();
    const firestore = useFirestore();

    useFirestoreConnect(() => [{ collection: RATES_COLLECTION }]);
    const rates = useSelector(rateList) || {};
    const ratesLoaded = isLoaded(rates);

    const [selectedRateId, setSelectedRateId] = React.useState('');
    const selectedRate = rates[selectedRateId];

    const handleDeleteRate = React.useCallback(() => {
        const docRef = firestore.doc(`${RATES_COLLECTION}/${selectedRateId}`);
        docRef.delete().then(() => {
            setSelectedRateId('');
        });
    }, [firestore, selectedRateId]);

    if (!ratesLoaded || (selectedRate && !selectedRate)) {
        return <Spinner />;
    }

    return (
        <Grid container direction="row" className={classes.root}>
            <RatesListPanel
                rates={rates}
                selectedRateId={selectedRateId}
                onSelect={setSelectedRateId}
            />
            <RateDetailsPanel
                rates={rates}
                selectedRateId={selectedRateId}
                selectedRate={selectedRate}
                onDeleteRate={handleDeleteRate}
            />
        </Grid>
    );
};

function RatesListPanel({
    rates,
    selectedRateId,
    onSelect,
}: RatesListPanelProps) {
    const classes = useStyles();
    const firestore = useFirestore();
    const ratesCollectionRef = firestore.collection(RATES_COLLECTION);
    const searchRef = React.useRef<any>(null);
    const getFilteredRates = () => {
        const searchQuery = searchRef?.current?.value?.toLowerCase() || '';
        const filtered = Object.entries(rates).filter(([, rate]) =>
            rate?.meta?.label?.toLowerCase().includes(searchQuery),
        );
        return filtered;
    };
    const [filteredRates, setFilteredRates] = React.useState(
        Object.entries(rates),
    );

    const onFilterChange = debounce(() => {
        setFilteredRates(getFilteredRates());
    }, 300);

    React.useEffect(() => {
        setFilteredRates(getFilteredRates());
    }, [rates]);

    const handleListItemClick = React.useCallback(
        (rateId: string) =>
            (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                onSelect(rateId);
            },
        [onSelect],
    );

    const editNameTextFieldRef = React.useRef<HTMLInputElement | null>();
    const [error, setError] = React.useState<string | null>(null);
    const [newDialogOpen, setNewDialogOpen] = React.useState(false);

    const openAddDialog = React.useCallback(() => {
        setError(null);
        setNewDialogOpen(true);
    }, [setNewDialogOpen]);

    const closeAddDialog = React.useCallback(() => {
        setNewDialogOpen(false);
    }, [setNewDialogOpen]);

    const handleSaveRate = () => {
        const newRateName = editNameTextFieldRef?.current?.value ?? '';
        if (isEmpty(newRateName)) {
            setError('Name cannot be empty');
            return;
        }

        const isDuplicate = Object.values(rates).some((rate) => {
            return (
                rate?.meta?.label?.toLowerCase() === newRateName?.toLowerCase()
            );
        });
        if (isDuplicate) {
            setError('Name already in use, please choose another name');
            return;
        }

        const docRef = ratesCollectionRef.doc();
        docRef
            .set({
                meta: {
                    label: newRateName,
                    type: {},
                },
                rates: {},
            })
            .then(() => {
                const rateId = docRef.id;
                onSelect(rateId);
                closeAddDialog();
            })
            .catch((_error) => {
                setError('Failed to create new rate.');
                console.warn(_error);
            });
    };

    return (
        <Grid container item className={classes.ratesListPanel} xs={4}>
            <PageTitle
                title="Service Rates"
                tooltip="Create rates for each service. These rates can be assigned to 'conversions' with specific roles, so that staff assigned to these roles (through Calendar Management) can be paid accordingly. The rates also determine the final retail cost."
                gridProps={{
                    className: classes.title,
                }}
            />
            <Grid
                container
                item
                direction="row"
                justify="space-between"
                className={classes.newRateButtonContainer}
            >
                <Typography className={classes.ratesListTitle}>{`${
                    Object.keys(rates).length
                } Rates`}</Typography>
                <Button
                    variant="contained"
                    onClick={openAddDialog}
                    className={classes.newRateButton}
                >
                    Add Rate
                </Button>
            </Grid>
            <TextField
                fullWidth
                inputRef={searchRef}
                name="Filter"
                type="text"
                placeholder="Search"
                label="Filter"
                onChange={() => onFilterChange()}
            />
            <List className={classes.ratesListContainer}>
                {filteredRates.map(([rateId, rate]) => {
                    if (!rate) {
                        return null;
                    }
                    const {
                        meta: { label },
                    } = rate;
                    return (
                        <ListItem
                            key={`${rateId}-list-item`}
                            button
                            selected={selectedRateId === rateId}
                            onClick={handleListItemClick(rateId)}
                        >
                            <ListItemText primary={label} />
                        </ListItem>
                    );
                })}
            </List>
            <Dialog
                PaperProps={{ className: classes.editNameDialog }}
                open={newDialogOpen}
                onClose={closeAddDialog}
                aria-labelledby="edit-form-dialog-title"
            >
                <DialogTitle id="edit-form-dialog-title">Rate Name</DialogTitle>
                <DialogContent>
                    <DialogContentText>Enter a new name:</DialogContentText>
                    <Grid direction="column" container>
                        <TextField
                            inputRef={editNameTextFieldRef}
                            defaultValue=""
                            multiline
                        />
                        <FormHelperText>Rate Name</FormHelperText>
                    </Grid>
                    {error && (
                        <DialogContentText className={classes.addDialogMessage}>
                            {error}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAddDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveRate} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

function RateDetailsPanel({
    rates: ratesList,
    selectedRate: rate,
    selectedRateId,
    onDeleteRate,
}: RateDetailsPanelProps) {
    const classes = useStyles();
    const firestore = useFirestore();
    const [selectedRate, setSelectedRate] = React.useState<
        RateObject | undefined
    >(rate);

    React.useEffect(() => {
        setSelectedRate(rate);
    }, [rate, setSelectedRate]);

    const updateRateValues = React.useCallback(
        (rateObject: RateObject) => {
            const docRef = firestore.doc(
                `${RATES_COLLECTION}/${selectedRateId}`,
            );
            docRef
                .update(rateObject as UpdateData)
                .then(() => {
                    // success, do nothing for now
                })
                .catch((error: Error) => {
                    console.error(error);
                });
        },
        [firestore, selectedRateId, setSelectedRate],
    );

    const onRateChange = React.useCallback(
        (updateObject: Partial<RateObject>, shouldSave = false) => {
            const updatedRateObject = merge({}, selectedRate, updateObject);
            setSelectedRate(updatedRateObject);
            if (shouldSave) {
                updateRateValues(updatedRateObject);
            }
        },
        [selectedRate, setSelectedRate],
    );

    const onSaveValues = React.useCallback(
        (_event: any) => {
            if (selectedRate) {
                updateRateValues(selectedRate);
            }
        },
        [selectedRate, updateRateValues],
    );

    if (selectedRate === undefined || selectedRateId === undefined) {
        return (
            <Grid
                item
                xs
                className={classnames(
                    classes.rateDetailsPanelContainer,
                    classes.rateDetailsContents,
                    classes.noSelectionMessage,
                )}
            >
                <Typography variant="body1">No rate selected</Typography>
            </Grid>
        );
    }

    const {
        meta: { label, prefDiscount, prefDiscountDollar, type: typeMap },
        rates,
    } = selectedRate;

    return (
        <Grid item xs className={classes.rateDetailsPanelContainer}>
            <RateActionBar
                rates={ratesList}
                selectedRate={selectedRate}
                onValueChange={onRateChange}
                onSave={onSaveValues}
                onDeleteRate={onDeleteRate}
            />
            <Grid
                container
                className={classes.rateDetailsContents}
                direction="column"
            >
                <Typography variant="h5" className={classes.rateTitle}>
                    {label}
                </Typography>
                <RatePrefPercentDiscount
                    prefDiscountPercent={prefDiscount}
                    selectedRateId={selectedRateId}
                    selectedRate={selectedRate}
                    onRateChange={onRateChange}
                />
                <RatePrefDollarDiscount
                    prefDiscountDollar={prefDiscountDollar}
                    selectedRateId={selectedRateId}
                    selectedRate={selectedRate}
                    onRateChange={onRateChange}
                />

                <RateTypeSelection
                    selectedRateId={selectedRateId}
                    selectedRate={selectedRate}
                    onRateChange={onRateChange}
                />
                {Object.entries(rates).map(([rateTypeKey, rateTabObject]) => {
                    const isRateEnabled = Boolean(typeMap[rateTypeKey]);
                    if (!isRateEnabled || rateTabObject === undefined) {
                        return;
                    }
                    const tabPath = ['rates', rateTypeKey];
                    return (
                        <RateTypeTab
                            selectedRateId={selectedRateId}
                            onValueChange={onRateChange}
                            path={tabPath}
                            key={`${selectedRateId}-${tabPath.toString()}`}
                            typeTitle={rateTypeKey}
                            rateTabObject={rateTabObject as RateTabObject}
                        />
                    );
                })}
                <Grid
                    container
                    item
                    xs
                    justify="center"
                    className={classes.saveButtonContainer}
                >
                    <Button onClick={onSaveValues} variant="contained">
                        Save Changes
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

function RateActionBar({
    rates,
    selectedRate,
    onValueChange,
    onSave,
    onDeleteRate,
}: RateActionBarProps) {
    const classes = useStyles();
    const label = selectedRate?.meta?.label || '';
    const editNameTextFieldRef = React.useRef<HTMLInputElement | null>();
    const [error, setError] = React.useState<string | null>(null);
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const openEditDialog = React.useCallback(() => {
        setError(null);
        setEditDialogOpen(true);
    }, [setEditDialogOpen, label]);

    const closeEditDialog = React.useCallback(() => {
        setEditDialogOpen(false);
    }, [setEditDialogOpen]);

    const openDeleteDialog = React.useCallback(() => {
        setDeleteDialogOpen(true);
    }, [setDeleteDialogOpen]);

    const closeDeleteDialog = React.useCallback(() => {
        setDeleteDialogOpen(false);
    }, [setDeleteDialogOpen]);

    const handleSaveName = React.useCallback(
        (_event: any) => {
            const newRateName = editNameTextFieldRef?.current?.value ?? '';
            if (isEmpty(newRateName)) {
                setError('Name cannot be empty');
                return;
            }

            const isDuplicate = Object.values(rates).some((rate) => {
                return (
                    rate?.meta?.label?.toLowerCase() ===
                    newRateName?.toLowerCase()
                );
            });
            if (isDuplicate) {
                setError('Name already in use, please choose another name');
                return;
            }

            const updateObject = {
                meta: { ...selectedRate.meta, label: newRateName },
            };
            onValueChange(updateObject, true);
            closeEditDialog();
        },
        [selectedRate, onValueChange, closeEditDialog],
    );

    const handleDeleteRate = () => {
        closeDeleteDialog();
        onDeleteRate();
    };

    return (
        <Grid
            container
            item
            xs
            className={classes.actionBar}
            direction="row"
            justify="flex-end"
        >
            <IconButton onClick={onSave}>
                <SaveIcon />
            </IconButton>
            <IconButton onClick={openEditDialog}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={openDeleteDialog}>
                <DeleteIcon />
            </IconButton>
            <Dialog
                PaperProps={{ className: classes.editNameDialog }}
                open={editDialogOpen}
                onClose={closeEditDialog}
                aria-labelledby="edit-form-dialog-title"
            >
                <DialogTitle id="edit-form-dialog-title">
                    Edit Rate Name
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>Enter a new name:</DialogContentText>
                    <Grid direction="column" container>
                        <TextField
                            inputRef={editNameTextFieldRef}
                            defaultValue={label}
                            multiline
                        />
                        <FormHelperText>Rate Name</FormHelperText>
                    </Grid>
                    {error && (
                        <DialogContentText className={classes.addDialogMessage}>
                            {error}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveName} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                PaperProps={{ className: classes.editNameDialog }}
                open={deleteDialogOpen}
                onClose={closeDeleteDialog}
                aria-labelledby="delete-form-dialog-title"
            >
                <DialogTitle id="delete-form-dialog-title">
                    Delete Rate
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you SURE you want to remove this rate? This action
                        cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteRate} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

function RatePrefPercentDiscount({
    selectedRate,
    selectedRateId,
    prefDiscountPercent = 0,
    onRateChange,
}: RatePrefDiscountPercentProps) {
    const classes = useStyles();
    const onValueChange = React.useCallback(
        (event: any) => {
            const value = event.target.value ?? 0;
            const updateObject = {
                meta: { ...selectedRate.meta, prefDiscount: value },
            };
            onRateChange(updateObject);
        },
        [onRateChange],
    );

    return (
        <Grid item key={`${selectedRateId}-pref-discount-percent`}>
            <Typography variant="body2" className={classes.valueLabel}>
                Pref Discount (%)
            </Typography>
            <TextField
                inputProps={{
                    style: {
                        padding: 5,
                        textAlign: 'end',
                    },
                }}
                InputProps={{
                    inputComponent: PercentFormat,
                }}
                onChange={onValueChange}
                variant="outlined"
                multiline={false}
                value={prefDiscountPercent}
                className={classes.valueTextField}
            />
        </Grid>
    );
}

function RatePrefDollarDiscount({
    selectedRate,
    selectedRateId,
    prefDiscountDollar = 0,
    onRateChange,
}: RatePrefDiscountDollarProps) {
    const classes = useStyles();
    const onValueChange = React.useCallback(
        (event: any) => {
            const value = event.target.value ?? 0;
            const updateObject = {
                meta: { ...selectedRate.meta, prefDiscountDollar: value },
            };
            onRateChange(updateObject);
        },
        [onRateChange],
    );

    return (
        <Grid item key={`${selectedRateId}-pref-discount-dollar`}>
            <Typography variant="body2" className={classes.valueLabel}>
                Pref Discount ($)
            </Typography>
            <TextField
                inputProps={{
                    style: {
                        padding: 5,
                        textAlign: 'end',
                    },
                }}
                InputProps={{
                    inputComponent: DollarFormat,
                }}
                onChange={onValueChange}
                variant="outlined"
                multiline={false}
                value={prefDiscountDollar}
                className={classes.valueTextField}
            />
        </Grid>
    );
}

function RateTypeSelection({
    selectedRate,
    selectedRateId,
    onRateChange,
}: RateTypeSelectionProps) {
    const classes = useStyles();

    const [typesState, setTypeState] = React.useState<any>({});
    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newTypesMap = {
                ...typesState,
                [event.target.name]: event.target.checked,
            };
            const changes = {
                meta: { ...selectedRate.meta, type: newTypesMap },
            };
            onRateChange(changes);
        },
        [typesState, selectedRate, onRateChange],
    );

    React.useEffect(() => {
        if (selectedRate) {
            const newTypeState = merge(
                {},
                DEFAULT_RATE_TYPES_MAP,
                selectedRate.meta.type,
            );
            setTypeState(newTypeState);
        }
    }, [selectedRate, setTypeState]);

    const error = Object.values(typesState).filter((v) => v).length === 0;

    return (
        <Grid className={classes.rateSelectionRoot}>
            <FormControl
                required
                error={error}
                component="fieldset"
                className={classes.formControl}
            >
                <FormLabel component="legend">
                    Rate Type(s) - Select at least one:
                </FormLabel>
                <FormGroup>
                    {Object.entries(typesState).map(
                        ([typeName, typeEnabled]) => {
                            return (
                                <FormControlLabel
                                    key={`${selectedRateId}-${typeName}`}
                                    control={
                                        <Checkbox
                                            checked={typeEnabled}
                                            onChange={handleChange}
                                            name={typeName}
                                        />
                                    }
                                    label={typeName.toUpperCase()}
                                />
                            );
                        },
                    )}
                </FormGroup>
            </FormControl>
        </Grid>
    );
}

const RateTypeTab = ({
    onValueChange,
    path,
    typeTitle,
    rateTabObject,
    selectedRateId,
}: RateTypeTabProps) => {
    const classes = useStyles();
    return (
        <Grid container item direction="column">
            <Typography
                variant="h5"
                className={classes.rateTabTitle}
            >{`${startCase(typeTitle)} Rate`}</Typography>
            <Grid container item className={classes.rateSectionsContainer}>
                {Object.entries(rateTabObject).map(
                    ([sectionKey, rateSectionObject]) => {
                        const sectionPath = [...path, sectionKey];
                        return (
                            <RateSection
                                onValueChange={onValueChange}
                                path={sectionPath}
                                sectionTitle={sectionKey}
                                rateSectionObject={rateSectionObject}
                                key={`${selectedRateId}-${sectionPath.toString()}`}
                                selectedRateId={selectedRateId}
                            />
                        );
                    },
                )}
            </Grid>
        </Grid>
    );
};

const RateSection = ({
    onValueChange,
    path,
    sectionTitle,
    rateSectionObject,
    selectedRateId,
}: RateSectionProps) => {
    const classes = useStyles();
    return (
        <Grid container item>
            <Grid container item xs={1}>
                <Typography variant="h5" className={classes.sectionTitle}>
                    {startCase(sectionTitle)}
                </Typography>
            </Grid>
            <Grid
                container
                direction="column"
                item
                xs
                className={classes.rateLinesContainer}
            >
                {Object.entries(rateSectionObject).map(
                    ([rateLineKey, rateLine]) => {
                        const rateLinePath = [...path, rateLineKey];
                        return (
                            <RateLine
                                selectedRateId={selectedRateId}
                                onValueChange={onValueChange}
                                path={rateLinePath}
                                rateLabel={rateLineKey}
                                rateLine={rateLine}
                                key={`${selectedRateId}-${rateLinePath.toString()}`}
                            />
                        );
                    },
                )}
            </Grid>
        </Grid>
    );
};

const RateLine = ({
    onValueChange: updateValue,
    path,
    rateLabel,
    rateLine: rateObject,
    selectedRateId,
}: RateLineProps) => {
    const classes = useStyles();

    const onValueChange = React.useCallback(
        (propertyPath: string[]) => (event: any) => {
            let value = event.target.value ?? 0;
            if (propertyPath.includes('minChargeMaxArea')) {
                if (!value || value === 0) {
                    value = null;
                }
            }

            const updateObject = set({}, propertyPath, value);
            updateValue(updateObject);
        },
        [updateValue, path],
    );

    const onCheckChange = React.useCallback(
        (propertyPath: string[]) => (event: any) => {
            const isChecked = event.target.checked || false;
            const updateObject = set({}, propertyPath, isChecked);
            updateValue(updateObject);
        },
        [updateValue, path],
    );

    return (
        <Grid
            className={classes.rateLine}
            container
            direction="row"
            alignItems="center"
        >
            <Grid item xs={2}>
                <Typography className={classes.rateLabel}>
                    {startCase(rateLabel)}
                </Typography>
            </Grid>
            <Grid container item xs>
                {Object.entries(rateObject).map(
                    ([key, value]: [string, number | boolean]) => {
                        const rateValuePath = [...path, key];
                        if (
                            key.toLowerCase() ===
                            'includePsf2InMinCharge'.toLowerCase()
                        ) {
                            return (
                                <Grid
                                    item
                                    xs={3}
                                    className={classes.valueContainer}
                                    key={`${selectedRateId}-${rateValuePath.toString()}`}
                                >
                                    <Typography
                                        variant="body2"
                                        className={classes.valueLabel}
                                    >
                                        Include Rate2 in Minimum Charge
                                    </Typography>
                                    <Checkbox
                                        checked={value as boolean}
                                        onChange={onCheckChange(rateValuePath)}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                    />
                                </Grid>
                            );
                        }

                        const noFormatFields = ['included', 'minchargemaxarea'];

                        const inputComponent = noFormatFields.includes(
                            key.toLowerCase(),
                        )
                            ? NoFormat
                            : DollarFormat;
                        let label = capitalize(key);
                        let placeholder;
                        if (key === 'minChargeMaxArea') {
                            const { amount = 0, psf1 = 0 } = rateObject;
                            console.debug('rateObject', rateObject);
                            console.debug('value', value);
                            label = 'Sqft Included';
                            const isInvalidAmount = !value || value <= 0;
                            const inferredSqftIncluded = isInvalidAmount
                                ? psf1 !== 0
                                    ? amount / psf1
                                    : 0
                                : 0;
                            placeholder = inferredSqftIncluded.toString();
                        }

                        return (
                            <Grid
                                item
                                xs={2}
                                className={classes.valueContainer}
                                key={`${selectedRateId}-${rateValuePath.toString()}`}
                            >
                                <Typography
                                    variant="body2"
                                    className={classes.valueLabel}
                                >
                                    {label}
                                </Typography>
                                <TextField
                                    inputProps={{
                                        style: {
                                            padding: 5,
                                            textAlign: 'end',
                                        },
                                    }}
                                    InputProps={{
                                        inputComponent,
                                    }}
                                    onChange={onValueChange(rateValuePath)}
                                    variant="outlined"
                                    multiline={false}
                                    value={value}
                                    placeholder={placeholder}
                                    className={classes.valueTextField}
                                />
                            </Grid>
                        );
                    },
                )}
            </Grid>
        </Grid>
    );
};

function NoFormat({ inputRef, onChange, ...other }: any) {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values: any) => {
                onChange({
                    target: {
                        value: values.floatValue,
                    },
                });
            }}
            decimalScale={2}
            allowEmptyFormatting={false}
        />
    );
}

function DollarFormat({ inputRef, onChange, ...other }: any) {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values: any) => {
                onChange({
                    target: {
                        value: values.floatValue,
                    },
                });
            }}
            prefix="$"
            decimalScale={5}
            allowEmptyFormatting={false}
        />
    );
}

function PercentFormat({ inputRef, onChange, value, ...other }: any) {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values: any) => {
                onChange({
                    target: {
                        value: values.floatValue / 100,
                    },
                });
            }}
            value={value * 100}
            decimalScale={0}
            suffix="%"
        />
    );
}

export default RateManagement;
