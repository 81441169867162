import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, isLoaded, useFirestore, Dictionary } from 'react-redux-firebase';
import { UpdateData } from '@firebase/firestore-types';
import {
    Grid,
    Typography,
    Select,
    MenuItem,
    Button,
    IconButton,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    FormHelperText,
    Tooltip,
} from '@material-ui/core';
import { RemoveCircle, InfoRounded } from '@material-ui/icons';
import { v4 as uuid } from 'uuid';
import { calendarList, userList, calendarRoleList, roleList } from '../../store/selectors';
import { CALENDAR_COLLECTION, CALENDAR_ROLES_COLLECTION, USERS_COLLECTION, ROLES_COLLECTION } from '../../constants';
import Spinner from '../Spinner';
import { PageTitle } from '../PageTitle';
import { Notification } from '../Notification';
import { formatUsername } from '../../utils';
import { useStyles } from './CalendarManagement.styles';
import { CalendarRowProps, RoleRowProps } from './CalendarManagement.types';

const CalendarManagement = () => {
    const classes = useStyles();
    const calendars = useSelector(calendarList) || {};
    const calendarRoles = useSelector(calendarRoleList);
    const calendarsLoaded = isLoaded(calendars);
    const calendarRolesLoaded = isLoaded(calendarRoles);

    const [notificationOpen, setNotificationOpen] = React.useState(false);
    const [notification, setNotification] = React.useState('');

    const handleClose = () => {
        setNotificationOpen(false);
    };

    const showNotification = React.useCallback(
        (message: string) => {
            setNotification(message);
            setNotificationOpen(true);
        },
        [setNotification, setNotificationOpen],
    );

    useFirestoreConnect(() => [{ collection: CALENDAR_COLLECTION }, { collection: CALENDAR_ROLES_COLLECTION }]);

    if (!calendarsLoaded || !calendarRolesLoaded) {
        return <Spinner />;
    }

    return (
        <Grid className={classes.root}>
            <PageTitle
                title="Calendars"
                tooltip="Assign staff and roles to calendars. The assigned staff will be paid based on the rates assigned to their respective roles."
            />
            <Grid container>
                {Object.values(calendars).map((calendar: any) => {
                    const calendarWithRoles = {
                        ...calendar,
                        roles: calendarRoles?.[calendar.id]?.roles || {},
                    };
                    return <CalendarRow calendar={calendarWithRoles} setNotification={showNotification} key={calendar.id} />;
                })}
            </Grid>
            <Notification open={notificationOpen} onClose={handleClose} message={notification} />
        </Grid>
    );
};

const CalendarRow = ({ calendar, setNotification }: CalendarRowProps) => {
    const firestore = useFirestore();
    const classes = useStyles();
    const users = useSelector(userList) || {};
    const { id, name, location, roles: calendarRoles } = calendar;
    const usersLoaded = isLoaded(users);
    const availableRoles = useSelector(roleList);
    const availableRolesLoaded = isLoaded(availableRoles);

    const [defaultRole] = Object.keys(availableRoles);
    const [defaultUserId] = Object.keys(users);
    const [addRoleDialogOpen, setAddRoleDialogOpen] = React.useState(false);
    const [selectedRole, setSelectedRole] = React.useState(defaultRole);
    const [selectedUser, setSelectedUser] = React.useState(defaultUserId);

    useFirestoreConnect(() => [{ collection: USERS_COLLECTION }, { collection: ROLES_COLLECTION }]);

    const openDialog = React.useCallback(() => {
        setSelectedRole(defaultRole);
        setSelectedUser(defaultUserId);
        setAddRoleDialogOpen(true);
    }, [setSelectedRole, setSelectedUser, setAddRoleDialogOpen]);

    const closeDialog = React.useCallback(() => {
        setSelectedRole(defaultRole);
        setSelectedUser(defaultUserId);
        setAddRoleDialogOpen(false);
    }, [setSelectedRole, setSelectedUser, setAddRoleDialogOpen]);

    const onRoleChange = React.useCallback(
        (event: any) => {
            setSelectedRole(event.target.value);
        },
        [setSelectedRole],
    );

    const onUserChange = React.useCallback(
        (event: any) => {
            setSelectedUser(event.target.value);
        },
        [setSelectedUser],
    );

    const addCalendarRole = () => {
        const updatedRoles = {
            ...calendarRoles,
            [selectedUser]: selectedRole,
        };
        updateCalendar(updatedRoles);
        closeDialog();
    };

    const removeCalendarRole = (userId: string) => () => {
        const { [userId]: roleToRemove, ...remainingRoles } = calendarRoles;
        updateCalendar(remainingRoles);
    };

    const updateCalendar = (roles: Dictionary<string>) => {
        const docRef = firestore.doc(`${CALENDAR_ROLES_COLLECTION}/${id}`);
        const changes = { roles };
        docRef
            .set(changes as UpdateData)
            .then(() => {
                setNotification(`Calendar updated`);
            })
            .catch((error) => {
                console.warn(error);
                setNotification('Failed to update calendar');
            });
    };

    if (!usersLoaded || !availableRolesLoaded) {
        return <Spinner />;
    }

    return (
        <Grid container direction="column" className={classes.calendarRow}>
            <Grid container direction="row" alignItems="center">
                <Typography className={classes.calendarName}>{name}</Typography>
                <Typography className={classes.calendarLocation}>{location}</Typography>
                <Button variant="contained" onClick={openDialog} className={classes.addRoleButton}>
                    Add Role
                </Button>
            </Grid>

            {Object.entries(calendarRoles).map(([userId, roleId]) => (
                <RoleRow roleId={roleId} userId={userId} onRemove={removeCalendarRole(userId)} key={uuid()} />
            ))}

            <Dialog open={addRoleDialogOpen} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Role</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Adding a staff member to the calendar will include them in the payouts, based on their role.
                    </DialogContentText>
                    <Grid direction="column" container>
                        <Select labelId="user-select-label" id="user-select" value={selectedUser || ''} onChange={onUserChange}>
                            {Object.keys(users).map((id: any) => {
                                const user = users[id];
                                if (!user) {
                                    return null;
                                }
                                return (
                                    <MenuItem value={id} key={id}>
                                        {formatUsername(user)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>Staff</FormHelperText>
                        <Select labelId="role-select-label" id="role-select" value={selectedRole || ''} onChange={onRoleChange}>
                            {Object.entries(availableRoles).map(([roleKey, role]) => (
                                <MenuItem value={roleKey} key={roleKey}>
                                    {role.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Role</FormHelperText>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={addCalendarRole} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

const RoleRow = ({ roleId, userId, onRemove }: RoleRowProps) => {
    const availableRoles = useSelector(roleList);
    const availableRolesLoaded = isLoaded(availableRoles);

    const classes = useStyles();
    const users = useSelector(userList) || {};
    const usersLoaded = isLoaded(users);
    const user = users[userId];

    if (!usersLoaded || !availableRolesLoaded || !user) {
        return null;
    }

    const role = availableRoles?.[roleId];
    const roleName = role?.name || '';
    const fullName = formatUsername(user);

    return (
        <Grid direction="row" container item alignItems="center" className={classes.roleRow} key={userId}>
            <Grid item>
                <Typography className={classes.userName}>{fullName}</Typography>
            </Grid>
            <Grid item className={classes.roleName}>
                <Typography>{roleName}</Typography>
            </Grid>
            <IconButton onClick={onRemove} className={classes.removeRoleButton}>
                <RemoveCircle fontSize="inherit" />
            </IconButton>
        </Grid>
    );
};

export default CalendarManagement;
