import React from 'react';
import { navigate } from '@reach/router';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import * as firebaseApp from 'firebase/app';
import 'firebase/auth';
import { Grid, Typography } from '@material-ui/core';
import queryString from 'query-string';
import { castArray } from 'lodash-es';
import { firebaseAuth } from '../../store/selectors';
import { DEFAULT_REDIRECT_PATH } from '../../config';
import { LoginProps } from './Login.types';
import { useStyles } from './Login.styles';

const Login = ({ location }: LoginProps) => {
    const classes = useStyles();
    const firebase = useFirebase();

    const auth = useSelector(firebaseAuth) || '';

    const searchQuery = location?.search;
    const params = queryString.parse(searchQuery || '');

    if (!firebase) {
        return null;
    }

    if (auth.uid) {
        navigate('/app/profile');
    }

    const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [firebaseApp.auth.GoogleAuthProvider.PROVIDER_ID],
        callbacks: {
            signInSuccessWithAuthResult: () => {
                if (params.redirect) {
                    const [redirect] = castArray(params.redirect);
                    navigate(redirect);
                } else {
                    navigate(DEFAULT_REDIRECT_PATH);
                }
                return true;
            },
        },
    };

    return (
        <Grid container justify="center" title="Log In" direction="column" className={classes.root} alignContent="center">
            <Grid container item xs justify="center" direction="column" alignContent="center" className={classes.innerCenter}>
                <Typography className={classes.message}>Sign in to get started</Typography>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </Grid>
        </Grid>
    );
};

export default Login;
