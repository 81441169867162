import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import ClientOAuth2 from 'client-oauth2';
import * as React from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { clientsList } from '../../store/selectors';
import { Appointment, FreshbooksClientDTO } from '../../types';
import { sortGroupedAppointments } from '../../utils';
// import { appointments as testData } from './testData';
// import { appointments as testData } from './testDataDev';
import { formatCost, formatDate } from '../Appointments/utils';
import { findMatchingFreshbooksClient } from '../ClientManagement';
import { AppointmentStatusDisplay } from './AppointmentStatusDisplay';
import { ClientStatusDisplay } from './ClientStatusDisplay';
import { useStyles } from './Freshbooks.styles';
import {
    generateInvoices,
    getFeesTable,
    uploadToFreshbooks,
} from './freshbooksAdapter';
import { InvoiceUploadStatus } from './types';
import { getAcuityClientFromAppointment, getAppointmentCost } from './utils';

export function ClientSummaryRow({
    clientName,
    appointments,
    freshbooksClients,
    uploadStatus,
    auth,
    accountId,
}: {
    clientName: string;
    appointments: Appointment[];
    freshbooksClients: FreshbooksClientDTO[];
    uploadStatus: InvoiceUploadStatus;
    auth: ClientOAuth2;
    accountId: string;
}) {
    const classes = useStyles();
    const acuityClients = useSelector(clientsList);
    const [error, setError] = React.useState<string | null>(null);
    const [response, setResponse] = React.useState<string | null>(null);
    const [statusText, setStatusText] = React.useState('Ready');

    React.useEffect(() => {
        switch (uploadStatus) {
            case InvoiceUploadStatus.Error:
                setError('Upload failed, please try again.');
                break;
            case InvoiceUploadStatus.Success:
                setError(null);
                setStatusText('Success');
                break;
            case InvoiceUploadStatus.Pending:
                setStatusText('Uploading...');
                break;
            default:
            case InvoiceUploadStatus.None:
                setResponse(null);
                setError(null);
                setStatusText('Ready');
                break;
        }
    }, [uploadStatus]);

    const onDebugInfo = (
        client?: FreshbooksClientDTO,
        appointmentsToDebug?: Appointment[],
    ) => {
        console.debug('client', client);
        console.debug('appointments', appointmentsToDebug);
        console.debug('error', error);
        console.debug('response', response);
    };

    const feesTable = getFeesTable(appointments);
    const clientTotal = appointments.reduce((acc, appointment) => {
        const roleIsLead = appointment?.roleType === 'lead';
        if (roleIsLead) {
            return acc;
        }
        const includeAdditionalFees = feesTable[appointment.id] === appointment;
        return acc + getAppointmentCost(appointment, includeAdditionalFees);
    }, 0);
    const [firstAppointment] = appointments;
    const acuityClient = getAcuityClientFromAppointment(
        acuityClients,
        firstAppointment,
    );
    const freshbooksClient = findMatchingFreshbooksClient(
        acuityClient,
        freshbooksClients,
    );

    const groupedAppointments: Record<string, Appointment[]> =
        appointments.reduce(
            (acc: Record<string, Appointment[]>, appointment) => {
                const existing = acc[appointment.id] ?? [];
                return {
                    ...acc,
                    [appointment.id]: existing.concat(appointment),
                };
            },
            {},
        ) ?? {};
    const numAppointments = Object.keys(groupedAppointments).length;

    const sortedGroupAppointments = React.useMemo(() => {
        return sortGroupedAppointments(groupedAppointments);
    }, [groupedAppointments]);

    const uploadSingleInvoice = async (invoiceAppointments: Appointment[]) => {
        console.debug('uploading single invoice', invoiceAppointments);

        const invoices = generateInvoices(
            invoiceAppointments,
            freshbooksClients!,
            acuityClients,
        );
        console.debug('FB invoice', invoices);

        const [firstInvoice] = Object.entries(invoices);
        const [clientEmail, invoice] = firstInvoice;

        const customerName = invoiceAppointments[0]?.displayName || clientEmail;

        const loadingToastId = toast.loading(
            `Uploading invoice for ${customerName}`,
        );

        try {
            uploadToFreshbooks(accountId, invoice, auth);
            toast.success(`Invoice successfully uploaded for ${customerName}`);
        } catch (err: unknown) {
            if (err instanceof Error) {
                console.error('Failed to upload invoice', err);
                return;
            }
            console.error('Unknown error while trying to upload invoice', err);
            toast.error(`Failed to upload invoice for ${customerName}`);
        } finally {
            toast.remove(loadingToastId);
        }
    };

    return (
        <Grid key={clientName}>
            <Grid item style={{ paddingTop: 8 }}>
                <Typography
                    component="span"
                    key={`${clientName}-title`}
                    style={{ fontWeight: 'bold' }}
                >
                    {`${clientName} - Total: ${formatCost(clientTotal)} `}
                </Typography>
                <ClientStatusDisplay
                    clientMatched={Boolean(freshbooksClient)}
                    response={response}
                    error={error}
                    statusText={statusText}
                />
            </Grid>

            <TableContainer component={Paper}>
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="client appointments table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                {`${numAppointments} ` +
                                    (numAppointments === 1
                                        ? 'appointment'
                                        : 'appointments')}
                            </TableCell>
                            <TableCell align="right">
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        uploadSingleInvoice(appointments);
                                    }}
                                    disabled={
                                        !freshbooksClient || Boolean(error)
                                    }
                                >
                                    Upload
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedGroupAppointments.map(
                            ([_appointmentId, appointmentRows], index) => {
                                if (
                                    !appointmentRows ||
                                    appointmentRows.length === 0
                                ) {
                                    return null;
                                }

                                let appointmentError: string | undefined;
                                const appointmentCost = appointmentRows.reduce(
                                    (acc, row) => {
                                        const roleIsLead =
                                            row?.roleType === 'lead';
                                        if (roleIsLead) {
                                            return acc;
                                        }
                                        const rowError =
                                            row?.calculations?.error;
                                        if (rowError) {
                                            appointmentError = rowError;
                                        }

                                        const includeAdditionalFees =
                                            feesTable[row.id] === row;
                                        return (
                                            acc +
                                            getAppointmentCost(
                                                row,
                                                includeAdditionalFees,
                                            )
                                        );
                                    },
                                    0,
                                );

                                const { datetime, id, staffId } =
                                    appointmentRows[0];

                                return (
                                    <TableRow key={`${id}-${staffId}`}>
                                        <TableCell
                                            style={{ width: 170 }}
                                            onClick={() =>
                                                onDebugInfo(
                                                    freshbooksClient,
                                                    appointmentRows,
                                                )
                                            }
                                        >
                                            <Typography>
                                                {`#${index + 1} (`}
                                                <a
                                                    href={`https://secure.acuityscheduling.com/appointments.php?action=detail&id=${id}`}
                                                    target="_blank"
                                                >
                                                    {id}
                                                </a>
                                                {`)`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {formatDate(datetime)}
                                        </TableCell>
                                        <TableCell align="left">
                                            {
                                                appointmentRows[0]?.formFields
                                                    ?.address
                                            }
                                        </TableCell>
                                        <TableCell align="right">
                                            <AppointmentStatusDisplay
                                                total={appointmentCost}
                                                error={appointmentError}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            },
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
}
