import { makeStyles, Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        paperRoot: {
            background: 'none',
            backgroundColor: 'none',
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        iconButton: {
            height: theme.spacing(3),
            width: theme.spacing(3),
        },
        errorIconButton: {
            color: 'red',
        },
        phoneColumn: {
            width: 120,
        },
        approveButtonColumn: {
            width: 50,
            alignContent: 'center',
        },
        linkColumnHeader: {
            paddingLeft: 18,
        },
        monthFilterTitle: {},
        monthFilterFormControl: {
            padding: theme.spacing(0.25),
        },
        monthFilterSelect: {
            paddingLeft: theme.spacing(),
        },
        noAppointmentsMessage: {
            padding: theme.spacing(3.5),
        },
        appointmentsActionBar: {
            padding: theme.spacing(2),
            paddingLeft: theme.spacing(3.5),
            margin: `${theme.spacing(2)}px 0px`,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
        },
        appointmentsSummary: {
            paddingTop: theme.spacing(),
            paddingLeft: theme.spacing(2)
        },
        appointmentsSummarySection: {
            paddingLeft: theme.spacing(2),
        },
        staffErrorMessage: {
            color: 'red',
        },
        appointmentRowError: {
            backgroundColor: theme.palette.error.light,
        },
        descriptionText: {
            whiteSpace: 'pre-line',
        },
        prefStarIcon: {
            marginLeft: theme.spacing(0.5),
            fontSize: 12,
        },
    });

export const useStyles = makeStyles(styles);
