import React from 'react';
import { createFirestoreInstance } from 'redux-firestore';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { ThemeProvider } from '@material-ui/core';
import appTheme from './appTheme';

import firebase from '../firebase';
import store from '../store';

// react-redux-firebase config
export const rrfConfig = {
    attachAuthIsReady: true,
    firebaseStateName: 'firebase',
    presence: 'presence',
    sessions: 'sessions',
    useFirestoreForProfile: true,
    userProfile: 'users',
};

const rrfProps = {
    firebase: typeof window !== 'undefined' ? firebase : {},
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance, // <- needed if using firestore
};

interface contextType {}

// Setup react-redux so that connect HOC can be used
export default ({ children }: React.PropsWithChildren<contextType>) => {
    if (!store) {
        return null;
    }

    return (
        <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <ThemeProvider theme={appTheme}>{children}</ThemeProvider>
            </ReactReduxFirebaseProvider>
        </Provider>
    );
};
