import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            minHeight: 500,
            alignItems: 'center',
        },
        message: {
            paddingTop: theme.spacing(),
        },
    }),
);
