import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import classnames from 'classnames';
import { UpdateData } from '@firebase/firestore-types';
import { Checkbox, Typography } from '@material-ui/core';
import { stopPropagation } from '../../../utils';
import { APPOINTMENTS_COLLECTION } from '../../../constants';
import { useStyles } from './ApproveButton.styles';
import { ApproveButtonProps } from './ApproveButton.types';
import { isPostponed } from '../utils';

export const ApproveButton = ({ appointment, endDate }: ApproveButtonProps) => {
    const classes = useStyles();
    const firestore = useFirestore();
    const { id: appointmentId } = appointment;

    const [isApproved, setIsApproved] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setIsApproved(appointment?.approved || false);
    }, [appointment]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        stopPropagation(event);
        const newApprovalValue = !isApproved;
        setIsApproved(newApprovalValue);
        setIsLoading(true);

        updateAppointmentApproval(newApprovalValue);
    };

    const updateAppointmentApproval = React.useCallback(
        (approved: boolean) => {
            const docRef = firestore.doc(`${APPOINTMENTS_COLLECTION}/${appointmentId}`);
            const changes = { approved };
            docRef
                .update(changes as UpdateData)
                .then(() => {
                    setIsApproved(approved);
                })
                .catch((error: Error) => {
                    setIsApproved(!approved);
                    console.error(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [firestore, setIsApproved, appointmentId],
    );

    const postponed = isPostponed(appointment, endDate);
    if (postponed) {
        return <Typography>Postponed</Typography>;
    }

    return (
        <Checkbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={classnames(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            checked={isApproved}
            onChange={onChange}
            onClick={stopPropagation}
            onMouseDown={stopPropagation}
            disabled={isLoading}
        />
    );
};
