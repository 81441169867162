import { createSelector } from 'reselect';
import { FirebaseReducer, isLoaded, isEmpty } from 'react-redux-firebase';
import { UserDTO } from '../../types';

export const firebaseState = (state: any) => state.firebase;

export const firebaseAuth = createSelector(firebaseState, (firebaseState: FirebaseReducer.Reducer) => firebaseState.auth);

export const firebaseProfile = createSelector(
    firebaseState,
    (firebaseState: FirebaseReducer.Reducer): FirebaseReducer.Profile<UserDTO> => firebaseState.profile,
);
export const firebaseProfileReady = createSelector(
    firebaseProfile,
    (firebaseProfile) => isLoaded(firebaseProfile) && !isEmpty(firebaseProfile),
);

export const firebaseToken = createSelector(firebaseAuth, (firebaseAuth) => firebaseAuth?.stsTokenManager?.accessToken);

export const firebaseUserId = createSelector(
    firebaseAuth,
    (firebaseAuth: FirebaseReducer.AuthState): string | null => firebaseAuth?.uid || null,
);

export const isUserLoggedIn = createSelector(firebaseAuth, (firebaseAuth) => isLoaded(firebaseAuth) && !isEmpty(firebaseAuth));
