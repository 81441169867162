import * as React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, omit } from 'lodash-es';
import { useFirestoreConnect, isLoaded, useFirestore } from 'react-redux-firebase';
import { UpdateData } from '@firebase/firestore-types';
import { RemoveCircle } from '@material-ui/icons';
import {
    Grid,
    Select,
    MenuItem,
    Typography,
    Button,
    DialogContentText,
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    IconButton,
    FormHelperText,
} from '@material-ui/core';
import { leadsList, userList } from '../../store/selectors';
import { USERS_COLLECTION, LEADS_COLLECTION } from '../../constants';
import { Notification } from '../Notification';
import { formatUsername } from '../../utils';
import Spinner from '../Spinner';
import { useStyles } from './LeadManagement.styles';
import { PageTitle } from '../PageTitle';

const LeadManagement = () => {
    const classes = useStyles();
    const firestore = useFirestore();
    const users = useSelector(userList) || {};
    const leads = useSelector(leadsList) || {};
    const allLoaded = isLoaded(users, leads);

    useFirestoreConnect(() => [{ collection: USERS_COLLECTION }, { collection: LEADS_COLLECTION }]);

    const [addLeadDialogOpen, setAddLeadDialogOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<string>('');
    const [selectedLead, setSelectedLead] = React.useState<string>('');
    const [usersWithLeads, setUsersWithLeads] = React.useState({});
    const [usersWithoutLeads, setUsersWithoutLeads] = React.useState({});
    const [availableLeads, setAvailableLeads] = React.useState({});
    const [notificationOpen, setNotificationOpen] = React.useState(false);
    const [notification, setNotification] = React.useState('');

    const openDialog = React.useCallback(() => {
        setSelectedUser('');
        setSelectedLead('');
        setAddLeadDialogOpen(true);
    }, [selectedLead, setAddLeadDialogOpen]);

    const closeDialog = React.useCallback(() => {
        setSelectedUser('');
        setSelectedLead('');
        setAddLeadDialogOpen(false);
    }, []);

    const onUserChange = React.useCallback(
        (event: any) => {
            setSelectedUser(event.target.value);
        },
        [setSelectedUser],
    );

    const onLeadChange = React.useCallback(
        (event: any) => {
            setSelectedLead(event.target.value);
        },
        [setSelectedLead],
    );

    const handleClose = () => {
        setNotificationOpen(false);
    };

    const showNotification = React.useCallback(
        (message: string) => {
            setNotification(message);
            setNotificationOpen(true);
        },
        [setNotification, setNotificationOpen],
    );

    const updateLead = React.useCallback(
        (userId: string, leadId: string | null = null) => {
            const docRef = firestore.doc(`${LEADS_COLLECTION}/${userId}`);
            docRef.get().then((snapshot) => {
                if (snapshot.exists) {
                    docRef
                        .update(changes as UpdateData)
                        .then(() => {
                            showNotification(`Lead updated`);
                        })
                        .catch((error) => {
                            console.warn(error);
                            showNotification('Failed to update lead');
                        });
                } else {
                    docRef
                        .set(changes as UpdateData)
                        .then(() => {
                            showNotification(`Lead updated`);
                        })
                        .catch((error) => {
                            console.warn(error);
                            showNotification('Failed to update lead');
                        });
                }
            });
            const changes = { lead: leadId };
        },
        [firestore, showNotification],
    );

    const assignLead = React.useCallback(() => {
        if (selectedUser !== null && selectedLead !== null) {
            updateLead(selectedUser, selectedLead);
        }
        closeDialog();
    }, [selectedUser, selectedLead, updateLead, closeDialog]);

    const removeLead = React.useCallback(
        (userId: string) => () => {
            const docRef = firestore.doc(`${LEADS_COLLECTION}/${userId}`);
            docRef
                .delete()
                .then(() => {
                    showNotification(`Lead removed`);
                })
                .catch((error) => {
                    console.warn(error);
                    showNotification('Failed to remove lead');
                });
        },
        [updateLead],
    );

    React.useEffect(() => {
        if (selectedUser !== null) {
            const { [selectedUser]: selected, ...remainingUsers } = users;
            setAvailableLeads(remainingUsers);
        }
    }, [selectedUser, users, setAvailableLeads]);

    React.useEffect(() => {
        const idsOfUsersWithLeads = Object.keys(leads);
        const filteredUsers = Object.entries(users).reduce((acc, [userId, user]) => {
            const hasLead = idsOfUsersWithLeads.includes(userId);
            return hasLead ? { ...acc, [userId]: user } : acc;
        }, {});
        setUsersWithLeads(filteredUsers);
    }, [users, leads]);

    React.useEffect(() => {
        const usersToFilter = Object.keys(usersWithLeads);
        setUsersWithoutLeads(omit(users, usersToFilter));
    }, [users, usersWithLeads, setUsersWithoutLeads]);

    if (!allLoaded) {
        return <Spinner />;
    }

    return (
        <Grid container direction="column" className={classes.root}>
            <PageTitle
                title="Leads"
                tooltip="Staff members can be assigned to lead other staff. A lead will receive part of an appointment's fees, or a bonus, depending on the rates associated with the appointment."
            />
            <Grid container direction="row" alignItems="center">
                <Button variant="contained" disabled={isEmpty(usersWithoutLeads)} onClick={openDialog} className={classes.assignleadButton}>
                    Assign Lead
                </Button>
                {isEmpty(usersWithoutLeads) && (
                    <Typography className={classes.errorMessage}>
                        There are no more staff members without a lead assigned to them.
                    </Typography>
                )}
            </Grid>

            <Grid container>
                {Object.entries(usersWithLeads).map(([userId, user]: any) => {
                    return <UserLeadRelationshipRow user={user} userId={userId} onRemove={removeLead(userId)} key={userId} />;
                })}
            </Grid>

            <Dialog open={addLeadDialogOpen} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Assign Lead</DialogTitle>
                <DialogContent>
                    <DialogContentText>Assign a lead to a staff member.</DialogContentText>
                    <Grid direction="column" container>
                        <Select id="user-select" value={selectedUser} onChange={onUserChange}>
                            <MenuItem disabled value="">
                                <em>None Selected</em>
                            </MenuItem>
                            {Object.keys(usersWithoutLeads).map((id: any) => {
                                const user = users[id];
                                if (!user) {
                                    return null;
                                }
                                return (
                                    <MenuItem value={id} key={id}>
                                        {formatUsername(user)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>Staff</FormHelperText>
                        <Select id="lead-select" value={selectedLead} disabled={isEmpty(selectedUser)} onChange={onLeadChange}>
                            {Object.keys(availableLeads).map((id: any) => {
                                const user = users[id];
                                if (!user) {
                                    return null;
                                }
                                return (
                                    <MenuItem value={id} key={id}>
                                        {formatUsername(user)}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <FormHelperText>Lead</FormHelperText>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={isEmpty(selectedUser) || isEmpty(selectedLead)} onClick={assignLead} color="primary">
                        Assign
                    </Button>
                </DialogActions>
            </Dialog>

            <Notification open={notificationOpen} onClose={handleClose} message={notification} />
        </Grid>
    );
};

const UserLeadRelationshipRow = ({ user, userId, onRemove }: any) => {
    const classes = useStyles();
    const users = useSelector(userList) || {};
    const leads = useSelector(leadsList) || {};
    const leadId = leads[userId]?.lead || '';
    const lead = users[leadId];

    if (!lead) {
        return null;
    }
    const userName = formatUsername(user);
    const leadName = formatUsername(lead);

    return (
        <Grid direction="row" container item alignItems="center" className={classes.roleRow} key={userId}>
            <Grid item>
                <Typography className={classes.userName}>{userName}</Typography>
            </Grid>
            <Grid item className={classes.leadName}>
                <Typography>is lead by {leadName}</Typography>
            </Grid>
            <IconButton onClick={onRemove} className={classes.removeLeadButton}>
                <RemoveCircle fontSize="inherit" />
            </IconButton>
        </Grid>
    );
};

export default LeadManagement;
