import { fromPairs, lowerCase, merge, sortBy, toPairs } from 'lodash-es';
import { Dictionary } from 'react-redux-firebase';

import {
    AreaRateTab,
    AreaRetailSection,
    FlatRateTab,
    HourlyRateTab,
    RateObject,
    TAB_TYPE,
} from '../types';

export function translateRates(rates: Dictionary<RateObject>) {
    const translatedRates = Object.entries(rates).reduce(
        (acc: Dictionary<RateObject>, [rateId, rate]) => {
            if (!rate) {
                return acc;
            }
            return {
                ...acc,
                [rateId]: translateRateObject(rate),
            };
        },
        {},
    );
    return sortRates(translatedRates);
}

function sortRates(rates: Dictionary<RateObject>) {
    return fromPairs(
        sortBy(toPairs(rates), [
            ([key, rate]) => {
                return lowerCase(rate.meta.label);
            },
        ]),
    );
}

export function translateRateObject(rate: RateObject) {
    const {
        meta: { prefDiscount = 0, prefDiscountDollar = 0, ...meta },
        rates = {},
    } = rate;
    const {
        [TAB_TYPE.FLAT]: flatTab,
        [TAB_TYPE.AREA]: areaTab,
        [TAB_TYPE.HOURLY]: hourlyTab,
    } = rates;

    const newFlatTab = {
        retail: {
            ...merge({}, defaultFlatRetailSection, flatTab?.retail),
        },
        payout: {
            ...merge({}, defaultFlatPayoutSection, flatTab?.payout),
        },
    } as FlatRateTab;

    const newAreaTab = {
        retail: {
            ...merge({}, defaultAreaRetailSection, areaTab?.retail),
        },
        payout: {
            ...merge({}, defaultAreaPayoutSection, areaTab?.payout),
        },
    } as AreaRateTab;

    const newHourlyTab = {
        retail: {
            ...merge({}, defaultHourlyRetailSection, hourlyTab?.retail),
        },
        payout: {
            ...merge({}, defaultHourlyPayoutSection, hourlyTab?.payout),
        },
    } as HourlyRateTab;

    return {
        meta: { ...meta, prefDiscount, prefDiscountDollar },
        rates: {
            [TAB_TYPE.FLAT]: newFlatTab,
            [TAB_TYPE.AREA]: newAreaTab,
            [TAB_TYPE.HOURLY]: newHourlyTab,
        },
    } as RateObject;
}

const defaultFlatRetailSection = {
    retail: {
        amount: 0,
        adjust1: 0,
        adjust2: 0,
        included: 0,
    },
};

const defaultFlatPayoutSection = {
    lead: {
        amount: 0,
        adjust1: 0,
        adjust2: 0,
    },
    team: {
        amount: 0,
        adjust1: 0,
        adjust2: 0,
    },
    leadBonus: {
        amount: 0,
        adjust1: 0,
        adjust2: 0,
    },
    supportStaff: {
        amount: 0,
        adjust1: 0,
        adjust2: 0,
    },
};

const defaultAreaRetailSection: AreaRetailSection = {
    minimumCharge: {
        amount: 0,
        psf1: 0,
        psf2: 0,
        minChargeMaxArea: undefined,
        includePsf2InMinCharge: true,
    },
};

const defaultAreaPayoutSection = {
    lead: {
        amount: 0,
        psf1: 0,
        psf2: 0,
    },
    team: {
        amount: 0,
        psf1: 0,
        psf2: 0,
    },
    leadBonus: {
        amount: 0,
        psf1: 0,
        psf2: 0,
    },
    supportStaff: {
        amount: 0,
        psf1: 0,
        psf2: 0,
    },
};

const defaultHourlyRetailSection = {
    minimumCharge: {
        amount: 0,
        rate1: 0,
        rate2: 0,
    },
};

const defaultHourlyPayoutSection = {
    lead: {
        amount: 0,
        rate1: 0,
        rate2: 0,
    },
    team: {
        amount: 0,
        rate1: 0,
        rate2: 0,
    },
    leadBonus: {
        amount: 0,
        rate1: 0,
        rate2: 0,
    },
    supportStaff: {
        amount: 0,
        rate1: 0,
        rate: 0,
    },
};
