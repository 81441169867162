import { chunk, isEmpty, isEqual, toNumber } from 'lodash-es';
import React from 'react';
import { useSelector } from 'react-redux';
import {
    Dictionary,
    isLoaded,
    useFirestoreConnect,
    WhereOptions,
} from 'react-redux-firebase';

import {
    APPOINTMENTS_COLLECTION,
    CALENDAR_ROLES_COLLECTION,
    CLIENTS_COLLECTION,
    CONVERSIONS_COLLECTION,
    LEADS_COLLECTION,
    RATES_COLLECTION,
    ROLES_COLLECTION,
    USERS_COLLECTION,
} from '../constants';
import {
    calendarList,
    calendarRoleList,
    clientsList,
    conversionList,
    firebaseUserId,
    isLoadingAppointments as isLoadingAppointmentsSelector,
    leadsList,
    rateList,
    reducedAppointmentList,
    roleList,
    userList,
} from '../store/selectors';
import {
    Appointment,
    AppointmentDTO,
    CalendarRoleMap,
    CalendarRoleMapObject,
    ClientDTO,
    Conversion,
    CostCalculations,
    CostGroup,
    LeadDTO,
    RateObject,
    Role,
    RoleType,
} from '../types';
import { sortAppointments } from './datetime';

export function useCalculatedAppointments(
    filterStart: Date,
    filterEnd: Date,
    reviewMode = false,
    viewAsUser?: string,
) {
    const userId = useSelector(firebaseUserId, isEqual) ?? '';
    const calendarRoles = useSelector(calendarRoleList, isEqual) ?? {};
    const calendars = useSelector(calendarList, isEqual) ?? {};
    const appointments =
        useSelector(
            reducedAppointmentList({ reviewMode, viewAsUser }),
            isEqual,
        ) ?? {};
    const postponedAppointments =
        useSelector(
            reducedAppointmentList({ reviewMode, postponed: true, viewAsUser }),
            isEqual,
        ) ?? {};
    const conversions = useSelector(conversionList, isEqual) ?? {};
    const rates = useSelector(rateList, isEqual) ?? {};
    const leads = useSelector(leadsList, isEqual) ?? {};
    const users = useSelector(userList, isEqual) ?? {};
    const clients = useSelector(clientsList, isEqual) ?? {};
    const roles = useSelector(roleList, isEqual) ?? {};

    const reviewModeUsers = viewAsUser ? [viewAsUser] : Object.keys(users);
    const viewerIds = reviewMode ? reviewModeUsers : [viewAsUser ?? userId];
    const ownCalendarRoles = getCalendarRoles(calendarRoles, viewerIds, leads);
    const noCalendarsOwned = isEmpty(ownCalendarRoles);
    const filteredCalendars = noCalendarsOwned
        ? [-1]
        : Object.keys(ownCalendarRoles).map(toNumber);
    const startDateFilter = ['datetime', '>=', filterStart];
    const endDateFilter = ['datetime', '<=', filterEnd];
    const cancelledFilter = ['canceled', '==', false];
    const defaultFilter = [
        startDateFilter,
        endDateFilter,
        cancelledFilter,
    ] as WhereOptions[];

    const postponedStartDateFilter = ['postponedDate', '>=', filterStart];
    const postponedEndDateFilter = ['postponedDate', '<=', filterEnd];
    const postponedDefaultFilter = [
        postponedStartDateFilter,
        postponedEndDateFilter,
        cancelledFilter,
    ] as WhereOptions[];

    const storeAsPrefix = viewAsUser ? `${viewAsUser}-` : '';
    const filterChunks = chunk(filteredCalendars, 10);
    const appointmentQueries = reviewMode
        ? [
              {
                  collection: APPOINTMENTS_COLLECTION,
                  where: defaultFilter,
                  storeAs: `${APPOINTMENTS_COLLECTION}`,
              },
          ]
        : filterChunks.map((filterChunk, index) => {
              const appointmentsFilter = {
                  where: [
                      ...defaultFilter,
                      ['calendarID', 'in', filterChunk] as WhereOptions,
                  ],
                  storeAs: `${storeAsPrefix}${APPOINTMENTS_COLLECTION}-${index}`,
              };
              return {
                  collection: APPOINTMENTS_COLLECTION,
                  ...appointmentsFilter,
              };
          });

    const postponedAppointmentQueries = reviewMode
        ? [
              {
                  collection: APPOINTMENTS_COLLECTION,
                  where: postponedDefaultFilter,
                  storeAs: `${APPOINTMENTS_COLLECTION}-postponed`,
              },
          ]
        : filterChunks.map((filterChunk, index) => {
              const postponedAppointmentsFilter = {
                  where: [
                      ...postponedDefaultFilter,
                      ['calendarID', 'in', filterChunk] as WhereOptions,
                  ],
                  storeAs: `${storeAsPrefix}${APPOINTMENTS_COLLECTION}-${index}-postponed`,
              };
              return {
                  collection: APPOINTMENTS_COLLECTION,
                  ...postponedAppointmentsFilter,
              };
          });

    const appointmentsWithCosts = React.useMemo(() => {
        const calculatedAppointments = calculatePayouts(
            { ...appointments, ...postponedAppointments },
            ownCalendarRoles,
            conversions,
            rates,
            clients,
            roles,
        );
        return calculatedAppointments.sort(
            (a, b) => a.datetime.toMillis() - b.datetime.toMillis(),
        );
    }, [
        appointments,
        postponedAppointments,
        ownCalendarRoles,
        conversions,
        rates,
        clients,
    ]);
    useFirestoreConnect(() => [
        { collection: CALENDAR_ROLES_COLLECTION },
        ...appointmentQueries,
        ...postponedAppointmentQueries,
        { collection: CONVERSIONS_COLLECTION },
        { collection: RATES_COLLECTION },
        { collection: LEADS_COLLECTION },
        { collection: USERS_COLLECTION },
        { collection: CLIENTS_COLLECTION },
        { collection: ROLES_COLLECTION },
    ]);

    const isLoadingAppointments = useSelector(isLoadingAppointmentsSelector);
    const loadComplete =
        !isLoadingAppointments &&
        isLoaded(
            appointments,
            calendarRoles,
            conversions,
            rates,
            calendars,
            leads,
            users,
            clients,
        );

    return {
        appointments: { ...appointments, ...postponedAppointments },
        appointmentsWithCosts,
        isLoading: !loadComplete,
    };
}

function calculatePayouts(
    appointments: Dictionary<AppointmentDTO>,
    ownCalendarRoles: Dictionary<CalendarRoleMap>,
    conversions: Dictionary<Conversion>,
    rates: Dictionary<RateObject>,
    clients: Dictionary<ClientDTO>,
    roles: Dictionary<Role>,
): Appointment[] {
    const result = Object.entries(appointments).reduce(
        (acc: Appointment[], [appointmentId, appointment]) => {
            try {
                const { type, calendarID, firstName, lastName } = appointment;
                const calendarRoles = ownCalendarRoles[calendarID] ?? {};
                const conversion = Object.values(conversions).find(
                    (conversion) => {
                        return conversion.name === type;
                    },
                );
                if (isEmpty(conversion)) {
                    throw new Error(
                        `The appointment [${type}]] could not be found. Please check with your admin.`,
                    );
                }

                if (isEmpty(calendarRoles)) {
                    throw new Error(
                        `There are no staff roles assigned to the appointment's calendar. Please check with your admin.`,
                    );
                }

                const ratesToCharge = Object.entries(calendarRoles).reduce(
                    (acc, [staffId, role]) => {
                        const { roleId, roleType } = role;
                        const rateId = conversion?.rates?.[roleId];
                        if (isEmpty(rateId)) {
                            const isOptional = roles[roleId]?.optional ?? false;
                            if (isOptional) {
                                return acc;
                            }
                            throw new Error(
                                `There is no service rate assigned to your staff role for this appointment type. Please check with your admin.`,
                            );
                        }

                        const rate = rates[rateId!];
                        if (isEmpty(rate)) {
                            const isOptional = roles[roleId]?.optional ?? false;
                            if (isOptional) {
                                return acc;
                            }
                            throw new Error(
                                `A service rate assigned to this appointment type may have been removed, and could not be processed. You may need to remove the assigned rate, and create a new one. Please check with your admin.`,
                            );
                        }
                        return acc.concat({ rate, roleType, staffId });
                    },
                    [] as any,
                );
                const client = Object.values(clients).find((client) => {
                    return (
                        client?.firstName === firstName &&
                        client?.lastName === lastName
                    );
                });
                const isPreferredClient = client?.isPreferred ?? false;

                const numberOfNonLeadStaff = ratesToCharge.filter(
                    (rate) => rate?.roleType !== RoleType.lead,
                ).length;
                const appointments = ratesToCharge.map(
                    ({ rate, roleType, staffId }) => {
                        const costCalculations = getCostCalculations(
                            appointment,
                            rate,
                            numberOfNonLeadStaff,
                            isPreferredClient,
                        ) as Dictionary<CostGroup>;
                        return {
                            ...appointment,
                            calculations: {
                                ...appointment.calculations,
                                costs: costCalculations,
                            } as any as CostCalculations,
                            roleType,
                            staffId,
                            preferred: isPreferredClient,
                            rate,
                        } as Appointment;
                    },
                );
                return acc.concat(appointments);
            } catch (error) {
                const client = Object.values(clients).find((client) => {
                    return (
                        client?.firstName === appointment?.firstName &&
                        client?.lastName === appointment?.lastName
                    );
                });
                const errorAppointment = {
                    ...appointment,
                    calculations: {
                        ...appointment.calculations,
                        error: error.message || 'Unknown error',
                        preferred: client?.isPreferred ?? false,
                    },
                };
                return acc.concat([errorAppointment]);
            }
        },
        [] as Appointment[],
    );
    const sorted = sortAppointments(result);
    return sorted;
}

function getCalendarRoles(
    calendarRoles: Dictionary<CalendarRoleMapObject>,
    viewerIds: string[],
    leads: Dictionary<LeadDTO>,
) {
    const staffLedByViewer = Object.entries(leads).reduce(
        (acc: string[], [staffId, { lead }]) => {
            if (viewerIds.includes(lead)) {
                acc.push(staffId);
            }
            return acc;
        },
        [],
    );

    return Object.entries(calendarRoles).reduce(
        (acc, [calendarId, calendar]: any) => {
            const { roles = {} } = calendar;
            const ownRoles = Object.entries(roles).reduce(
                (acc, [staffId, roleId]) => {
                    const updates = {};
                    if (viewerIds.includes(staffId)) {
                        const staffHasLead = leads[staffId]?.lead;
                        const roleType = staffHasLead
                            ? RoleType.team
                            : RoleType.selfLead;
                        updates[staffId] = { roleId, roleType };
                    }
                    if (staffLedByViewer.includes(staffId)) {
                        const leadId = leads[staffId].lead;
                        updates[leadId] = { roleId, roleType: RoleType.lead };
                    }
                    return {
                        ...acc,
                        ...updates,
                    };
                },
                {},
            );

            if (isEmpty(ownRoles)) {
                return acc;
            }

            return {
                ...acc,
                [calendarId.toString()]: ownRoles,
            };
        },
        {},
    );
}

const getCostCalculations = (
    appointment: AppointmentDTO,
    rate: RateObject,
    numberOfNonLeadStaff: number,
    isPreferredClient = false,
) => {
    const {
        hoursOne,
        hoursTwo,
        numImages,
        sqft,
        sqftAux,
        shouldChargeTravelFee = false,
        overnightFee,
        travelFee,
    } = appointment?.formFields ?? {};
    const parsedHoursOne = Number.parseFloat(hoursOne || '0');
    const parsedHoursTwo = Number.parseFloat(hoursTwo || '0');
    const parsedNumImages = Number.parseFloat(numImages || '0');
    const parsedSqft = Number.parseFloat(sqft || '0');
    const parsedSqftAux = Number.parseFloat(sqftAux || '0');
    const parsedOvernightFee = Number.parseFloat(overnightFee || '0');
    const parsedTravelFee = Number.parseFloat(travelFee || '0');

    const {
        meta: {
            type: {
                flat = false,
                adjust1 = false,
                area = false,
                hourly = false,
            },
            label,
        },
        rates: { flat: flatRate, area: areaRate, hourly: hourlyRate },
    } = rate;

    let costCalculations: Dictionary<CostGroup> = {};

    // calculate flat rate
    if (flat && !isEmpty(flatRate)) {
        const {
            retail: { retail },
            payout: { lead, leadBonus, team },
        } = flatRate!;
        if (!retail) {
            throw new Error(`Rate ${label} is missing flat retail info.`);
        }
        if (!lead || !leadBonus || !team) {
            throw new Error(`Rate ${label} is missing flat payout info.`);
        }

        const {
            amount: retailAmount = 0,
            adjust1: retailAdjust1 = 0,
            included: retailIncluded = 0,
        } = retail;
        const { amount: leadAmount = 0, adjust1: leadAdjust1 = 0 } = lead;
        const { amount: leadBonusAmount = 0, adjust1: leadBonusAdjust1 = 0 } =
            leadBonus;
        const { amount: teamAmount = 0, adjust1: teamAdjust1 = 0 } = team;
        const numNotIncluded = Math.max(parsedNumImages - retailIncluded, 0);
        if (adjust1) {
            costCalculations = {
                ...costCalculations,
                flat: {
                    retail: retailAmount + retailAdjust1 * numNotIncluded,
                    lead: leadAmount + leadAdjust1 * numNotIncluded,
                    leadBonus:
                        leadBonusAmount + leadBonusAdjust1 * numNotIncluded,
                    team: teamAmount + teamAdjust1 * numNotIncluded,
                },
            } as Dictionary<CostGroup>;
        } else {
            costCalculations = {
                ...costCalculations,
                flat: {
                    retail: retailAmount,
                    lead: leadAmount,
                    leadBonus: leadBonusAmount,
                    team: teamAmount,
                },
            } as Dictionary<CostGroup>;
        }
    }

    // calculate area rate
    if (area && !isEmpty(areaRate)) {
        const {
            retail: { minimumCharge },
            payout: { lead, leadBonus, team },
        } = areaRate!;
        if (!minimumCharge) {
            throw new Error(`Rate ${label} is missing area retail info.`);
        }
        if (!lead || !leadBonus || !team) {
            throw new Error(`Rate ${label} is missing area payout info.`);
        }

        const {
            amount: retailMinimum = 0,
            psf1: minimumPsf1 = 0,
            psf2: minimumPsf2 = 0,
            includePsf2InMinCharge = true,
            minChargeMaxArea = 0,
        } = minimumCharge;
        const {
            amount: leadMinimum = 0,
            psf1: leadPsf1 = 0,
            psf2: leadPsf2 = 0,
        } = lead;
        const {
            amount: leadBonusMinimum = 0,
            psf1: leadBonusPsf1 = 0,
            psf2: leadBonusPsf2 = 0,
        } = leadBonus;
        const {
            amount: teamMinimum = 0,
            psf1: teamPsf1 = 0,
            psf2: teamPsf2 = 0,
        } = team;

        const sqftCredits = minChargeMaxArea ?? retailMinimum / minimumPsf1;
        const sqftToCharge = Math.max(parsedSqft - sqftCredits, 0);
        const creditsRemaining = Math.max(sqftCredits - parsedSqft, 0);
        const sqftAuxToCharge = includePsf2InMinCharge
            ? Math.max(0, parsedSqftAux - creditsRemaining)
            : parsedSqftAux;

        const areaRetail =
            retailMinimum +
            sqftToCharge * minimumPsf1 +
            sqftAuxToCharge * minimumPsf2;
        // if(appointment.id === 611359006){
        //     console.debug(sqftCredits, sqftToCharge, creditsRemaining, sqftAuxToCharge)
        //     console.debug('original calc', Math.max(leadMinimum, parsedSqft * leadPsf1 + parsedSqftAux * leadPsf2), parsedSqft, leadPsf1, parsedSqftAux, leadPsf2)
        // console.debug('new calc', Math.max(leadMinimum, leadMinimum + sqftToCharge * leadPsf1 + sqftAuxToCharge * leadPsf2), sqftToCharge, leadPsf1, sqftAuxToCharge, leadPsf2)
        // }

        // const areaRetail = includePsf2InMinCharge
        //     ? Math.max(retailMinimum, parsedSqft * minimumPsf1 + parsedSqftAux * minimumPsf2)
        //     : Math.max(retailMinimum, parsedSqft * minimumPsf1) + parsedSqftAux * minimumPsf2;

        costCalculations = {
            ...costCalculations,
            area: {
                retail: areaRetail,
                retailMin: retailMinimum,
                lead: Math.max(
                    leadMinimum,
                    leadMinimum +
                        sqftToCharge * leadPsf1 +
                        sqftAuxToCharge * leadPsf2,
                ),
                leadMin: leadMinimum,
                leadBonus: Math.max(
                    leadBonusMinimum,
                    leadBonusMinimum +
                        sqftToCharge * leadBonusPsf1 +
                        sqftAuxToCharge * leadBonusPsf2,
                ),
                leadBonusMin: leadBonusMinimum,
                team: Math.max(
                    teamMinimum,
                    teamMinimum +
                        sqftToCharge * teamPsf1 +
                        sqftAuxToCharge * teamPsf2,
                ),
                teamMin: teamMinimum,
            },
        } as Dictionary<CostGroup>;
    }

    // calculate hourly rate
    if (hourly && !isEmpty(hourlyRate)) {
        const {
            retail: { minimumCharge },
            payout: { lead, leadBonus, team },
        } = hourlyRate!;
        if (!minimumCharge) {
            throw new Error(`Rate ${label} is missing hourly retail info.`);
        }
        if (!lead || !leadBonus || !team) {
            throw new Error(`Rate ${label} is missing hourly payout info.`);
        }

        const {
            amount: retailMinimum = 0,
            rate1: minimumRate1 = 0,
            rate2: minimumRate2 = 0,
        } = minimumCharge;
        const {
            amount: leadMinimum = 0,
            rate1: leadRate1 = 0,
            rate2: leadRate2 = 0,
        } = lead;
        const {
            amount: leadBonusMinimum = 0,
            rate1: leadBonusRate1 = 0,
            rate2: leadBonusRate2 = 0,
        } = leadBonus;
        const {
            amount: teamMinimum = 0,
            rate1: teamRate1 = 0,
            rate2: teamRate2 = 0,
        } = team;
        costCalculations = {
            ...costCalculations,
            hourly: {
                retail: Math.max(
                    retailMinimum,
                    parsedHoursOne * minimumRate1 +
                        parsedHoursTwo * minimumRate2,
                ),
                lead: Math.max(
                    leadMinimum,
                    parsedHoursOne * leadRate1 + parsedHoursTwo * leadRate2,
                ),
                leadBonus: Math.max(
                    leadBonusMinimum,
                    parsedHoursOne * leadBonusRate1 +
                        parsedHoursTwo * leadBonusRate2,
                ),
                team: Math.max(
                    teamMinimum,
                    parsedHoursOne * teamRate1 + parsedHoursTwo * teamRate2,
                ),
            },
        } as Dictionary<CostGroup>;
    }

    // calculate additional fees
    costCalculations = {
        ...costCalculations,
        travel: {
            retail: shouldChargeTravelFee ? parsedTravelFee : 0,
            lead: parsedTravelFee / numberOfNonLeadStaff,
            leadBonus: 0,
            team: parsedTravelFee / numberOfNonLeadStaff,
        },
        overnight: {
            retail: shouldChargeTravelFee ? parsedOvernightFee : 0,
            lead: parsedOvernightFee / numberOfNonLeadStaff,
            leadBonus: 0,
            team: parsedOvernightFee / numberOfNonLeadStaff,
        },
    };

    // calculate totals
    const retailTotal = getCostGroupTotals(costCalculations, 'retail');
    const leadTotal = getCostGroupTotals(costCalculations, 'lead'); // + leadBonusTotal;
    const leadBonusTotal = getCostGroupTotals(costCalculations, 'leadBonus');
    const teamTotal = getCostGroupTotals(costCalculations, 'team');

    costCalculations = {
        ...costCalculations,
        totals: {
            retail: retailTotal,
            lead: leadTotal,
            leadBonus: leadBonusTotal,
            team: teamTotal,
        },
    } as Dictionary<CostGroup>;

    return costCalculations;
};

function getCostGroupTotals(
    costCalculations: Dictionary<CostGroup | undefined> = {},
    role: string,
): number {
    return Object.entries(costCalculations).reduce(
        (
            acc: any,
            [costGroupkey, costGroup]: [string, CostGroup | undefined],
        ) => {
            if (
                !costGroup ||
                costGroupkey === 'travel' ||
                costGroupkey === 'overnight'
            ) {
                return acc;
            }
            const cost = costGroup?.[role] || 0;
            return (acc.total ?? 0) + cost;
        },
        0,
    );
}
