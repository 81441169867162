import { Typography } from '@material-ui/core';
import classnames from 'classnames';
import React from 'react';

import { useStyles } from './Freshbooks.styles';

export function ClientStatusDisplay({
    clientMatched,
    response,
    error,
    statusText = 'Ready',
}: {
    clientMatched: boolean;
    response: string | null;
    error: string | null;
    statusText: string;
}) {
    const classes = useStyles();
    if (clientMatched) {
        if (error) {
            return (
                <Typography
                    component="span"
                    className={classnames(
                        classes.errorStatus,
                        classes.clientStatus,
                    )}
                >
                    {JSON.stringify(error)}
                </Typography>
            );
        }
        return (
            <Typography
                component="span"
                className={classnames(classes.okayStatus, classes.clientStatus)}
            >
                {response ? JSON.stringify(response) : `${statusText}`}
            </Typography>
        );
    }
    return (
        <Typography
            component="span"
            className={classnames(classes.errorStatus, classes.clientStatus)}
        >
            Could not match client with Freshbooks
        </Typography>
    );
}
