import {
    Button,
    Checkbox,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {
    Check,
    Clear,
    Edit,
    Error,
    FilterList,
    Star,
    Timer,
} from '@material-ui/icons';
import classnames from 'classnames';
import clsx from 'clsx';
import { isEmpty, orderBy as lodashOrder } from 'lodash-es';
import React from 'react';
import { useFirestore } from 'react-redux-firebase';

import { APPOINTMENTS_COLLECTION } from '../../../constants';
import { useToolbarStyles } from '../../../styles';
import { Appointment, InvoiceAppointmentRow } from '../../../types';
import { stopPropagation } from '../../../utils';
import { getMonthStart } from '../Appointments';
import { useStyles } from '../Appointments.styles';
import { ApproveButton } from '../ApproveButton';
import { formatAppointments } from '../generateInvoice';
import { isPostponed } from '../utils';
import {
    AppointmentsTableProps,
    Order,
    SortHandler,
} from './AppointmentsTable.types';

const DEMO_MODE =
    process.env.GATSBY_DEMO_MODE?.toLowerCase() === 'true' || false;

interface HeadCell {
    disablePadding: boolean;
    id: keyof InvoiceAppointmentRow;
    label: string;
    align: TableCellProps['align'];
    styleName?: string;
}

const headCells: HeadCell[] = [
    { id: 'datetime', align: 'left', disablePadding: false, label: 'Date' },
    { id: 'displayName', align: 'left', disablePadding: true, label: 'Client' },
    {
        id: 'description',
        align: 'left',
        disablePadding: true,
        label: 'Description',
    },
    { id: 'address', align: 'left', disablePadding: true, label: 'Address' },
    { id: 'calendar', align: 'left', disablePadding: false, label: 'Calendar' },
    {
        id: 'approved',
        align: 'center',
        disablePadding: true,
        label: 'Approved',
        styleName: 'linkColumnHeader',
    },
    {
        id: 'id',
        align: 'center',
        disablePadding: true,
        label: 'Link',
        styleName: 'linkColumnHeader',
    },
    { id: 'fee', align: 'right', disablePadding: false, label: 'Fee ($)' },
    {
        id: 'additional',
        align: 'right',
        disablePadding: false,
        label: 'Travel & Delivery ($)',
    },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof InvoiceAppointmentRow,
    ) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const {
        classes,
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler =
        (property: keyof InvoiceAppointmentRow) =>
        (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes[headCell.styleName || '']}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    selected: InvoiceAppointmentRow[];
    onNotification: (message: string) => void;
    endDate: Date;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const { selected } = props;
    const classes = useToolbarStyles();
    const numSelected = selected.length;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography
                    className={classes.title}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Appointments
                </Typography>
            )}
            {numSelected > 0 ? (
                <SelectedAppointmentsToolbar {...props} />
            ) : (
                <Tooltip title="Filter list">
                    <IconButton aria-label="filter list">
                        <FilterList />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

function SelectedAppointmentsToolbar({
    onNotification,
    selected,
    endDate,
}: EnhancedTableToolbarProps) {
    const firestore = useFirestore();

    const allSelectedAppointmentsApproved = selected.every(
        (appointment) => appointment.approved,
    );
    const allSelectedAppointmentsPostponed = selected.every((appointment) =>
        isPostponed(appointment, endDate),
    );

    const setApproval = React.useCallback(async () => {
        const approved = !allSelectedAppointmentsApproved;

        const writeBatch = firestore.batch();
        selected.forEach((appointment) => {
            const { id } = appointment;
            const docRef = firestore.doc(`${APPOINTMENTS_COLLECTION}/${id}`);
            writeBatch.update(docRef, { approved });
        });

        writeBatch.commit().then((_res) => {
            const approveText = approved ? 'approved' : 'unapproved';
            onNotification(`${selected.length} appointments ${approveText}`);
        });
    }, [allSelectedAppointmentsApproved, selected]);

    const postponeAppointmentInvoice = React.useCallback(async () => {
        const postponed = !allSelectedAppointmentsPostponed;

        const writeBatch = firestore.batch();
        selected.forEach((appointment) => {
            const { id } = appointment;
            const docRef = firestore.doc(`${APPOINTMENTS_COLLECTION}/${id}`);

            const appointmentDate =
                appointment.postponedDate ?? appointment.rawDatetime;
            const monthStart = getMonthStart(appointmentDate);
            const month = monthStart.getMonth();
            const year = monthStart.getFullYear();
            const newMonth = (month + 1) % 12;
            const newYear = newMonth < month ? year + 1 : year;
            const postponedDate = postponed
                ? getMonthStart(appointmentDate)
                : null;
            if (postponedDate) {
                postponedDate.setFullYear(newYear, newMonth);
            }
            writeBatch.update(docRef, { postponedDate });
        });

        writeBatch.commit().then((_res) => {
            const postponeText = postponed ? 'postponed' : 'un-postponed';
            onNotification(`${selected.length} appointments ${postponeText}`);
        });
    }, [allSelectedAppointmentsPostponed, selected]);

    return (
        <Grid direction="row" container item justify="flex-end">
            <Tooltip
                title={
                    allSelectedAppointmentsPostponed
                        ? 'Un-Postpone all selected appointments'
                        : 'Postpone all selected appointments'
                }
            >
                <Button
                    startIcon={<Timer />}
                    aria-label="postpone-all"
                    onClick={postponeAppointmentInvoice}
                >
                    {allSelectedAppointmentsPostponed
                        ? 'Un-Postpone Selected'
                        : 'Postpone Selected'}
                </Button>
            </Tooltip>
            <Tooltip
                title={
                    allSelectedAppointmentsApproved
                        ? 'Unapprove all selected appointments'
                        : 'Approve all selected appoingments'
                }
            >
                <Button
                    startIcon={
                        allSelectedAppointmentsApproved ? <Clear /> : <Check />
                    }
                    aria-label="approve-all"
                    onClick={setApproval}
                >
                    {allSelectedAppointmentsApproved
                        ? 'Un-Approve Selected'
                        : 'Approve Selected'}
                </Button>
            </Tooltip>
        </Grid>
    );
}

export default function EnhancedTable({
    data: rawAppointments,
    reviewMode = false,
    onNotification,
    endDate,
}: AppointmentsTableProps) {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] =
        React.useState<keyof InvoiceAppointmentRow>('datetime');
    const [selected, setSelected] = React.useState<number[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);

    const appointments = React.useMemo(
        () => formatAppointments(rawAppointments),
        [rawAppointments],
    );

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof InvoiceAppointmentRow,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.checked) {
            const newSelecteds = appointments.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        // debug //
        const selectedAppointments = rawAppointments.filter(
            (appointment) => appointment.id === id,
        );
        const cost = selectedAppointments.reduce((acc, appointment) => {
            return acc + (appointment.calculations.costs?.totals.retail ?? 0);
        }, 0);
        console.log(cost, selectedAppointments);

        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, appointments.length - page * rowsPerPage);

    const sortHandlers: SortHandler = {
        email: (row: Appointment) => {
            return row.email?.toLowerCase() || '';
        },
        datetime: (row: any) => {
            return row.rawDatetime;
        },
    };

    return (
        <div className={classes.root}>
            <Paper
                className={classes.paper}
                classes={{
                    root: classes.paperRoot,
                }}
            >
                <EnhancedTableToolbar
                    selected={appointments.filter((row) =>
                        selected.includes(row.id),
                    )}
                    onNotification={onNotification}
                    endDate={endDate}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={appointments.length}
                        />
                        <TableBody>
                            {lodashOrder(
                                appointments,
                                [sortHandlers[orderBy] || orderBy],
                                [order],
                            )
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map(
                                    (
                                        row: InvoiceAppointmentRow,
                                        index: number,
                                    ) => {
                                        const isItemSelected = isSelected(
                                            castInt(row.id),
                                        );
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        const { hasError } =
                                            appointmentErrors(row);

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) =>
                                                    handleClick(
                                                        event,
                                                        castInt(row.id),
                                                    )
                                                }
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={`${row.id}-${row.staffId}-${row.roleType}`}
                                                selected={isItemSelected}
                                                className={classnames({
                                                    [classes.appointmentRowError]:
                                                        hasError,
                                                })}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby':
                                                                labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {formatDateTime(
                                                        row,
                                                        endDate,
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    <ClientDisplay row={row} />
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.descriptionText
                                                    }
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {row.description}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {row.address}
                                                </TableCell>
                                                <TableCell>
                                                    <CalendarDisplay
                                                        calendar={row.calendar}
                                                        isLead={
                                                            row.roleType ===
                                                            'lead'
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className={
                                                        classes.approveButtonColumn
                                                    }
                                                >
                                                    <ApproveButton
                                                        appointment={row}
                                                        endDate={endDate}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        className={
                                                            classes.iconButton
                                                        }
                                                        color="primary"
                                                        aria-label="view appointment"
                                                        href={`https://secure.acuityscheduling.com/appointments.php?action=detail&id=${row.id}`}
                                                        target="_blank"
                                                        onClick={
                                                            stopPropagation
                                                        }
                                                        onMouseDown={
                                                            stopPropagation
                                                        }
                                                    >
                                                        <Edit />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <FeeDisplay
                                                        row={row}
                                                        _reviewMode={reviewMode}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <AdditionalCostDisplay
                                                        row={row}
                                                        _reviewMode={reviewMode}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    },
                                )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={appointments.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

function CalendarDisplay({ calendar, _isLead = false }: any) {
    // const calendarText = isLead ? `${calendar} (Lead)` : calendar;
    const calendarText = calendar;
    return <Typography>{calendarText}</Typography>;
}

function formatDateTime(row: InvoiceAppointmentRow, endDate: Date) {
    const postponed = isPostponed(row, endDate);
    const { datetime } = row;
    if (postponed) {
        return `${datetime} (Postponed)`;
    }

    return datetime;
}

function FeeDisplay({
    row,
    _reviewMode = false,
}: {
    row: InvoiceAppointmentRow;
    _reviewMode: boolean;
}) {
    const classes = useStyles();

    if (DEMO_MODE) {
        return <Typography>$123.45</Typography>;
    }

    const { hasError, error, missingCosts } = appointmentErrors(row);
    if (hasError || missingCosts) {
        let errorMessage = missingCosts
            ? 'Could not load costs'
            : 'Unknown error';
        errorMessage = hasError ? error! : errorMessage;

        return (
            <Tooltip title={errorMessage}>
                <Error
                    className={classnames(
                        classes.iconButton,
                        classes.errorIconButton,
                    )}
                />
            </Tooltip>
        );
    }

    return <Typography>{row.fee}</Typography>;
}

function AdditionalCostDisplay({
    row,
    _reviewMode = false,
}: {
    row: InvoiceAppointmentRow;
    _reviewMode: boolean;
}) {
    if (DEMO_MODE) {
        return <Typography>$123.45</Typography>;
    }

    const { hasError, missingCosts } = appointmentErrors(row);
    if (hasError || missingCosts) {
        return null;
    }

    return <Typography>{row.additional}</Typography>;
}

function ClientDisplay({ row }: { row: InvoiceAppointmentRow }) {
    const classes = useStyles();
    return (
        <Grid container alignItems="center" direction="row">
            {row.displayName}
            {row.preferred && <Star className={classes.prefStarIcon} />}
        </Grid>
    );
}

const castInt = (number: string | number): number => {
    if (typeof number === 'number') {
        return number;
    }

    return Number.parseInt(number, 10);
};

function appointmentErrors(appointment: InvoiceAppointmentRow) {
    const {
        calculations: { costs, error },
    } = appointment;

    const hasError = !isEmpty(error);
    const missingCosts = isEmpty(costs);
    return { hasError, error, missingCosts };
}
