import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        paperRoot: {
            background: 'none',
            backgroundColor: 'none',
        },
        table: {
            minWidth: 750,
        },
        tableCell: {
            '&:first-child': {
                paddingLeft: theme.spacing(3),
            },
            '&:last-child': {
                paddingRight: theme.spacing(3),
            },
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    });

export const useStyles = makeStyles(styles);
