import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { Button } from '@material-ui/core';
import { useStyles } from './SignInOutButton.styles';

const Signout = () => {
    const classes = useStyles();
    const firebase = useFirebase();

    const signOut = React.useCallback(() => {
        firebase.logout();
    }, [firebase]);

    return (
        <Button className={classes.button} onClick={signOut}>
            Sign Out
        </Button>
    );
};

export default Signout;
