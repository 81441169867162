import { makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        button: {
            margin: `0px ${theme.spacing(2)}px`,
            padding: theme.spacing(0.5),
            borderRadius: 0,
            backgroundColor: theme.palette.grey[700],
            fontFamily: 'GillSans',
            color: '#0f0f0f',
            '&:hover': {
                backgroundColor: theme.palette.grey[500],
            },
        },
    });

export const useStyles = makeStyles(styles);
