export enum InvoiceUploadStatus {
    Pending = 'PENDING',
    Success = 'SUCCESS',
    Error = 'ERROR',
    None = 'NONE',
}

export enum PageStatus {
    Idle = 'IDLE',
    Uploading = 'UPLOADING',
    SettingPaymentOptions = 'SETTING_PAYMENT_OPTIONS',
    UploadComplete = 'UPLOAD_COMPLETE',
}
