import firebase from 'firebase/app';
import fbConfig from '../../firebase-config';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

export const getUiConfig = (firebase) => ({
    signInFlow: 'popup',
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
});

firebase.initializeApp(fbConfig);
firebase.firestore().settings({
    ignoreUndefinedProperties: true,
});

export default firebase;
