import * as React from 'react';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import classnames from 'classnames';
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import { useFirestoreConnect, useFirestore, isLoaded, Dictionary } from 'react-redux-firebase';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InfoRounded } from '@material-ui/icons';
import { fromPairs, isEmpty, lowerCase, sortBy, toPairs, debounce } from 'lodash-es';
import { useLocation } from '@reach/router';
import { FRESHBOOKS_DEV, FRESHBOOKS_PROD } from '../../../../freshbooks-config';

import { ClientDTO, FreshbooksClientDTO } from '../../types';
import { CLIENTS_COLLECTION } from '../../constants';
import { clientsList } from '../../store/selectors';
import { ACCESS_TOKEN_KEY, getFbAuth, getFbClients, getFbMe, REFRESH_TOKEN_KEY, verifyAuth } from '../Freshbooks/freshbooksAdapter';

import Spinner from '../Spinner';
import { useStyles } from './ClientManagement.styles';
import {
    ClientDetailsPanelProps,
    ClientListPanelProps,
    FreshbooksClientMatcherProps,
    FreshbooksClientSelectorProps,
    MatchMethod
} from './ClientManage.types' ;
import { PageTitle } from '../PageTitle';

const IS_DEV = process.env.GATSBY_ENV === 'DEV';
const FRESHBOOKS_CONFIG = IS_DEV ? FRESHBOOKS_DEV : FRESHBOOKS_PROD;

const {GATSBY_FRESHBOOKS_CLIENTS_AUTH_LINK, GATSBY_FRESHBOOKS_CLIENTS_REDIRECT_URI} = FRESHBOOKS_CONFIG;

function getAuth( ) {
    return getFbAuth(GATSBY_FRESHBOOKS_CLIENTS_REDIRECT_URI, GATSBY_FRESHBOOKS_CLIENTS_AUTH_LINK);
}

function ClientManagement() {
    const classes = useStyles();

    useFirestoreConnect(() => [{ collection: CLIENTS_COLLECTION }]);
    const clients = useSelector(clientsList);
    const sortedClients = React.useMemo(() => sortClients(clients), [clients]);
    const clientsLoaded = isLoaded(clients);

    const [isFreshbooksAuthenticated, setIsFreshbooksAuthenticated] = React.useState(false);
    const [, setFbMe] = React.useState<any>(null);
    const [freshbooksClients, setFreshbooksClients] = React.useState<FreshbooksClientDTO[] | null>(null);
    const [, setError] = React.useState<string | null>(null);
    const [accountId, setAccountId] = React.useState<string | null>(null);

    const location = useLocation();

    React.useEffect(() => {
        let isSubscribed = true;
        const fbAuth = getAuth();
        const searchParams = new URLSearchParams(location.search);
        const fbAuthCode = searchParams.get('code');
        if (fbAuthCode) {
            fbAuth.code
                .getToken(location)
                .then(({ accessToken, refreshToken }) => {
                    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
                    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
                    if (isSubscribed) {
                        setIsFreshbooksAuthenticated(true);
                    }
                    window.history.replaceState(null, document.title, location.pathname);
                })
                .catch((reason) => console.error(`Failed to authenticate Freshbooks: ${reason}`));
        } else {
            verifyAuth(fbAuth)
                .then((user) => {
                    if (isSubscribed) {
                        setIsFreshbooksAuthenticated(true);
                    }
                })
                .catch((reason) => {
                    console.error('Failed to verify authentication', reason);
                    if (isSubscribed) {
                        setIsFreshbooksAuthenticated(false);
                    }
                });
        }
        return () => {
            isSubscribed = false;
        };
    }, []);

    React.useEffect(() => {
        if (!isFreshbooksAuthenticated) {
            return;
        }
        getFbMe()
            .then(async (result) => {
                const { response } = await result.json();
                setFbMe(response);
                const { subscription_statuses = {} } = response;
                const activeAccount =
                    Object.entries(subscription_statuses).find(([_, accountStatus]) => {
                        return accountStatus === 'active' || accountStatus === 'active_trial';
                    }) || [];
                const [accountKey] = activeAccount;
                if (accountKey) {
                    setAccountId(accountKey);
                } else {
                    setError('Failed to load account subscription.');
                }
            })
            .catch((reason) => {
                setError(`Failed to load your account details: ${reason}`);
            });
    }, [isFreshbooksAuthenticated]);

    React.useEffect(() => {
        if (isEmpty(accountId)) {
            return;
        }

        getFbClients(accountId!)
            .then((clients: FreshbooksClientDTO[]) => {
                setFreshbooksClients(clients);
            })
            .catch((reason: string) => {
                setError(`Failed to load client details: ${reason}`);
            });
    }, [accountId]);

    const [selectedClientId, setSelectedClientId] = React.useState('');

    const onSignOutFreshbooks = () => {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        setIsFreshbooksAuthenticated(false);
    };

    if (!clientsLoaded) {
        return <Spinner message="Loading clients from Acuity..." />;
    }

    return (
        <Grid container direction="row" className={classes.root}>
            <ClientListPanel
                clients={sortedClients}
                selectedClientId={selectedClientId}
                onSelect={setSelectedClientId}
                freshbooksClients={freshbooksClients}
            />
            <ClientDetailsPanel
                clients={sortedClients}
                clientId={selectedClientId}
                freshbooksClients={freshbooksClients}
                isFreshbooksAuthenticated={isFreshbooksAuthenticated}
                handleFreshbooksSignout={onSignOutFreshbooks}
            />
        </Grid>
    );
}

function ClientListPanel({ clients, selectedClientId, onSelect, freshbooksClients }: ClientListPanelProps) {
    const classes = useStyles();

    const searchRef = React.useRef<any>(null);
    const getFilteredClients = () => {
        const searchQuery = searchRef?.current?.value?.toLowerCase() || '';
        const filtered = Object.entries(clients).filter(([, client]) => client?.displayName?.toLowerCase().includes(searchQuery));
        return filtered;
    };
    const [filteredClients, setFilteredClients] = React.useState(getFilteredClients());

    const onFilterChange = debounce(() => {
        setFilteredClients(getFilteredClients());
    }, 300);

    React.useEffect(() => {
        setFilteredClients(getFilteredClients());
    }, [clients]);

    const handleListItemClick = React.useCallback(
        (clientId: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            onSelect(clientId);
        },
        [onSelect],
    );

    const numMissingMatches =
        freshbooksClients === null
            ? undefined
            : filteredClients.reduce((acc, [, client]) => {
                  const matchingClient = findMatchingFreshbooksClient(client, freshbooksClients);
                  return !matchingClient ? acc + 1 : acc;
              }, 0);
    const missingMessage = numMissingMatches ? `${numMissingMatches} not matched with Freshbooks` : '';

    return (
        <Grid container item className={classes.clientsListPanel} xs={4}>
            <PageTitle
                title="Clients"
                tooltip="Acuity clients will be synced here. Changing a client to preferred will cause the preferred rate to be used for all Rates that offer a preferred discount. When matching clients to Freshbooks users, both the name and email should match. In special cases, name matching can be turned off."
                gridProps={{
                    className: classes.title,
                }}
            />
            <Grid
                container
                item
                direction="row"
                alignContent="center"
                justify="space-between"
                className={classes.clientsListPanelHeaderContainer}
            >
                <Grid item>
                    <Typography className={classes.clientsListTitle}>{`${Object.keys(clients).length} Clients`}</Typography>
                    {missingMessage && (
                        <Typography className={(classes.clientsListTitle, classes.errorMessage)}>{missingMessage}</Typography>
                    )}
                </Grid>
            </Grid>
            <TextField
                fullWidth
                inputRef={searchRef}
                name="Filter"
                type="text"
                placeholder="Search"
                label="Filter"
                onChange={() => onFilterChange()}
            />
            <List className={classes.clientsListContainer}>
                {filteredClients.map(([clientId, client]) => {
                    if (!client) {
                        return null;
                    }
                    const match = findMatchingFreshbooksClient(client, freshbooksClients);
                    const hasMatchingClient = isEmpty(freshbooksClients) || Boolean(match);
                    const { displayName } = client;
                    return (
                        <ListItem
                            key={`${clientId}-list-item`}
                            button
                            selected={selectedClientId === clientId}
                            onClick={handleListItemClick(clientId)}
                            className={classnames({
                                [classes.isMissingFreshbooksMatch]: !hasMatchingClient,
                            })}
                        >
                            <ListItemText primary={displayName} />
                        </ListItem>
                    );
                })}
            </List>
        </Grid>
    );
}

function ClientDetailsPanel({
    clients,
    clientId,
    freshbooksClients,
    isFreshbooksAuthenticated,
    handleFreshbooksSignout,
}: ClientDetailsPanelProps) {
    const classes = useStyles();
    const firestore = useFirestore();
    const client = clients[clientId];

    const handleFormSubmit = (values: Partial<ClientDTO>) => {
        const { isPreferred, useEmailForBilling, billingEmail, matchMethod } = values;
        const docRef = firestore.doc(`${CLIENTS_COLLECTION}/${clientId}`);
        const billingEmailToSave = useEmailForBilling ? '' : billingEmail;
        docRef.update({ isPreferred, useEmailForBilling, billingEmail: billingEmailToSave, matchMethod });
    };

    if (!client || clientId === undefined) {
        return (
            <Grid item xs className={classes.clientDetailsPanelContainer}>
                <Typography variant="body1" className={classes.clientDetailsContents}>
                    No client selected
                </Typography>
            </Grid>
        );
    }

    const {
        firstName = '',
        lastName = '',
        email = '',
        phone = '',
        notes = '',
        isPreferred = false,
        useEmailForBilling = false,
        billingEmail = '',
        matchEmailOnly = false,
        matchMethod,
    } = client;
    const defaultMatchMethod = matchMethod ?? (matchEmailOnly ? MatchMethod.EmailOnly : MatchMethod.EmailAndName);

    const FormSchema = Yup.object().shape({
        isPreferred: Yup.boolean(),
        useEmailForBilling: Yup.boolean(),
        matchMethod: Yup.string().required(),
        billingEmail: Yup.string().email(),
    });

    return (
        <Grid item xs container direction="row" className={classes.clientDetailsPanelContainer}>
            <Grid container className={classes.root} direction="column" wrap="nowrap">
                <Formik
                    enableReinitialize
                    initialValues={{
                        isPreferred,
                        useEmailForBilling,
                        billingEmail,
                        matchMethod: defaultMatchMethod
                    }}
                    validationSchema={FormSchema}
                    onSubmit={handleFormSubmit}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <Grid container item direction="row" alignItems="stretch">
                                <Grid item container justify="flex-end">
                                    {isFreshbooksAuthenticated && <Button onClick={handleFreshbooksSignout}>Sign out of Freshbooks</Button>}
                                </Grid>
                                <Grid className={classes.formColumn} container item direction="column" xs>
                                    <Typography className={classes.sectionHeader} variant="h5">
                                        Acuity Profile
                                    </Typography>
                                    <Grid className={classes.textFieldContainer} container item direction="row" alignItems="stretch">
                                        <FormTextDisplayField fieldName="First Name" fieldValue={firstName} />
                                        <FormTextDisplayField fieldName="Last Name" fieldValue={lastName} />
                                        <FormTextDisplayField fieldName="Email" fieldValue={email} />
                                        <FormTextDisplayField fieldName="Phone" fieldValue={phone} />
                                        <FormTextDisplayField fieldName="Notes" fieldValue={isEmpty(notes) ? 'None' : notes} />
                                    </Grid>
                                </Grid>
                                <Grid className={classes.formColumn} container item direction="column" xs>
                                    <Typography className={classes.sectionHeader} variant="h5">
                                        Options
                                    </Typography>
                                    <Grid
                                        className={classes.textFieldContainer}
                                        container
                                        item
                                        direction="row"
                                        wrap="nowrap"
                                        alignItems="center"
                                    >
                                        <Field name="isPreferred" type="checkbox">
                                            {({ field, form: { touched, errors } }: { field: any; form: any }) => (
                                                <FormControlLabel
                                                    {...field}
                                                    control={<Checkbox color="primary" />}
                                                    label="Preferred Client?"
                                                    labelPlacement="start"
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <FieldError touched={touched} errors={errors} fieldName="isPreferred" />

                                    <Grid className={classes.textFieldContainer} container item direction="column" alignItems="flex-start">
                                        <Field name="useEmailForBilling" type="checkbox">
                                            {({ field, form: { touched, errors } }: { field: any; form: any }) => (
                                                <FormControlLabel
                                                    {...field}
                                                    control={<Checkbox color="primary" />}
                                                    label="Use Acuity email for billing?"
                                                    labelPlacement="start"
                                                />
                                            )}
                                        </Field>
                                        <FieldError touched={touched} errors={errors} fieldName="useEmailForBilling" />
                                        <Field name="useEmailForBilling" type="checkbox">
                                            {({ field, form: { touched, errors } }: { field: any; form: any }) => (
                                                <Grid>
                                                    <Grid
                                                        container
                                                        item
                                                        direction="column"
                                                        className={classes.billingEmailContainer}
                                                        alignItems="flex-start"
                                                    >
                                                        <InputLabel className={classes.inputLabel} htmlFor="billingEmail">
                                                            Billing Email
                                                        </InputLabel>
                                                        <Field
                                                            className={classes.textField}
                                                            id="billingEmail"
                                                            aria-describedby="billingEmail"
                                                            disabled={field.checked}
                                                            type="text"
                                                            name="billingEmail"
                                                        />
                                                    </Grid>
                                                    {!field.checked && (
                                                        <FieldError touched={touched} errors={errors} fieldName="billingEmail" />
                                                    )}
                                                </Grid>
                                            )}
                                        </Field>
                                    </Grid>

                                    <Grid
                                        className={classnames(classes.textFieldContainer, classes.radioGroup)}
                                        container
                                        item
                                        direction="column"
                                        wrap="nowrap"
                                        role="group" 
                                        aria-labelledby="my-radio-group"
                                    >
                                        <Grid direction="row" wrap="nowrap" container>
                                            <Typography>Match Method</Typography>
                                            <Tooltip
                                            title="Acuity clients are matched to Freshbooks clients by both name and email by default. In special cases where the invoices are managed by someone else and the name may not match, select Email Only matching. If multiple emails are used by the same client under the same name, select Name Only matching."
                                            className={classnames(classes.tooltip, classes.inlineTooltip)}
                                            placement="right"
                                        >
                                            <IconButton aria-label="info-rounded">
                                                <InfoRounded />
                                            </IconButton>
                                        </Tooltip>
                                        </Grid>
                                        
                                        <label className={classes.radioOption}>
                                            <Field type="radio" name="matchMethod" value={MatchMethod.EmailAndName} />
                                            <Typography className={classes.radioOptionLabel} component="span">Email and Name (Default)</Typography>
                                        </label>
                                        <label className={classes.radioOption}>
                                            <Field type="radio" name="matchMethod" value={MatchMethod.EmailOnly} />
                                            <Typography className={classes.radioOptionLabel} component="span">Email Only</Typography>
                                        </label>
                                        <label className={classes.radioOption}>
                                            <Field type="radio" name="matchMethod" value={MatchMethod.NameOnly} />
                                            <Typography className={classes.radioOptionLabel} component="span">Name Only</Typography>
                                        </label>
                                    </Grid>
                                    <FieldError touched={touched} errors={errors} fieldName="matchMethod" />
                                </Grid>
                            </Grid>
                            <Grid
                                className={classes.updateButtonContainer}
                                direction="row"
                                alignContent="center"
                                justify="center"
                                container
                                item
                                xs
                            >
                                <Button className={classes.formButton} type="submit" variant="contained">
                                    Update
                                </Button>
                            </Grid>
                        </Form>
                    )}
                </Formik>
                <FreshbooksClientMatcher
                    acuityClient={client}
                    clientId={clientId}
                    freshbooksClients={freshbooksClients}
                    isFreshbooksAuthenticated={isFreshbooksAuthenticated}
                />
            </Grid>
        </Grid>
    );
}

function FreshbooksClientMatcher({ clientId, acuityClient, freshbooksClients, isFreshbooksAuthenticated }: FreshbooksClientMatcherProps) {
    const classes = useStyles();
    const firestore = useFirestore();

    const authenticateFreshbooks = React.useCallback(() => {
        const fbAuth = getAuth();
        const uri = fbAuth.code.getUri();
        navigate(uri);
    }, []);

    if (!isFreshbooksAuthenticated) {
        return <Button onClick={authenticateFreshbooks}>Sign in to Freshbooks for Client Data</Button>;
    }

    if (freshbooksClients === undefined) {
        return <Spinner message="Loading Freshbooks clients..." />;
    }

    if (isEmpty(freshbooksClients)) {
        return <Spinner message="No Freshbooks clients available." />;
    }

    function onClientSelected(freshbooksClient: FreshbooksClientDTO) {
        const docRef = firestore.doc(`${CLIENTS_COLLECTION}/${clientId}`);
        const { firstName = '', lastName = '', email: acuityEmail } = acuityClient;
        const { fname, lname, email } = freshbooksClient;
        const fnameMatches = !isEmpty(fname) && firstName.toLowerCase() === fname.toLowerCase();
        const lnameMatches = !isEmpty(lname) && lastName.toLowerCase() === lname.toLowerCase();
        const nameMatches = fnameMatches && lnameMatches;
        const emailMatches = !isEmpty(email) && acuityEmail.toLowerCase() === email.toLowerCase();

        const matchEmailOnly = emailMatches && !nameMatches;
        const matchNameOnly = nameMatches && !emailMatches;
        let matchMethod = MatchMethod.EmailAndName;
        if(matchEmailOnly){
            matchMethod = MatchMethod.EmailOnly;
        }
        if(matchNameOnly){
            matchMethod = MatchMethod.NameOnly;
        }

        docRef.update({ useEmailForBilling: false, billingEmail: email, matchMethod });
    }

    const matchingClient = findMatchingFreshbooksClient(acuityClient, freshbooksClients);
    if (!matchingClient) {
        return (
            <Grid container item direction="row" alignItems="stretch">
                <Grid className={classes.formColumn} container item direction="column" xs>
                    <Typography className={classes.sectionHeader} variant="h5">
                        Freshbooks Client
                    </Typography>
                    <Grid className={classes.textFieldContainer} container item direction="row" alignItems="stretch">
                        <Typography className={classes.freshbooksNoMatchMessage}>
                            No matching Freshbooks client found. Consider matching by email or name only in the client options (remember to click update).
                        </Typography>
                        <FreshbooksClientSelector freshbooksClients={freshbooksClients} onClientSelected={onClientSelected} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const { fname, lname, email, note } = matchingClient;

    return (
        <Grid container item direction="row" alignItems="stretch">
            <Grid className={classes.formColumn} container item direction="column" xs>
                <Typography className={classes.sectionHeader} variant="h5">
                    Freshbooks Client
                </Typography>
                <Grid className={classes.textFieldContainer} container item direction="row" alignItems="stretch">
                    <FormTextDisplayField fieldName="First Name" fieldValue={fname} />
                    <FormTextDisplayField fieldName="Last Name" fieldValue={lname} />
                    <FormTextDisplayField fieldName="Email" fieldValue={email} />
                    <FormTextDisplayField fieldName="Notes" fieldValue={isEmpty(note) ? 'None' : note} />
                </Grid>
            </Grid>
        </Grid>
    );
}

function FreshbooksClientSelector({ freshbooksClients, onClientSelected }: FreshbooksClientSelectorProps) {
    const classes = useStyles();
    const [sortedFreshbooksClients, setSortedFreshbooksClients] = React.useState<FreshbooksClientDTO[] | null>(null);
    const [selectedClient, setSelectedClient] = React.useState<FreshbooksClientDTO | null>(freshbooksClients?.[0] ?? null);
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (freshbooksClients) {
            setSortedFreshbooksClients(sortFbClients(freshbooksClients));
        }
    }, [freshbooksClients]);

    if (sortedFreshbooksClients === null) {
        return null;
    }

    function handleClientSelected() {
        if (!selectedClient) {
            setError('No client selected');
            return;
        }

        setError(null);
        onClientSelected(selectedClient);
    }

    return (
        <Grid container direction="column" wrap="nowrap">
            <Typography className={classes.freshbooksNoMatchMessage}>
                If you know the email address of the Freshbooks client, you can assign it as the billing email for this Acuity client
            </Typography>
            <Autocomplete
                className={classes.freshbooksSelectorAutocomplete}
                id="freshbooks-client-selector"
                options={sortedFreshbooksClients}
                getOptionLabel={(option) => {
                    const { fname = '', lname = '', email = '' } = option;
                    return `${email} <${fname} ${lname}>`;
                }}
                getOptionSelected={(option, value) => {
                    return option.email === value.email && option.fname === value.fname && option.lname === value.lname;
                }}
                value={selectedClient}
                onChange={(event: any, newValue: FreshbooksClientDTO | null) => {
                    setSelectedClient(newValue);
                }}
                style={{ width: '100%' }}
                renderInput={(params) => <TextField {...params} label="Freshbooks Clients" variant="outlined" />}
            />
            {error && <Typography>{error}</Typography>}
            <Button onClick={handleClientSelected} variant="outlined">
                Assign email to client
            </Button>
        </Grid>
    );
}

function FormTextDisplayField({ fieldName, fieldValue }: { fieldName: string; fieldValue: string }) {
    const classes = useStyles();
    return (
        <Grid item container direction="column" className={classes.formTextContainer}>
            <Typography className={classes.formTextTitle}>{fieldName.toUpperCase()}</Typography>
            <Typography className={classes.formTextValue}>{fieldValue}</Typography>
        </Grid>
    );
}

function sortClients(clients?: Dictionary<ClientDTO>) {
    if (isEmpty(clients)) {
        return {};
    }
    return fromPairs(
        sortBy(toPairs(clients), [
            ([key, client]) => {
                return lowerCase(client?.displayName || '');
            },
        ]),
    );
}

function sortFbClients(clients: FreshbooksClientDTO[] | null) {
    if (clients === null) {
        return null;
    }
    return clients.sort(function (a, b) {
        const emailA = a.email.toLowerCase();
        const emailB = b.email.toLowerCase();
        if (emailA < emailB) {
            return -1;
        }
        if (emailA > emailB) {
            return 1;
        }

        // names must be equal
        return 0;
    });
}

function FieldError({
    touched,
    errors,
    fieldName,
}: {
    touched: FormikTouched<
        Partial<ClientDTO> & {
            email: string | null;
        }
    >;
    errors: FormikErrors<
        Partial<ClientDTO> & {
            email: string | null;
        }
    >;
    fieldName: string;
}) {
    const classes = useStyles();
    const wasTouched = touched?.[fieldName];
    const error = errors?.[fieldName];

    if (!wasTouched || !error) {
        return null;
    }

    return <Typography className={classes.fieldError}>{error}</Typography>;
}

export function findMatchingFreshbooksClient(acuityClient: ClientDTO | undefined, freshbooksClients: FreshbooksClientDTO[] | null): FreshbooksClientDTO | undefined {
    if (!acuityClient || isEmpty(freshbooksClients)) {
        return undefined;
    }

    const { firstName = '', lastName = '', email: acuityEmail = '', matchMethod, useEmailForBilling, billingEmail = '' } = acuityClient;
    const emailToUse = useEmailForBilling ? acuityEmail : billingEmail;

    switch(matchMethod){
        case MatchMethod.EmailOnly:
            const emailOnlyMatch = freshbooksClients!.find(
                ({ email }) => !isEmpty(email) && email.toLowerCase() === emailToUse.toLowerCase(),
            );
            return emailOnlyMatch;
        case MatchMethod.NameOnly:
            const nameOnlyMatch = freshbooksClients!.find(
                ({ fname, lname }) => 
                    !isEmpty(fname) &&
                    fname.toLowerCase() === firstName.toLowerCase() &&
                    !isEmpty(lname) &&
                    lname.toLowerCase() === lastName.toLowerCase(),
            );
            return nameOnlyMatch
        default:
        case MatchMethod.EmailAndName:
            const fullMatch = freshbooksClients!.find(
                ({ email, fname, lname }) =>
                    !isEmpty(email) &&
                    email.toLowerCase() === emailToUse.toLowerCase() &&
                    !isEmpty(fname) &&
                    fname.toLowerCase() === firstName.toLowerCase() &&
                    !isEmpty(lname) &&
                    lname.toLowerCase() === lastName.toLowerCase(),
            );
            return fullMatch;
    }
}

export default ClientManagement;
