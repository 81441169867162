import { AppointmentDTO, RateObject } from './backend';

export interface InvoiceAppointmentRow {
    id: number;
    datetime: string;
    rawDatetime?: Date;
    postponedDate?: Date;
    displayName: string;
    description: string;
    address: string;
    fee: string;
    travel: string;
    overnight: string;
    additional: string;
    roleType: RoleType;
    approved: boolean;
    calendar: string;
    calculations: CostCalculations;
    preferred: boolean;
    staffId: string;
}

export interface Appointment extends AppointmentDTO {
    staffId: string;
    calculations: CostCalculations;
    roleType?: RoleType;
    preferred: boolean;
    rate: RateObject;
}

export type CostGroup = {
    [rateGroup: string]: number;
};

export type CostCalculations = {
    formFields: {
        hoursOne?: string;
        hoursTwo?: string;
        numImages?: string;
        sqft?: string;
        sqftAux?: string;
        shouldChargeTravelFee?: boolean;
        travelFee?: string;
        overnightFee?: string;
    };
    costs?: {
        flat?: CostGroup;
        area?: CostGroup;
        hourly?: CostGroup;
        totals: CostGroup;
        travel: CostGroup;
        overnight: CostGroup;
    };
    error?: string;
};

export enum RoleType {
    team = 'team',
    lead = 'lead',
    selfLead = 'selfLead',
    unknown = 'unknown,',
}
