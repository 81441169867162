// set client secret in Netlify - GATSBY_FRESHBOOKS_CLIENT_SECRET

export const FRESHBOOKS_PROD = {
    GATSBY_FRESHBOOKS_AUTH_LINK:
        'https://my.freshbooks.com/service/auth/oauth/authorize?client_id=c7d2e81e3edfc6cc6635f22e5231876da95a82d988aff90fd443dfc644491382&response_type=code&redirect_uri=https://approvals.netlify.app/app/freshbooks',
    GATSBY_FRESHBOOKS_CLIENTS_AUTH_LINK:
        'https://my.freshbooks.com/service/auth/oauth/authorize?client_id=c7d2e81e3edfc6cc6635f22e5231876da95a82d988aff90fd443dfc644491382&response_type=code&redirect_uri=https://approvals.netlify.app/app/clients',
    GATSBY_FRESHBOOKS_CLIENTS_REDIRECT_URI:
        'https://approvals.netlify.app/app/clients',
    GATSBY_FRESHBOOKS_CLIENT_ID:
        'c7d2e81e3edfc6cc6635f22e5231876da95a82d988aff90fd443dfc644491382',
    GATSBY_FRESHBOOKS_REDIRECT_URI:
        'https://approvals.netlify.app/app/freshbooks',
};

export const FRESHBOOKS_DEV = {
    GATSBY_FRESHBOOKS_AUTH_LINK:
        'https://my.freshbooks.com/service/auth/oauth/authorize?client_id=c7d2e81e3edfc6cc6635f22e5231876da95a82d988aff90fd443dfc644491382&response_type=code&redirect_uri=https://localhost:8000/app/freshbooks',
    GATSBY_FRESHBOOKS_CLIENTS_AUTH_LINK:
        'https://my.freshbooks.com/service/auth/oauth/authorize?client_id=c7d2e81e3edfc6cc6635f22e5231876da95a82d988aff90fd443dfc644491382&response_type=code&redirect_uri=https://localhost:8000/app/clients',
    GATSBY_FRESHBOOKS_CLIENTS_REDIRECT_URI:
        'https://localhost:8000/app/clients',
    GATSBY_FRESHBOOKS_CLIENT_ID:
        'c7d2e81e3edfc6cc6635f22e5231876da95a82d988aff90fd443dfc644491382',
    GATSBY_FRESHBOOKS_REDIRECT_URI: 'https://localhost:8000/app/freshbooks',
};
