import { makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            // height: theme.spacing(5),
            display: 'flex',
            flex: '1',
            justifyContent: 'space-between',
            borderBottom: '1px solid #d1c1e0',
            backgroundColor: theme.palette.grey[900],
            color: 'white',
        },
        menuDivider: {
            height: '80%',
        },
        menuLink: {
            padding: `0 ${theme.spacing(2)}px`,
            textDecoration: 'none',
            color: 'white',
        },
        menuSelected: {
            fontWeight: theme.typography.fontWeightBold,
        },
        menuText: {},
    });

export const useStyles = makeStyles(styles);
