/* eslint-disable jsx-a11y/label-has-associated-control */
import DateFnsUtils from '@date-io/date-fns';
import { Timestamp } from '@google-cloud/firestore';
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { Check, Delete } from '@material-ui/icons';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import classnames from 'classnames';
import { Field, Formik } from 'formik';
import { orderBy as lodashOrder } from 'lodash-es';
import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    isLoaded,
    useFirestore,
    useFirestoreConnect,
} from 'react-redux-firebase';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import { EXPENSES_COLLECTION, USERS_COLLECTION } from '../../constants';
import {
    expensesList,
    firebaseAuth,
    isAdminUser,
    myExpenseList,
    userById,
    userList,
    userListReady,
} from '../../store/selectors';
import { useToolbarStyles } from '../../styles';
import { ExpenseDTO, UserDTO, UserExpensesDTO } from '../../types';
import { formatUsername } from '../../utils';
import { formatCost, formatDate } from '../Appointments/utils';
import { PageTitle } from '../PageTitle';
import Spinner from '../Spinner';
import { useStyles } from './Expenses.styles';
import {
    EnhancedTableProps,
    EnhancedTableToolbarProps,
    ExpensesTableProps,
    FormattedUserExpense,
    FormFieldProps,
    HeadCell,
    NewExpense,
    Order,
    SortHandler,
} from './Expenses.types';

function NewExpensesForm() {
    const classes = useStyles();
    const firestore = useFirestore();
    const auth = useSelector(firebaseAuth) || '';

    async function onSubmit(
        values: Partial<NewExpense>,
        { resetForm }: { resetForm: any },
    ) {
        const { amount, description, gst, datetime } = values;

        const expensesRef = firestore.collection(EXPENSES_COLLECTION);
        const userRef = firestore
            .collection(USERS_COLLECTION)
            .doc(auth.uid).path;
        const userExpensesRef = expensesRef.doc(auth.uid);

        await userExpensesRef.get().then(async (snapshot) => {
            if (!snapshot.exists) {
                await userExpensesRef.set({
                    expenses: [],
                    userRef,
                    userId: auth.uid,
                } as UserExpensesDTO);
            }
        });

        await userExpensesRef.get().then(async (snapshot) => {
            const newExpense = {
                datetime,
                amount,
                description,
                gst,
                id: uuid(),
            };

            const expenses = (snapshot.data()?.expenses ?? []).concat(
                newExpense,
            );

            userExpensesRef.update({ expenses });
        });

        resetForm();
    }

    const initialValues = {
        amount: 0,
        description: '',
        gst: false,
        datetime: new Date(),
    };

    const FormSchema = Yup.object().shape({
        amount: Yup.number().required('Amount is required'),
    });

    return (
        <Formik
            enableReinitialize
            {...{ initialValues, onSubmit }}
            validationSchema={FormSchema}
        >
            {({
                getFieldProps,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
            }) => (
                <form className="baseForm" onSubmit={handleSubmit} noValidate>
                    <Grid
                        container
                        className={classnames(classes.formRoot, classes.root)}
                    >
                        <PageTitle
                            title="New Expense"
                            tooltip="Your expenses will be added to your monthly invoice."
                        />
                        <Grid
                            container
                            item
                            xs
                            direction="column"
                            wrap="nowrap"
                        >
                            <FormRow label="Date">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="date-picker-inline"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        {...getFieldProps('datetime')}
                                        onChange={(option) =>
                                            setFieldValue('datetime', option)
                                        }
                                    />
                                </MuiPickersUtilsProvider>
                            </FormRow>
                            <FormRow label="Amount ($)">
                                <Field
                                    id="amount"
                                    className={classnames(
                                        classes.amountPadding,
                                        classes.inputField,
                                        'amount',
                                        'formField',
                                    )}
                                    {...getFieldProps('amount')}
                                >
                                    {({ field, form, meta }: any) => (
                                        <div>
                                            <input
                                                type="number"
                                                {...field}
                                                placeholder="Amount"
                                            />
                                            {meta.touched && meta.error && (
                                                <div
                                                    className={
                                                        classes.fieldError
                                                    }
                                                >
                                                    {meta.error}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Field>
                                <Grid
                                    container
                                    className={classes.checkboxWrapper}
                                    alignItems="center"
                                >
                                    <label>
                                        GST Included in Amount
                                        <Field
                                            className={classnames(
                                                classes.checkbox,
                                                'gst',
                                                'formField',
                                            )}
                                            type="checkbox"
                                            id="gst"
                                            name="gst"
                                        />
                                    </label>
                                </Grid>
                            </FormRow>

                            <FormRow label="Description">
                                <input
                                    type="text"
                                    id="description"
                                    className={classnames(
                                        classes.descriptionField,
                                        classes.inputField,
                                        'description',
                                        'formField',
                                    )}
                                    {...getFieldProps('description')}
                                />
                            </FormRow>

                            <Grid>
                                <button type="submit">Submit</button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Formik>
    );
}

function FormRow({ label, children }: FormFieldProps) {
    const classes = useStyles();
    return (
        <Grid item className={classes.formRow}>
            <Grid item>
                <Typography>{label}</Typography>
            </Grid>
            <Grid
                alignItems="center"
                container
                direction="row"
                item
                xs
                className={classes.formField}
            >
                {children}
            </Grid>
        </Grid>
    );
}

function Expenses() {
    const auth = useSelector(firebaseAuth) || '';
    const isAdmin = useSelector(isAdminUser(auth?.uid));
    if (isAdmin) {
        return <AdminExpenses />;
    }

    return <UserExpenses />;
}

function AdminExpenses() {
    useFirestoreConnect(() => [
        { collection: USERS_COLLECTION },
        { collection: EXPENSES_COLLECTION },
    ]);
    const users = useSelector(userList);
    const userExpenses = useSelector(expensesList);
    const expensesLoaded = useSelector(userListReady) && isLoaded(userExpenses);

    const expensesWithUsers = React.useMemo(
        () =>
            Object.entries(userExpenses).reduce(
                (acc: FormattedUserExpense[], [userId, expensesContainer]) => {
                    let user = users[userId];
                    if (!user) {
                        user = {
                            firstName: 'Unknown',
                            lastName: 'User',
                        } as UserDTO;
                    }
                    const expensesForCurrentUser =
                        expensesContainer.expenses.map((invoice) => ({
                            ...invoice,
                            user,
                            userId,
                        }));

                    acc.push(...expensesForCurrentUser);
                    return acc;
                },
                [],
            ),
        [userExpenses, users],
    );

    if (!expensesLoaded) {
        return <Spinner message="Loading expenses..." />;
    }

    return (
        <Grid>
            <NewExpensesForm />
            <EnhancedTable data={expensesWithUsers} />;
        </Grid>
    );
}

function UserExpenses() {
    const auth = useSelector(firebaseAuth) || '';
    const user = useSelector(userById(auth.uid));
    const expensesContainer = useSelector(myExpenseList);
    const expensesLoaded = isLoaded(expensesContainer);
    useFirestoreConnect(() => [
        {
            collection: EXPENSES_COLLECTION,
            doc: auth.uid,
            storeAs: 'myExpenses',
        },
    ]);

    const { userId, expenses = [] } = expensesContainer;
    const userExpenses = expenses.map(
        (invoice) => ({ ...invoice, user, userId } as FormattedUserExpense),
    );

    if (!expensesLoaded) {
        return <Spinner message="Loading expenses..." />;
    }

    return (
        <Grid>
            <NewExpensesForm />
            <EnhancedTable data={userExpenses} />;
        </Grid>
    );
}

const headCells: HeadCell[] = [
    { id: 'datetime', align: 'left', disablePadding: false, label: 'Date' },
    { id: 'amount', align: 'left', disablePadding: false, label: 'Amount' },
    { id: 'user', align: 'left', disablePadding: false, label: 'Staff' },
    {
        id: 'description',
        align: 'left',
        disablePadding: false,
        label: 'Description',
    },
    { id: 'gst', align: 'left', disablePadding: false, label: 'GST' },
    { id: 'id', align: 'left', disablePadding: false, label: 'Delete' },
];

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: keyof FormattedUserExpense) =>
        (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classnames(
                            classes[headCell.styleName || ''],
                            classes.tableCell,
                        )}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = ({}: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar>
            <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Expenses
            </Typography>
        </Toolbar>
    );
};

export function EnhancedTable({ data: userExpenses }: ExpensesTableProps) {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] =
        React.useState<keyof FormattedUserExpense>('datetime');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof FormattedUserExpense,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, userExpenses.length - page * rowsPerPage);

    const sortHandlers: SortHandler = {};

    return (
        <div className={classes.root}>
            <Paper
                className={classes.paper}
                classes={{
                    root: classes.paperRoot,
                }}
            >
                <EnhancedTableToolbar />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={userExpenses.length}
                        />
                        <TableBody>
                            {lodashOrder(
                                userExpenses,
                                [sortHandlers[orderBy] || orderBy],
                                [order],
                            )
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map(
                                    (
                                        row: FormattedUserExpense,
                                        index: number,
                                    ) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {formatDate(
                                                        row.datetime as Timestamp,
                                                        true,
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {formatCost(row.amount)}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {formatUsername(row.user)}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {row.description}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    <GstDisplay
                                                        includeGst={row.gst}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    <DeleteExpenseButton
                                                        expense={row}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    },
                                )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={userExpenses.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

function GstDisplay({ includeGst }: { includeGst?: boolean }) {
    if (!includeGst) {
        return null;
    }

    return <Check />;
}

function DeleteExpenseButton({
    expense: expenseToRemove,
}: {
    expense: FormattedUserExpense;
}) {
    const firestore = useFirestore();
    const { userId } = expenseToRemove;

    function deleteAppointment() {
        const docRef = firestore.doc(`${EXPENSES_COLLECTION}/${userId}`);
        docRef.get().then((docSnapshot) => {
            const userExpenses = docSnapshot.data();
            const { expenses = [] } = userExpenses as any;
            const updatedExpenses = (expenses as ExpenseDTO[]).reduce(
                (acc: ExpenseDTO[], expense) => {
                    const isMatched = expense.id === expenseToRemove.id;
                    if (!isMatched) {
                        acc.push(expense);
                    }
                    return acc;
                },
                [],
            );

            docRef.update({
                ...userExpenses,
                expenses: updatedExpenses,
            });
        });
    }

    return (
        <IconButton onClick={deleteAppointment}>
            <Delete />
        </IconButton>
    );
}

export default Expenses;
