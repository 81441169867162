import { makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            background: `rgba(255, 255, 255, 0.9)`,
            overflowY: `auto`,
            overflowX: `hidden`,
            margin: `${theme.spacing(2)}px 0px`,
            padding: theme.spacing(2),
        },
        roleRow: {
            marginBottom: -theme.spacing(),
            marginLeft: theme.spacing(2),
            minWidth: 120,
            '&:last-child': {
                marginBottom: theme.spacing(),
            },
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        calendarRow: {
            border: '1px solid grey',
            borderBottom: 'none',
            padding: theme.spacing(),
            '&:last-child': {
                borderBottom: '1px solid grey',
            },
        },
        calendarName: {
            fontWeight: 700,
            paddingRight: theme.spacing(),
        },
        calendarLocation: {
            fontSize: 13,
            fontWeight: 400,
            fontStyle: 'italic',
        },
        addRoleButton: {
            fontSize: 12,
            padding: theme.spacing(),
            marginLeft: theme.spacing(),
            backgroundColor: 'green',
            color: 'white',
        },
        removeRoleButton: {
            fontSize: theme.spacing(3),
            color: 'red',
        },
        userName: {
            fontWeight: 500,
        },
        roleName: {
            paddingLeft: theme.spacing(1),
            fontStyle: 'italic',
        },
    });

export const useStyles = makeStyles(styles);
