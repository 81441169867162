import ClientOAuth2 from 'client-oauth2';
import { Dictionary } from 'react-redux-firebase';

import { Appointment, ClientDTO, FreshbooksClientDTO } from '../../../types';
import { formatDiscounts } from '../../Appointments/utils';
import { findMatchingFreshbooksClient } from '../../ClientManagement';
import {
    getInvoicesForCurrentPeriod,
    setPaymentOptionsForInvoice,
} from '../freshbooksAdapter';

export function getAppointmentCost(
    appointment: Appointment,
    includeAdditionalFees: boolean,
) {
    const costs = appointment?.calculations?.costs;
    const appointmentSubTotal = costs?.totals?.retail ?? 0;
    const { amount: discountAmount } = formatDiscounts(appointment);

    const appointmentTravel = costs?.travel?.retail ?? 0;
    const appointmentOvernight = costs?.overnight?.retail ?? 0;

    let finalCost = appointmentSubTotal - discountAmount;
    if (includeAdditionalFees) {
        finalCost = finalCost + appointmentTravel + appointmentOvernight;
    }
    return finalCost;
}

export async function ensureInvoicesHavePaymentOptions(
    accountId: string,
    customerId: number,
    auth: ClientOAuth2,
    gatewayName: string,
) {
    const invoiceResponse = await getInvoicesForCurrentPeriod(
        accountId,
        customerId,
        auth,
    );

    const invoiceJson = await invoiceResponse.json();
    console.debug('invoice JSON', invoiceJson);
    const invoices = invoiceJson?.response?.result?.invoices;
    invoices.forEach(async (returnedInvoice: any) => {
        const invoiceId = returnedInvoice.id;
        if (gatewayName) {
            await setPaymentOptionsForInvoice(
                accountId,
                invoiceId,
                auth,
                gatewayName,
            );
        } else {
            console.warn('No gateway available in upload error');
        }
    });
    console.debug(`Set payment methods for ${invoices.length} invoices`);
}

export function getAcuityClientFromAppointment(
    acuityClients: Dictionary<ClientDTO>,
    appointment: Appointment,
) {
    const { firstName, lastName } = appointment;
    const acuityClient = Object.values(acuityClients).find(
        (client) =>
            client.firstName === firstName && client.lastName === lastName,
    );
    return acuityClient;
}

export function appointmentMatch(
    freshbooksClients: FreshbooksClientDTO[] | null,
    acuityClients: Dictionary<ClientDTO>,
    appointment: Appointment,
): FreshbooksClientDTO | undefined {
    if (!freshbooksClients) {
        return undefined;
    }

    const acuityClient = getAcuityClientFromAppointment(
        acuityClients,
        appointment,
    );
    const freshbooksClient = findMatchingFreshbooksClient(
        acuityClient,
        freshbooksClients,
    );
    return freshbooksClient;
}

export const onDebugInfo = (
    appointments: Appointment[],
    fbMe: any,
    acuityClients: Dictionary<ClientDTO>,
    freshbooksClients: FreshbooksClientDTO[],
) => {
    console.debug('Freshbooks Profile', fbMe);
    console.debug('Appointments', appointments);
    console.debug('Acuity Clients', acuityClients);
    console.debug('Freshbooks Clients', freshbooksClients);
};
