import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, useFirestore, isLoaded, Dictionary } from 'react-redux-firebase';

import {
    Grid,
    Typography,
    TextField,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    FormHelperText,
    DialogActions,
    Checkbox,
} from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import { ROLES_COLLECTION } from '../../constants';
import { roleList } from '../../store/selectors';
import { Notification } from '../Notification';
import Spinner from '../Spinner';
import { useStyles } from './RoleManagement.styles';
import { Role, RoleRowProps } from './RoleManagement.types';
import { PageTitle } from '../PageTitle';

function RoleManagement() {
    const classes = useStyles();
    const firestore = useFirestore();
    useFirestoreConnect(() => [{ collection: ROLES_COLLECTION }]);
    const roles = (useSelector(roleList) || []) as Dictionary<Role>;
    const rolesLoaded = isLoaded(roles);
    const rolesCollectionRef = firestore.collection(ROLES_COLLECTION);

    const [addRoleDialogOpen, setAddRoleDialogOpen] = React.useState(false);
    const [newRoleName, setNewRoleName] = React.useState('');
    const [notificationOpen, setNotificationOpen] = React.useState(false);
    const [notification, setNotification] = React.useState('');

    const handleClose = () => {
        setNotificationOpen(false);
    };

    const showNotification = React.useCallback(
        (message: string) => {
            setNotification(message);
            setNotificationOpen(true);
        },
        [setNotification, setNotificationOpen],
    );

    const openDialog = React.useCallback(() => {
        setNewRoleName('');
        setAddRoleDialogOpen(true);
    }, [setNewRoleName, setAddRoleDialogOpen]);

    const closeDialog = React.useCallback(() => {
        setNewRoleName('');
        setAddRoleDialogOpen(false);
    }, [setNewRoleName, setAddRoleDialogOpen]);

    const onRoleChange = React.useCallback(
        (event: any) => {
            setNewRoleName(event.target.value);
        },
        [setNewRoleName],
    );

    const addRole = () => {
        if (Object.values(roles).some((role) => role?.name === newRoleName)) {
            showNotification(`${newRoleName} already exists`);
        } else {
            // add new role
            const docRef = rolesCollectionRef.doc();
            docRef
                .set({
                    name: newRoleName,
                })
                .then(() => {
                    showNotification(`Added ${newRoleName}`);
                })
                .catch((err) => {
                    showNotification(`Failed to add ${newRoleName}`);
                })
                .finally(() => {
                    closeDialog();
                });
        }
    };

    if (!rolesLoaded) {
        return <Spinner />;
    }

    return (
        <Grid container direction="column" className={classes.root}>
            <PageTitle
                title="Staff Roles"
                tooltip="Staff roles link service fees with staff members. For example, Bob can be assigned as a Photographer to one of his calendars. When an appointment is made in that calendar, it will be matched with a Conversion. If a Rate was assigned to that Conversion under the Photographer role, then Bob will be paid that rate."
            />
            <Typography>Set the roles available to staff.</Typography>
            <Typography>IMPORTANT: Only delete a role if it is no longer used.</Typography>
            <Grid item xs className={classes.addRoleButtonContainer}>
                <Button variant="contained" onClick={openDialog} className={classes.addRoleButton}>
                    Add Role
                </Button>
            </Grid>
            <Grid container direction="column" wrap="nowrap">
                {Object.entries(roles).map(([roleId, role]) => {
                    if (!role) {
                        return null;
                    }
                    return <RoleRow key={roleId} role={role} roleId={roleId} />;
                })}
            </Grid>
            <Notification open={notificationOpen} onClose={handleClose} message={notification} />
            <Dialog open={addRoleDialogOpen} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Staff Role</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Roles can be used to assign staff to calendars, and determine payout rates for appointment types.
                    </DialogContentText>
                    <Grid direction="column" container>
                        <TextField onChange={onRoleChange} />
                        <FormHelperText>Role Name</FormHelperText>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={addRole} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

const RoleRow = ({ roleId, role }: RoleRowProps) => {
    const { name: roleName, optional = false } = role;
    const classes = useStyles();
    const firestore = useFirestore();
    const rolesCollectionRef = firestore.collection(ROLES_COLLECTION);

    const removeRole = () => {
        const docRef = rolesCollectionRef.doc(roleId);
        docRef.delete();
    };

    const toggleOptional = () => {
        const docRef = rolesCollectionRef.doc(roleId);
        docRef.set({
            optional: !optional
        }, {merge: true})
    }

    return (
        <Grid direction="column" container item key={roleId} className={classes.roleRow}> 
            <Grid direction="row" container item alignItems="center">
                <Grid item className={classes.roleName}>
                    <Typography>{roleName}</Typography>
                </Grid>
                <IconButton onClick={removeRole} className={classes.removeRoleButton}>
                    <RemoveCircle fontSize="inherit" />
                </IconButton>
            </Grid>
            <Grid direction="row" container item alignItems="center" className={classes.roleOptionalRow}>
                <Typography variant="body2">Optional</Typography>
                <Checkbox checked={optional} onChange={toggleOptional}/>
            </Grid>
        </Grid>

    );
};

export default RoleManagement;
