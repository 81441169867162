import { makeStyles, Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {},
        innerCenter: {
            background: `rgba(255, 255, 255, 0.9)`,
            width: `50%`,
            marginTop: `30vh`,
            textAlign: 'center',
        },
        message: {
            paddingTop: theme.spacing(2),
        },
    });

export const useStyles = makeStyles(styles);
