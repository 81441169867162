import { makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: `${theme.spacing(2)}px 0px`,
            background: `rgba(255, 255, 255, 0.9)`,
            padding: theme.spacing(2),
        },
    });

export const useStyles = makeStyles(styles);
