import {
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import classnames from 'classnames';
import { orderBy as lodashOrder } from 'lodash-es';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import { INVOICES_COLLECTION, USERS_COLLECTION } from '../../constants';
import { invoicesList, userList, userListReady } from '../../store/selectors';
import { useToolbarStyles } from '../../styles';
import { UserDTO } from '../../types';
import { formatUsername } from '../../utils';
import { formatDate } from '../Appointments/utils';
import Spinner from '../Spinner';
import { useStyles } from './Invoices.styles';
import {
    EnhancedTableProps,
    FormattedUserInvoice,
    HeadCell,
    InvoicesTableProps,
    Order,
    SortHandler,
} from './Invoices.types';

function Invoices() {
    const userInvoices = useSelector(invoicesList);
    const users = useSelector(userList);
    const invoicesLoaded = useSelector(userListReady) && isLoaded(userInvoices);
    useFirestoreConnect(() => [
        { collection: USERS_COLLECTION },
        { collection: INVOICES_COLLECTION },
    ]);

    const invoicesWithUsers = React.useMemo(
        () =>
            Object.entries(userInvoices).reduce(
                (acc: FormattedUserInvoice[], [userId, invoicesContainer]) => {
                    let user = users[userId];
                    if (!user) {
                        user = {
                            firstName: 'Unknown',
                            lastName: 'User',
                        } as UserDTO;
                    }
                    const invoicesForCurrentUser =
                        invoicesContainer.invoices.map((invoice) => ({
                            ...invoice,
                            user,
                        }));

                    acc.push(...invoicesForCurrentUser);
                    return acc;
                },
                [],
            ),
        [userInvoices, users],
    );

    if (!invoicesLoaded) {
        return <Spinner message="Loading invoices..." />;
    }

    return <EnhancedTable data={invoicesWithUsers} />;
}

const headCells: HeadCell[] = [
    { id: 'datetime', align: 'left', disablePadding: false, label: 'Date' },
    { id: 'user', align: 'left', disablePadding: false, label: 'Staff' },
    {
        id: 'invoiceUrl',
        align: 'left',
        disablePadding: false,
        label: 'Download',
    },
];

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: keyof FormattedUserInvoice) =>
        (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classnames(
                            classes[headCell.styleName || ''],
                            classes.tableCell,
                        )}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = () => {
    const classes = useToolbarStyles();

    return (
        <Toolbar>
            <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Invoices
            </Typography>
        </Toolbar>
    );
};

export function EnhancedTable({ data: userInvoices }: InvoicesTableProps) {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] =
        React.useState<keyof FormattedUserInvoice>('datetime');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof FormattedUserInvoice,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, userInvoices.length - page * rowsPerPage);

    const sortHandlers: SortHandler = {};

    return (
        <div className={classes.root}>
            <Paper
                className={classes.paper}
                classes={{
                    root: classes.paperRoot,
                }}
            >
                <EnhancedTableToolbar />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={userInvoices.length}
                        />
                        <TableBody>
                            {lodashOrder(
                                userInvoices,
                                [sortHandlers[orderBy] || orderBy],
                                [order],
                            )
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )
                                .map((row: FormattedUserInvoice) => {
                                    const userName = formatUsername(row.user);
                                    const datetimeString =
                                        row.datetime.valueOf();

                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={`${userName}-${datetimeString}`}
                                        >
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                {formatDate(row.datetime, true)}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                {formatUsername(row.user)}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                <IconButton aria-label="filter list">
                                                    <Link
                                                        download
                                                        href={row.invoiceUrl}
                                                        target="_blank"
                                                    >
                                                        <CloudDownload />
                                                    </Link>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={userInvoices.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

export default Invoices;
