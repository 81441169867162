import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

import { formatCost } from '../Appointments/utils';
import { useStyles } from './Freshbooks.styles';

export function AppointmentStatusDisplay({
    total,
    error,
}: {
    total?: number;
    error?: string;
}) {
    const classes = useStyles();
    if (Number.isFinite(total)) {
        return (
            <Typography
                component="span"
                className={classNames(classes.okayStatus, {
                    [classes.warningStatus]: total === 0,
                })}
            >
                {formatCost(total!)}
            </Typography>
        );
    }

    const errorToDisplay = error || 'Unknown Error';
    return (
        <Typography component="span" className={classes.errorStatus}>
            {errorToDisplay}
        </Typography>
    );
}
