import React from 'react';
import { Link, useLocation } from '@reach/router';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import { Signout } from '../../containers';
import { isAdminUser, firebaseAuth, isUserLoggedIn } from '../../store/selectors';
import { useStyles } from './NavBar.styles';

function NavBar() {
    const classes = useStyles();
    const auth = useSelector(firebaseAuth);
    const isAdmin = useSelector(isAdminUser(auth.uid));

    return (
        <Grid container alignItems="center" className={classes.root}>
            <Grid item>
                <nav>
                    <MenuLink to="/app/home" text="Main" />
                    <MenuLink to="/app/profile" text="Profile" />
                    <MenuLink to="/app/appointments" text="Appointments" />
                    <MenuLink to="/app/expenses" text="Expenses" />
                    {isAdmin && <Typography component="span">|</Typography>}
                    <AdminLink to="/app/review" text="Review" />
                    <AdminLink to="/app/clients" text="Clients" />
                    <AdminLink to="/app/conversions" text="Conversions" />
                    <AdminLink to="/app/rates" text="Rates" />
                    {isAdmin && <Typography component="span">|</Typography>}
                    <AdminLink to="/app/calendars" text="Calendars" />
                    <AdminLink to="/app/roles" text="Roles" />
                    <AdminLink to="/app/leads" text="Leads" />
                    <AdminLink to="/app/invoices" text="Invoices" />
                </nav>
            </Grid>
            <Grid container item xs alignContent="flex-end" direction="row-reverse">
                <AuthComponent />
            </Grid>
        </Grid>
    );
}

function AuthComponent() {
    const auth = useSelector(firebaseAuth);
    const isLoggedIn = useSelector(isUserLoggedIn);

    if (!isLoggedIn) {
        return null;
    }
    const message = `Logged in as ${auth.displayName}`;

    return (
        <div>
            <Typography component="span">{message}</Typography>
            <Signout />
        </div>
    );
}

function MenuLink({ text, to }: { text: string; to: string }) {
    const { pathname } = useLocation();
    const classes = useStyles();
    const isLoggedIn = useSelector(isUserLoggedIn);

    const linkSelected = pathname === to;

    if (!isLoggedIn) {
        return null;
    }

    return (
        <Link className={classes.menuLink} to={to}>
            <Typography component="span" className={classnames(classes.menuText, { [classes.menuSelected]: linkSelected })}>
                {text}
            </Typography>
        </Link>
    );
}

function AdminLink({ text, to }: { text: string; to: string }) {
    const auth = useSelector(firebaseAuth);
    const isAdmin = useSelector(isAdminUser(auth.uid));
    return isAdmin ? <MenuLink text={text} to={to} /> : null;
}

export default NavBar;
