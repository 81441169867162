import { Timestamp } from '@google-cloud/firestore';
import { isEmpty, trimEnd, trimStart } from 'lodash-es';

import {
    Appointment,
    AppointmentDTO,
    InvoiceAppointmentRow,
    RoleType,
} from '../../types';
import { TAX_RATE } from './generateInvoice';

export const formatCost = (amount: number) => {
    return `$${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export function formatDiscounts(appointment: Appointment): {
    amount: number;
    reason: string | null;
} {
    const {
        calculations,
        preferred = false,
        formFields,
        rate,
        certificate,
        price,
        priceSold,
        id,
    } = appointment;

    let priceParsed = 0;
    let priceSoldParsed = 0;
    try {
        priceParsed = Number.parseFloat(price);
        priceSoldParsed = Number.parseFloat(priceSold);
    } catch (error) {
        console.debug(
            `Failed to parse price and priceSold for appointment ID ${id}`,
            price,
            priceSold,
        );
    }

    const total = calculations?.costs?.totals?.retail ?? 0;
    const { discountAmount, discountPercent, discountReason } = formFields;

    const prefDiscountPercent = preferred ? rate?.meta?.prefDiscount : 0;
    const prefDiscountDollar = preferred ? rate?.meta?.prefDiscountDollar : 0;
    const prefDiscountAmount = preferred
        ? total * prefDiscountPercent + prefDiscountDollar
        : 0;

    const certificateDiscountAmount = certificate
        ? (priceSoldParsed - priceParsed) / (1 + TAX_RATE)
        : 0;

    const discountReasonString = discountReason ? ` - ${discountReason}` : '';

    const isAreaRate = rate?.meta?.type?.area;
    const minimumCharge = isAreaRate
        ? rate?.rates?.area?.retail?.minimumCharge?.amount ?? 0
        : 0;

    let amount: number = 0;
    let reason: string | null = '';

    if (discountAmount) {
        const discountWithoutDollarSign = Number.parseFloat(
            trimStart(discountAmount, '$') ?? 0,
        );
        if (discountWithoutDollarSign > prefDiscountAmount) {
            amount = discountWithoutDollarSign;
            reason = `Discount: ${formatCost(
                discountWithoutDollarSign,
            )} off${discountReasonString}`;
        }
    }

    if (discountPercent) {
        const discountWithoutPercent = trimEnd(discountPercent, '%');
        const discountNum = Number.parseFloat(discountWithoutPercent);

        const dollarAmount = (total * discountNum) / 100;
        if (dollarAmount > prefDiscountAmount && dollarAmount > amount) {
            amount = dollarAmount;
            reason = `Discount: ${discountWithoutPercent}% off${discountReasonString}`;
        }
    }

    if (prefDiscountAmount > amount) {
        amount = prefDiscountAmount;
        const reasonParts = [];

        if (prefDiscountPercent > 0) {
            reasonParts.push(`${(prefDiscountPercent * 100).toFixed(0)}%`);
        }
        if (prefDiscountDollar > 0) {
            reasonParts.push(`${formatCost(prefDiscountDollar)}`);
        }
        const reasonString = reasonParts.join(' + ');
        reason = `Discount: ${reasonString} off - PREF`;
    }

    if (certificateDiscountAmount > amount) {
        amount = certificateDiscountAmount;
        reason = `Discount: ${formatCost(
            certificateDiscountAmount,
        )} off - ${certificate} (coupon)`;
    }

    const discountedTotalLessThanMinimum = total - amount < minimumCharge;
    if (discountedTotalLessThanMinimum) {
        const diffTotalAndMinimum = total - minimumCharge;
        return { amount: diffTotalAndMinimum, reason: null };
    }

    if (certificate && total - amount !== priceParsed / (1 + TAX_RATE)) {
        console.error(
            'Coupon/certificate mismatch error',
            `appointment ID: ${id}`,
            `coupon/certificate: ${certificate}`,
            `total: ${total}`,
            `discount amount: ${amount}`,
            `total - discount amount: ${total - amount}`,
            `priceSold: ${priceSold}`,
            `price: ${price}`,
            `tax rate: ${TAX_RATE}`,
        );
    }
    return { amount, reason };
}

const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export function timestampToDate(timestamp: Timestamp) {
    try {
        const date = new Date(
            timestamp.toMillis?.() || getMilliseconds(timestamp),
        );
        return date;
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

export function formatDate(
    timestamp: Timestamp,
    split = false,
    includeTime = true,
) {
    try {
        const date = timestampToDate(timestamp);
        if (!date) {
            throw Error('Could not get date');
        }

        const month = date.getMonth();
        const monthName = MONTHS[month];
        const day = date.getDate();
        const year = date.getFullYear();
        const hour = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');

        if (!includeTime) {
            return `${monthName} ${day}, ${year}`;
        }

        if (split) {
            return `${monthName} ${day}, ${year}\n${hour}:${minutes}`;
        }

        return `${monthName} ${day}, ${year} @ ${hour}:${minutes}`;
    } catch (error) {
        console.error(error);
        return 'Could not get date';
    }
}

function getMilliseconds(timestamp: Timestamp) {
    const { seconds } = timestamp;
    return seconds * 1000;
}

export function getCalculatedCosts(appointment: Appointment): {
    total: number;
    travel: number;
    overnight: number;
} {
    const {
        calculations: { costs, error },
        roleType,
    } = appointment;

    if (isEmpty(costs)) {
        return { total: 0, travel: 0, overnight: 0 };
    }

    const {
        totals: { lead, leadBonus, team },
        travel: {
            lead: leadTravel,
            leadBonus: leadBonusTravel,
            team: teamTravel,
        },
        overnight: {
            lead: leadOvernight,
            leadBonus: leadBonusOvernight,
            team: teamOvernight,
        },
    } = costs!;

    if (error) {
        return { total: 0, travel: 0, overnight: 0 };
    }

    if (roleType === RoleType.lead) {
        return {
            total: leadBonus,
            travel: leadBonusTravel,
            overnight: leadBonusOvernight,
        };
    }

    if (roleType === RoleType.team) {
        return { total: team, travel: teamTravel, overnight: teamOvernight };
    }

    if (roleType === RoleType.selfLead) {
        return { total: lead, travel: leadTravel, overnight: leadOvernight };
    }

    console.warn(
        `No matching role type for [${roleType}] while calculating costs`,
    );
    return { total: 0, travel: 0, overnight: 0 };
}

export function isPostponed(
    { postponedDate }: InvoiceAppointmentRow,
    endDate: Date,
) {
    return isAppointmentInFuture(postponedDate, endDate);
}

export function isAppointmentPostponed(
    { postponedDate }: AppointmentDTO,
    endDate: Date,
) {
    const wasPostponed = postponedDate !== undefined && postponedDate !== null;
    return (
        wasPostponed &&
        isAppointmentInFuture(timestampToDate(postponedDate), endDate)
    );
}

function isAppointmentInFuture(postponedDate?: Date, datetime = new Date()) {
    const wasPostponed = postponedDate !== undefined;
    const isInFuture = datetime?.valueOf() < (postponedDate?.valueOf() || 0);
    if (wasPostponed && isInFuture) {
        return true;
    }

    return false;
}
