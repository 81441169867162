import { makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            background: `rgba(255, 255, 255, 0.9)`,
            margin: `${theme.spacing(2)}px 0px`,
        },
        roleRow: {
            marginBottom: -theme.spacing(),
            marginLeft: theme.spacing(2),
            minWidth: 120,
            '&:last-child': {
                marginBottom: theme.spacing(),
            },
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        assignleadButton: {
            fontSize: 12,
            padding: theme.spacing(),
            marginLeft: theme.spacing(),
            backgroundColor: 'green',
            color: 'white',
        },
        errorMessage: {
            paddingLeft: theme.spacing(),
        },
        removeLeadButton: {
            fontSize: theme.spacing(3),
            color: 'red',
        },
        userName: {
            fontWeight: 500,
        },
        leadName: {
            paddingLeft: theme.spacing(1),
            fontStyle: 'italic',
        },
    });

export const useStyles = makeStyles(styles);
