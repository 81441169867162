import { makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
        },
        title: {
            paddingLeft: theme.spacing(2),
        },
        conversionsListPanel: {
            height: '100%',
            background: `rgba(255, 255, 255, 0.9)`,
            padding: theme.spacing(0.5),
            paddingTop: theme.spacing(2),
            margin: `${theme.spacing(2)}px 0px`,
        },
        newConversionButtonContainer: {
            padding: theme.spacing(2),
            width: '100%',
        },
        conversionsListTitle: {
            fontWeight: theme.typography.fontWeightBold,
        },
        errorMessage: {
            color: 'red',
            fontSize: 14,
        },
        isConversionMissingRates: {
            backgroundColor: theme.palette.error.light,
            '&:hover': {
                backgroundColor: theme.palette.error.dark,
            },
        },
        newConversionButton: {
            color: 'white',
            backgroundColor: 'green',
            maxHeight: theme.spacing(4),
        },
        addDialogMessage: {
            color: 'red',
        },
        conversionsListContainer: {
            height: `calc(100% - ${theme.spacing(25)}px)`,
            width: `100%`,
            padding: theme.spacing(),
            overflowX: 'hidden',
            overflowY: 'auto',
            textOverflow: 'ellipsis',
        },
        conversionDetailsPanelContainer: {
            height: '100%',
            margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
            textOverflow: 'ellipsis',
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            background: `rgba(255, 255, 255, 0.9) content-box`,
        },
        conversionDetailsContents: {
            padding: theme.spacing(2),
        },
        actionBar: {
            height: theme.spacing(6),
            width: '100%',
        },
        editNameDialog: {
            minWidth: 600,
        },
        rateRowsContainer: {
            padding: theme.spacing(),
        },
        roleRow: {
            border: '1px solid grey',
            borderBottom: 'none',
            padding: theme.spacing(),
            '&:last-child': {
                borderBottom: '1px solid grey',
            },
        },
        removeRoleButton: {
            fontSize: theme.spacing(3),
            color: 'red',
        },
        addRateButton: {
            color: 'white',
            backgroundColor: 'green',
            margin: theme.spacing(),
        },
        noSelectionMessage: {
            background: 'rgba(255, 255, 255, 0.9) padding-box',
        },
    });

export const useStyles = makeStyles(styles);
