import React from 'react';
import { useSelector } from 'react-redux';
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik';
import * as Yup from 'yup';
import { Grid, Typography, InputLabel, Button } from '@material-ui/core';
import { isLoaded, useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { LEADS_COLLECTION, USERS_COLLECTION } from '../../constants';
import { formatUsername } from '../../utils';
import { firebaseAuth, firebaseProfile, firebaseProfileReady, leadsList, userList, userListReady } from '../../store/selectors';
import { UserDTO } from '../../types';
import Spinner from '../Spinner';
import { useStyles } from './Profile.styles';
import { PageTitle } from '../PageTitle';

function Profile() {
    const classes = useStyles();
    const firestore = useFirestore();

    const auth = useSelector(firebaseAuth) || '';
    const profile = useSelector(firebaseProfile);
    const profileReady = useSelector(firebaseProfileReady);
    const users = useSelector(userList) || {};
    const leads = useSelector(leadsList) || {};
    const usersLoaded = useSelector(userListReady);
    const loaded = profileReady && isLoaded(auth, leads) && usersLoaded;

    useFirestoreConnect(() => [{ collection: USERS_COLLECTION }, { collection: LEADS_COLLECTION }]);

    const handleFormSubmit = (values: Partial<UserDTO> & { email: string | null; lead: string | null }) => {
        if (auth.uid) {
            const { admin, lead, email, ...safeUpdateFields } = values;
            const docRef = firestore.doc(`${USERS_COLLECTION}/${auth.uid}`);
            docRef.update(safeUpdateFields);
        }
    };

    if (!loaded) {
        return <Spinner message="Loading your profile..." />;
    }

    const {
        firstName = '',
        lastName = '',
        admin = false,
        gstNumber = '',
        streetAddress = '',
        streetAddressLineTwo = '',
        city = '',
        province = '',
        postalCode = '',
        country = '',
    } = profile;

    const leadId = leads[auth.uid]?.lead || '';
    const lead = users?.[leadId];
    const leadName = lead ? formatUsername(lead) : '';

    const FormSchema = Yup.object().shape({
        firstName: Yup.string().max(50, 'Too Long!').required('Required'),
        lastName: Yup.string().max(50, 'Too Long!').required('Required'),
        email: Yup.string().email().required('Required'),
        gstNumber: Yup.string().required('Required'),
        lead: Yup.string(),
        streetAddress: Yup.string(),
        streetAddressLineTwo: Yup.string(),
        city: Yup.string(),
        province: Yup.string(),
        postalCode: Yup.string(),
        country: Yup.string(),
    });

    return (
        <Formik
            enableReinitialize
            initialValues={{
                firstName,
                lastName,
                email: auth.email,
                gstNumber,
                lead: leadName,
                streetAddress,
                streetAddressLineTwo,
                city,
                province,
                postalCode,
                country,
            }}
            validationSchema={FormSchema}
            onSubmit={handleFormSubmit}
        >
            {({ errors, touched }) => (
                <Form className={classes.root}>
                    <PageTitle title="My Profile" tooltip="Your profile information will be used to generate your invoice." />
                    <Grid container item direction="row" alignItems="stretch">
                        <Grid className={classes.formColumn} container item direction="column" xs>
                            <Typography className={classes.sectionHeader} variant="h5">
                                Personal
                            </Typography>
                            <Grid className={classes.textFieldContainer} container item direction="row" wrap="nowrap" alignItems="center">
                                <InputLabel className={classes.inputLabel} htmlFor="firstName">
                                    {decorateLabel('First Name')}
                                </InputLabel>
                                <Field className={classes.textField} id="firstName" type="text" name="firstName" />
                            </Grid>
                            <FieldError touched={touched} errors={errors} fieldName="firstName" />

                            <Grid className={classes.textFieldContainer} container item direction="row" wrap="nowrap" alignItems="center">
                                <InputLabel className={classes.inputLabel} htmlFor="lastName">
                                    {decorateLabel('Last Name')}
                                </InputLabel>
                                <Field
                                    className={classes.textField}
                                    id="lastName"
                                    aria-describedby="lastName"
                                    type="text"
                                    name="lastName"
                                />
                            </Grid>
                            <FieldError touched={touched} errors={errors} fieldName="lastName" />

                            <Grid className={classes.textFieldContainer} container item direction="row" wrap="nowrap" alignItems="center">
                                <InputLabel className={classes.inputLabel} htmlFor="email">
                                    {decorateLabel('Email address')}
                                </InputLabel>
                                <Field
                                    className={classes.textField}
                                    id="email"
                                    aria-describedby="email"
                                    disabled
                                    type="text"
                                    name="email"
                                />
                            </Grid>
                            <FieldError touched={touched} errors={errors} fieldName="email" />

                            <Typography className={classes.sectionHeader} variant="h5">
                                GST
                            </Typography>
                            <Grid className={classes.textFieldContainer} container item direction="row" wrap="nowrap" alignItems="center">
                                <InputLabel className={classes.inputLabel} htmlFor="gstNumber">
                                    {decorateLabel('GST #')}
                                </InputLabel>
                                <Field
                                    className={classes.textField}
                                    id="gstNumber"
                                    aria-describedby="gstNumber"
                                    type="text"
                                    name="gstNumber"
                                />
                            </Grid>
                            <FieldError touched={touched} errors={errors} fieldName="gstNumber" />

                            <Typography className={classes.sectionHeader} variant="h5">
                                Lead
                            </Typography>
                            <Grid className={classes.textFieldContainer} container item direction="row" wrap="nowrap" alignItems="center">
                                <InputLabel className={classes.inputLabel} htmlFor="lead">
                                    {decorateLabel('Lead')}
                                </InputLabel>
                                <Field
                                    disabled
                                    placeholder="N/A"
                                    className={classes.textField}
                                    id="lead"
                                    aria-describedby="lead"
                                    type="text"
                                    name="lead"
                                />
                            </Grid>
                            <FieldError touched={touched} errors={errors} fieldName="lead" />
                        </Grid>
                        <Grid className={classes.formColumn} container item direction="column" xs>
                            <Typography className={classes.sectionHeader} variant="h5">
                                Address
                            </Typography>
                            <Grid className={classes.textFieldContainer} container item direction="row" wrap="nowrap" alignItems="center">
                                <InputLabel className={classes.inputLabel} htmlFor="streetAddress">
                                    {decorateLabel('Street Address')}
                                </InputLabel>
                                <Field
                                    className={classes.textField}
                                    id="streetAddress"
                                    aria-describedby="streetAddress"
                                    type="text"
                                    name="streetAddress"
                                />
                            </Grid>
                            <FieldError touched={touched} errors={errors} fieldName="streetAddress" />

                            <Grid className={classes.textFieldContainer} container item direction="row" wrap="nowrap" alignItems="center">
                                <InputLabel className={classes.inputLabel} htmlFor="streetAddressLineTwo">
                                    {decorateLabel('Line 2')}
                                </InputLabel>
                                <Field
                                    className={classes.textField}
                                    id="streetAddressLineTwo"
                                    aria-describedby="streetAddressLineTwo"
                                    type="text"
                                    name="streetAddressLineTwo"
                                />
                            </Grid>
                            <FieldError touched={touched} errors={errors} fieldName="streetAddressLineTwo" />

                            <Grid className={classes.textFieldContainer} container item direction="row" wrap="nowrap" alignItems="center">
                                <InputLabel className={classes.inputLabel} htmlFor="city">
                                    {decorateLabel('City')}
                                </InputLabel>
                                <Field className={classes.textField} id="city" aria-describedby="city" type="text" name="city" />
                            </Grid>

                            <Grid className={classes.textFieldContainer} container item direction="row" wrap="nowrap" alignItems="center">
                                <InputLabel className={classes.inputLabel} htmlFor="province">
                                    {decorateLabel('Province')}
                                </InputLabel>
                                <Field
                                    className={classes.textField}
                                    id="province"
                                    aria-describedby="province"
                                    type="text"
                                    name="province"
                                />
                            </Grid>
                            <FieldError touched={touched} errors={errors} fieldName="province" />

                            <Grid className={classes.textFieldContainer} container item direction="row" wrap="nowrap" alignItems="center">
                                <InputLabel className={classes.inputLabel} htmlFor="postalCode">
                                    {decorateLabel('Postal Code')}
                                </InputLabel>
                                <Field
                                    className={classes.textField}
                                    id="postalCode"
                                    aria-describedby="postalCode"
                                    type="text"
                                    name="postalCode"
                                />
                            </Grid>
                            <FieldError touched={touched} errors={errors} fieldName="postalCode" />

                            <Grid className={classes.textFieldContainer} container item direction="row" wrap="nowrap" alignItems="center">
                                <InputLabel className={classes.inputLabel} htmlFor="country">
                                    {decorateLabel('Country')}
                                </InputLabel>
                                <Field className={classes.textField} id="country" aria-describedby="country" type="text" name="country" />
                            </Grid>
                            <FieldError touched={touched} errors={errors} fieldName="country" />
                        </Grid>
                    </Grid>
                    <Grid
                        className={classes.updateButtonContainer}
                        direction="row"
                        alignContent="center"
                        justify="center"
                        container
                        item
                        xs
                    >
                        <Button className={classes.formButton} type="submit" variant="contained">
                            Update
                        </Button>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

const decorateLabel = (label: string) => {
    return `${label}:`;
};

function FieldError({
    touched,
    errors,
    fieldName,
}: {
    touched: FormikTouched<
        Partial<UserDTO> & {
            email: string | null;
        }
    >;
    errors: FormikErrors<
        Partial<UserDTO> & {
            email: string | null;
        }
    >;
    fieldName: string;
}) {
    const classes = useStyles();
    const wasTouched = touched?.[fieldName];
    const error = errors?.[fieldName];

    if (!wasTouched || !error) {
        return null;
    }

    return <Typography className={classes.fieldError}>{error}</Typography>;
}

export default Profile;
