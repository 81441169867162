import { makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            background: `rgba(255, 255, 255, 0.9)`,
            margin: `${theme.spacing(2)}px 0px`,
        },
        addRoleButtonContainer: {
            width: '100%',
            padding: `${theme.spacing(2)}px 0`,
        },
        addRoleButton: {
            fontSize: 12,
            padding: theme.spacing(),
            backgroundColor: 'green',
            color: 'white',
            width: '100%',
        },
        removeRoleButton: {
            fontSize: theme.spacing(3),
            color: 'red',
        },
        roleName: {
            fontWeight: 700,
            paddingRight: theme.spacing(),
        },
        roleRow: {
            border: '1px solid grey',
            borderBottom: 'none',
            padding: theme.spacing(),
            '&:last-child': {
                borderBottom: '1px solid grey',
            },
        },
        roleOptionalRow: {
            paddingLeft: 16,
        }
    });

export const useStyles = makeStyles(styles);
