import * as React from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, useFirestore, isLoaded, Dictionary } from 'react-redux-firebase';
import {
    Grid,
    Typography,
    List,
    ListItem,
    ListItemText,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    FormHelperText,
    DialogActions,
    IconButton,
    MenuItem,
    Select,
} from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon, RemoveCircle } from '@material-ui/icons';
import classnames from 'classnames';
import { fromPairs, toPairs, sortBy, lowerCase, isEmpty, debounce } from 'lodash-es';
import { CONVERSIONS_COLLECTION, RATES_COLLECTION, ROLES_COLLECTION } from '../../constants';
import { conversionList, rateList, roleList } from '../../store/selectors';
import { Conversion, Role } from '../../types';
import Spinner from '../Spinner';
import { useStyles } from './ConversionManagement.styles';
import { PageTitle } from '../PageTitle';

function ConversionManagement() {
    const classes = useStyles();
    const firestore = useFirestore();

    useFirestoreConnect(() => [{ collection: CONVERSIONS_COLLECTION }, { collection: ROLES_COLLECTION }, { collection: RATES_COLLECTION }]);
    const conversions = useSelector(conversionList);
    const sortedConversions = React.useMemo(() => sortConversions(conversions), [conversions]);
    const conversionsloaded = isLoaded(conversions);

    const [selectedConversionId, setSelectedConversionId] = React.useState('');

    const handleDeleteConversion = React.useCallback(() => {
        const docRef = firestore.doc(`${CONVERSIONS_COLLECTION}/${selectedConversionId}`);
        docRef.delete().then(() => {
            setSelectedConversionId('');
        });
    }, [firestore, selectedConversionId]);

    if (!conversionsloaded) {
        return <Spinner />;
    }

    return (
        <Grid container direction="row" className={classes.root}>
            <ConversionListPanel
                conversions={sortedConversions}
                selectedConversionId={selectedConversionId}
                onSelect={setSelectedConversionId}
            />
            <ConversionDetailsPanel
                conversions={conversions}
                conversionId={selectedConversionId}
                onDeleteConversion={handleDeleteConversion}
            />
        </Grid>
    );
}

function ConversionListPanel({
    conversions,
    selectedConversionId,
    onSelect,
}: {
    conversions: Dictionary<Conversion>;
    selectedConversionId: string;
    onSelect: (selectedId: string) => void;
}) {
    const classes = useStyles();
    const firestore = useFirestore();
    const conversionsCollectionRef = firestore.collection(CONVERSIONS_COLLECTION);
    const searchRef = React.useRef<any>(null);
    const getFilteredConversions = () => {
        const searchQuery = searchRef?.current?.value?.toLowerCase() || '';
        const filtered = Object.entries(conversions).filter(([, conversion]) => conversion?.name?.toLowerCase().includes(searchQuery));
        return filtered;
    };
    const [filteredConversions, setFilteredConversions] = React.useState(Object.entries(conversions));

    const onFilterChange = debounce(() => {
        setFilteredConversions(getFilteredConversions());
    }, 300);

    React.useEffect(() => {
        setFilteredConversions(getFilteredConversions());
    }, [conversions]);

    const editNameTextFieldRef = React.useRef<HTMLInputElement | null>();

    const numConversionsMissingRates = React.useMemo(() => {
        return Object.values(conversions).filter(isConversionMissingRates).length || 0;
    }, [conversions]);

    const handleListItemClick = React.useCallback(
        (conversionId: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            onSelect(conversionId);
        },
        [onSelect],
    );

    const [error, setError] = React.useState<string | null>(null);
    const [newDialogOpen, setNewDialogOpen] = React.useState(false);

    const openAddDialog = React.useCallback(() => {
        setError(null);
        setNewDialogOpen(true);
    }, [setNewDialogOpen]);

    const closeAddDialog = React.useCallback(() => {
        setNewDialogOpen(false);
    }, [setNewDialogOpen]);

    const handleSaveConversion = () => {
        const newConversionName = editNameTextFieldRef?.current?.value ?? '';
        if (isEmpty(newConversionName)) {
            setError('Name cannot be empty');
            return;
        }

        const isDuplicate = Object.values(conversions).some((conversion) => {
            return conversion?.name?.toLowerCase() === newConversionName?.toLowerCase();
        });
        if (isDuplicate) {
            setError('Name already in use, please choose another name');
            return;
        }

        const docRef = conversionsCollectionRef.doc();
        docRef
            .set({
                name: newConversionName,
                nameLower: newConversionName.toLowerCase(),
                rates: [],
            })
            .then(() => {
                const conversionId = docRef.id;
                onSelect(conversionId);
                closeAddDialog();
            })
            .catch((error) => {
                setError('Failed to create new conversion.');
                console.error(error);
            });
    };

    const showError = numConversionsMissingRates > 0;
    const errorMessage = `${numConversionsMissingRates} conversions are missing rates`;

    return (
        <Grid container item className={classes.conversionsListPanel} xs={4}>
            <PageTitle
                title="Appointment Conversions"
                tooltip="Conversions map appointment types from Acuity to the service rates. Rates are assigned by staff role. If an appointment is created in Acuity, and no conversion exists for the appointment type, a new converion will be created. If a conversion matches an appointment, but there are no rates assigned, then the appointment will not be invoiced."
                gridProps={{
                    className: classes.title,
                }}
            />
            <Grid
                container
                item
                direction="row"
                alignContent="center"
                justify="space-between"
                className={classes.newConversionButtonContainer}
            >
                <Grid item>
                    <Typography className={classes.conversionsListTitle}>{`${Object.keys(conversions).length} Conversions`}</Typography>
                    {showError && <Typography className={(classes.conversionsListTitle, classes.errorMessage)}>{errorMessage}</Typography>}
                </Grid>
                <Button variant="contained" onClick={openAddDialog} className={classes.newConversionButton}>
                    Add Conversion
                </Button>
            </Grid>
            <TextField
                fullWidth
                inputRef={searchRef}
                name="Filter"
                type="text"
                placeholder="Search"
                label="Filter"
                onChange={() => onFilterChange()}
            />
            <List className={classes.conversionsListContainer}>
                {filteredConversions.map(([conversionId, conversion]) => {
                    if (!conversion) {
                        return null;
                    }
                    const { name } = conversion;
                    return (
                        <ListItem
                            key={`${conversionId}-list-item`}
                            button
                            selected={selectedConversionId === conversionId}
                            onClick={handleListItemClick(conversionId)}
                            className={classnames({
                                [classes.isConversionMissingRates]: isConversionMissingRates(conversion),
                            })}
                        >
                            <ListItemText primary={name} />
                        </ListItem>
                    );
                })}
            </List>
            <Dialog
                PaperProps={{ className: classes.editNameDialog }}
                open={newDialogOpen}
                onClose={closeAddDialog}
                aria-labelledby="edit-form-dialog-title"
            >
                <DialogTitle id="edit-form-dialog-title">Conversion Name</DialogTitle>
                <DialogContent>
                    <DialogContentText>Enter a new name:</DialogContentText>
                    <Grid container direction="column">
                        <TextField inputRef={editNameTextFieldRef} defaultValue="" multiline />
                        <FormHelperText>Conversion Name</FormHelperText>
                    </Grid>
                    {error && <DialogContentText className={classes.addDialogMessage}>{error}</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAddDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveConversion} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

function ConversionDetailsPanel({
    conversions,
    conversionId,
    onDeleteConversion,
}: {
    conversions: Dictionary<Conversion>;
    conversionId: string;
    onDeleteConversion: () => void;
}) {
    const classes = useStyles();
    const firestore = useFirestore();
    const rates = useSelector(rateList);
    const roles = useSelector(roleList);
    const ratesLoaded = isLoaded(rates);
    const rolesLoaded = isLoaded(roles);
    const conversion = conversions[conversionId];

    const [addRateDialogOpen, setAddRateDialogOpen] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [selectedRole, setSelectedRole] = React.useState('');
    const [selectedRate, setSelectedRate] = React.useState('');

    const openDialog = React.useCallback(() => {
        setSelectedRole('');
        setSelectedRate('');
        setAddRateDialogOpen(true);
    }, [setSelectedRole, setSelectedRate, setAddRateDialogOpen]);

    const closeDialog = React.useCallback(() => {
        setSelectedRole('');
        setSelectedRate('');
        setAddRateDialogOpen(false);
    }, [setSelectedRole, setSelectedRate, setAddRateDialogOpen]);

    const onRoleChange = React.useCallback(
        (event: any) => {
            setSelectedRole(event.target.value);
        },
        [setSelectedRole],
    );

    const onRateChange = React.useCallback(
        (event: any) => {
            setSelectedRate(event.target.value);
        },
        [setSelectedRate],
    );

    const addStaffRate = () => {
        if (isEmpty(selectedRole)) {
            setError('Please select a role.');
            return;
        }
        if (isEmpty(selectedRate)) {
            setError('Please select a rate.');
            return;
        }

        const { rates: existingRates } = conversion;
        const updatedConversion = {
            rates: {
                ...existingRates,
                [selectedRole]: selectedRate,
            },
        };
        const docRef = firestore.doc(`${CONVERSIONS_COLLECTION}/${conversionId}`);
        docRef
            .update(updatedConversion)
            .then(() => {
                closeDialog();
            })
            .catch((error) => {
                setError('Failed to add new staff rate.');
                console.error(error);
            });
    };

    if (!conversion || conversionId === undefined) {
        return (
            <Grid
                item
                xs
                className={classnames(
                    classes.conversionDetailsPanelContainer,
                    classes.conversionDetailsContents,
                    classes.noSelectionMessage,
                )}
            >
                <Typography variant="body1">No conversion selected</Typography>
            </Grid>
        );
    }

    if (!ratesLoaded || !rolesLoaded) {
        return <Spinner />;
    }

    const { name, rates: conversionRates } = conversion;
    const availableRoles = Object.entries(roles).reduce((acc: Dictionary<Role>, [roleId, role]) => {
        const roleExists = Object.keys(conversionRates).includes(roleId);
        if (roleExists) {
            return acc;
        }

        return {
            ...acc,
            [roleId]: role,
        };
    }, {});
    return (
        <Grid item xs className={classes.conversionDetailsPanelContainer}>
            <ConversionActionBar
                conversions={conversions}
                selectedConversion={conversion}
                selectedConversionId={conversionId}
                onDeleteConversion={onDeleteConversion}
            />
            <Grid container className={classes.conversionDetailsContents} direction="column">
                <Typography variant="h5">{name}</Typography>
                <Button onClick={openDialog} variant="contained" className={classes.addRateButton}>
                    Add Rate
                </Button>
                <ConversionRates conversion={conversion} conversionId={conversionId} conversionRates={conversionRates} />
            </Grid>
            <Dialog open={addRateDialogOpen} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add staff rate</DialogTitle>
                <DialogContent>
                    <DialogContentText>Assign a staff role and pay rate to this appointment type.</DialogContentText>
                    <Grid direction="column" container>
                        <Select labelId="role-select-label" id="role-select" value={selectedRole || ''} onChange={onRoleChange}>
                            {Object.entries(availableRoles).map(([roleKey, role]) => (
                                <MenuItem value={roleKey} key={roleKey}>
                                    {role.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Staff</FormHelperText>
                        <Select labelId="rate-select-label" id="rate-select" value={selectedRate || ''} onChange={onRateChange}>
                            {Object.entries(rates).map(([rateId, { meta: { label } }]) => (
                                <MenuItem value={rateId} key={rateId}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>Role</FormHelperText>
                    </Grid>
                    {error && <DialogContentText className={classes.addDialogMessage}>{error}</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={addStaffRate} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

function ConversionActionBar({
    conversions,
    selectedConversion,
    selectedConversionId,
    onDeleteConversion,
}: {
    conversions: Dictionary<Conversion>;
    selectedConversion: Conversion;
    selectedConversionId: string;
    onDeleteConversion: () => void;
}) {
    const classes = useStyles();
    const firestore = useFirestore();

    const name = selectedConversion?.name || '';
    const editNameTextFieldRef = React.useRef<HTMLInputElement | null>();
    const [error, setError] = React.useState<string | null>(null);
    const [editDialogOpen, setEditDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const openEditDialog = React.useCallback(() => {
        setError(null);
        setEditDialogOpen(true);
    }, [setEditDialogOpen]);

    const closeEditDialog = React.useCallback(() => {
        setEditDialogOpen(false);
    }, [setEditDialogOpen]);

    const openDeleteDialog = React.useCallback(() => {
        setDeleteDialogOpen(true);
    }, [setDeleteDialogOpen]);

    const closeDeleteDialog = React.useCallback(() => {
        setDeleteDialogOpen(false);
    }, [setDeleteDialogOpen]);

    const handleDeleteConversion = () => {
        closeDeleteDialog();
        onDeleteConversion();
    };

    const handleSaveName = React.useCallback(() => {
        const newConversionName = editNameTextFieldRef?.current?.value ?? '';
        if (isEmpty(newConversionName)) {
            setError('Name cannot be empty');
            return;
        }

        const isDuplicate = Object.values(conversions).some((conversion) => {
            return conversion?.name?.toLowerCase() === newConversionName?.toLowerCase();
        });
        if (isDuplicate) {
            setError('Name already in use, please choose another name');
            return;
        }
        const docRef = firestore.doc(`${CONVERSIONS_COLLECTION}/${selectedConversionId}`);
        docRef
            .update({
                name: newConversionName,
                nameLower: newConversionName.toLowerCase(),
            })
            .then(() => {
                closeEditDialog();
            })
            .catch((error) => {
                setError('Failed to save conversion name.');
                console.error(error);
            });
    }, [selectedConversionId]);

    return (
        <Grid container item xs className={classes.actionBar} direction="row" justify="flex-end">
            <IconButton onClick={openEditDialog}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={openDeleteDialog}>
                <DeleteIcon />
            </IconButton>
            <Dialog
                PaperProps={{ className: classes.editNameDialog }}
                open={editDialogOpen}
                onClose={closeEditDialog}
                aria-labelledby="edit-form-dialog-title"
            >
                <DialogTitle id="edit-form-dialog-title">Edit Conversion Name</DialogTitle>
                <DialogContent>
                    <DialogContentText>Enter a new name:</DialogContentText>
                    <Grid direction="column" container>
                        <TextField inputRef={editNameTextFieldRef} defaultValue={name} multiline />
                        <FormHelperText>Conversion Name</FormHelperText>
                    </Grid>
                    {error && <DialogContentText className={classes.addDialogMessage}>{error}</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveName} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                PaperProps={{ className: classes.editNameDialog }}
                open={deleteDialogOpen}
                onClose={closeDeleteDialog}
                aria-labelledby="delete-form-dialog-title"
            >
                <DialogTitle id="delete-form-dialog-title">Delete Conversion</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you SURE you want to remove this conversion? This action cannot be undone.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConversion} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

type ConversionRateDictionary = {
    [roleId: string]: string;
};

function ConversionRates({
    conversionId,
    conversion,
    conversionRates,
}: {
    conversionId: string;
    conversion: Conversion;
    conversionRates: ConversionRateDictionary;
}) {
    const classes = useStyles();
    const firestore = useFirestore();
    const rates = useSelector(rateList);
    const roles = useSelector(roleList);
    const ratesLoaded = isLoaded(rates);
    const rolesLoaded = isLoaded(roles);

    const conversionsCollectionRef = firestore.collection(CONVERSIONS_COLLECTION);

    const onDeleteRate = (roleId: string) => () => {
        const conversionDocRef = conversionsCollectionRef.doc(conversionId);
        const { rates } = conversion;
        const { [roleId]: rateToRemove, ...ratesToKeep } = rates;
        conversionDocRef.update({
            rates: ratesToKeep,
        });
    };

    if (!ratesLoaded || !rolesLoaded) {
        return <Spinner />;
    }

    if (isEmpty(conversionRates)) {
        return (
            <Grid container direction="column" className={classes.rateRowsContainer}>
                <Typography>No rates added</Typography>
            </Grid>
        );
    }

    return (
        <Grid container direction="column" className={classes.rateRowsContainer}>
            {Object.entries(conversionRates).map(([roleId, rateId]) => {
                if (isEmpty(roleId) || isEmpty(rateId)) {
                    return null;
                }
                const roleName = roles[roleId]?.name || 'Role name not found';
                const rateName = rates[rateId]?.meta?.label || 'Rate name not found';
                return (
                    <ConversionRateRow
                        roleName={roleName}
                        rateName={rateName}
                        key={`${roleId}-${rateName}`}
                        onDelete={onDeleteRate(roleId)}
                    />
                );
            })}
        </Grid>
    );
}

function ConversionRateRow({ roleName, rateName, onDelete }: { roleName: string; rateName: string; onDelete: () => void }) {
    const classes = useStyles();
    return (
        <Grid direction="row" container item alignItems="center" className={classes.roleRow} key={`${roleName}-${rateName}`}>
            <Grid container item xs direction="row">
                <Typography>{`${roleName} - ${rateName}`}</Typography>
            </Grid>
            <IconButton onClick={onDelete} className={classes.removeRoleButton}>
                <RemoveCircle fontSize="inherit" />
            </IconButton>
        </Grid>
    );
}

function sortConversions(conversions?: Dictionary<Conversion>) {
    if (isEmpty(conversions)) {
        return {};
    }
    return fromPairs(
        sortBy(toPairs(conversions), [
            ([key, conversion]) => {
                return lowerCase(conversion?.name || '');
            },
        ]),
    );
}

function isConversionMissingRates(conversion: Conversion) {
    return !Object.keys(conversion.rates || {})?.length;
}

export default ConversionManagement;
