import {
    Divider,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { navigate } from 'gatsby';
import { isEmpty, isEqual } from 'lodash-es';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useFirebase,
    useFirestore,
    useFirestoreConnect,
} from 'react-redux-firebase';
import { actionTypes } from 'redux-firestore';

import { FILTER_NUM_MONTHS, FILTER_NUM_MONTHS_REVIEW_MODE } from '../../config';
import { EXPENSES_COLLECTION, USERS_COLLECTION } from '../../constants';
import {
    allExpenses,
    firebaseAuth,
    firebaseProfile,
    firebaseProfileReady,
    isAdminUser,
    userExpenses,
    userList,
} from '../../store/selectors';
import { Appointment, ExpenseDTO, RoleType, UserDTO } from '../../types';
import { formatUsername, useCalculatedAppointments } from '../../utils';
import { ButtonWithConfirmation } from '../ButtonWithConfirmation';
import { getFeesTable } from '../Freshbooks/freshbooksAdapter';
import { getAppointmentCost } from '../Freshbooks/utils';
import { Notification } from '../Notification';
import Spinner from '../Spinner';
import { useStyles } from './Appointments.styles';
import {
    AppointmentsProps,
    AppointmentsTableWrapperProps,
    AppointmentSummaryProps,
    ReviewSummaryProps,
} from './Appointments.types';
import AppointmentsTable from './AppointmentsTable/AppointmentsTable';
import {
    calculateTaxes,
    generateInvoice,
    parseMonthExpenses,
    TAX_RATE,
} from './generateInvoice';
import {
    formatCost,
    getCalculatedCosts,
    isAppointmentPostponed,
} from './utils';

const Appointments = ({ reviewMode = false }: AppointmentsProps) => {
    const numFilters = reviewMode
        ? FILTER_NUM_MONTHS_REVIEW_MODE
        : FILTER_NUM_MONTHS;

    const classes = useStyles();
    const firebase = useFirebase();
    const firestore = useFirestore();
    const dispatch = useDispatch();

    const auth = useSelector(firebaseAuth, isEqual);
    const isAdmin = useSelector(isAdminUser(auth?.uid), isEqual);
    const profile = useSelector(firebaseProfile, isEqual);
    const profileReady = useSelector(firebaseProfileReady);
    const users = useSelector(userList, isEqual) || {};

    const [startDate, setStartDate] = React.useState(getMonthStart());
    const [endDate, setEndDate] = React.useState(getMonthEnd());
    const [viewAsUser, setViewAsUser] = React.useState<string | undefined>(
        undefined,
    );

    const [notificationOpen, setNotificationOpen] = React.useState(false);
    const [notification, setNotification] = React.useState('');

    const expenses = useSelector(userExpenses(viewAsUser ?? auth.uid));
    useFirestoreConnect(() => [
        { collection: USERS_COLLECTION },
        { collection: EXPENSES_COLLECTION, doc: viewAsUser ?? auth.uid },
    ]);

    const onMonthFilterChange = React.useCallback(
        (start: Date, end: Date) => {
            setStartDate(start);
            setEndDate(end);
        },
        [setStartDate, setEndDate],
    );

    const handleUserFilterChange = React.useCallback(
        (userId: string) => {
            if (userId === 'NONE') {
                setViewAsUser(undefined);
                return;
            }
            setViewAsUser(userId);
        },
        [setViewAsUser],
    );

    React.useEffect(() => {
        return () => {
            dispatch({ type: actionTypes.CLEAR_DATA });
        };
    }, []);

    const handleClose = () => {
        setNotificationOpen(false);
    };

    const handleNotification = (message: string) => {
        setNotification(message);
        setNotificationOpen(true);
    };

    const { appointmentsWithCosts, isLoading } = useCalculatedAppointments(
        startDate,
        endDate,
        reviewMode,
        viewAsUser,
    );
    const appointmentsMissingApproval = React.useMemo(
        () =>
            appointmentsWithCosts.reduce((acc: number[], appointment) => {
                if (
                    appointment &&
                    !appointment?.approved &&
                    !isAppointmentPostponed(appointment, endDate)
                ) {
                    acc.push(appointment?.id);
                }
                return acc;
            }, []),
        [appointmentsWithCosts],
    );
    const numAppointmentsMissingApproval = React.useMemo(() => {
        return new Set(appointmentsMissingApproval).size || 0;
    }, [appointmentsWithCosts]);
    const appointmentsPostponed = React.useMemo(
        () =>
            appointmentsWithCosts.reduce((acc: number[], appointment) => {
                if (
                    appointment &&
                    isAppointmentPostponed(appointment, endDate)
                ) {
                    acc.push(appointment?.id);
                }
                return acc;
            }, []),
        [appointmentsWithCosts],
    );
    const numAppointmentsPostponed = React.useMemo(() => {
        return new Set(appointmentsPostponed).size || 0;
    }, [appointmentsPostponed]);

    const numAppointmentsWithErrors = React.useMemo(
        () =>
            appointmentsWithCosts.reduce((acc: number[], appointment) => {
                const hasError = !isEmpty(appointment?.calculations?.error);
                if (hasError) {
                    acc.push(appointment?.id);
                }
                return acc;
            }, []).length,
        [appointmentsWithCosts],
    );

    const redirectToInvoices = () => {
        localStorage.setItem(
            'invoiceStartDate',
            startDate.valueOf().toString(),
        );
        localStorage.setItem('invoiceEndDate', endDate.valueOf().toString());
        navigate('/app/freshbooks');
    };

    const finalizeInvoice = React.useCallback(() => {
        if (typeof window !== 'undefined') {
            const appointmentsExcludingPostponed = appointmentsWithCosts.filter(
                (appointment) => !isAppointmentPostponed(appointment, endDate),
            );
            const invoiceProfile = viewAsUser ? users[viewAsUser] : profile;
            const invoiceUserId = viewAsUser ?? auth.uid;
            generateInvoice(
                appointmentsExcludingPostponed,
                invoiceProfile,
                invoiceUserId,
                expenses,
                startDate,
                endDate,
                firebase,
                firestore,
            );
        }
    }, [appointmentsWithCosts, profile]);

    if (isLoading || !profileReady) {
        return <Spinner message="Loading appointments..." />;
    }

    return (
        <Grid>
            <Grid
                container
                item
                direction="column"
                className={classes.appointmentsActionBar}
            >
                <Grid
                    container
                    item
                    xs
                    alignItems="stretch"
                    direction="row"
                    wrap="nowrap"
                >
                    <MonthFilter
                        numMonths={numFilters}
                        start={startDate}
                        onFilterChange={onMonthFilterChange}
                    />
                    {isAdmin && !reviewMode && (
                        <ViewAsUserFilter
                            onUserSelected={handleUserFilterChange}
                            selectedUser={viewAsUser}
                        />
                    )}
                    <Grid container item xs={6} justify="flex-end">
                        {reviewMode ? (
                            <UploadInvoicesButton
                                numAppointmentsMissingApproval={
                                    numAppointmentsMissingApproval
                                }
                                onConfirm={redirectToInvoices}
                            />
                        ) : (
                            <FinalizeInvoiceButton
                                numAppointments={
                                    appointmentsWithCosts?.length ?? 0
                                }
                                numAppointmentsMissingApproval={
                                    numAppointmentsMissingApproval
                                }
                                numAppointmentsWithErrors={
                                    numAppointmentsWithErrors
                                }
                                numAppointmentsPostponed={
                                    numAppointmentsPostponed
                                }
                                onConfirm={finalizeInvoice}
                                invoiceDate={endDate}
                            />
                        )}
                    </Grid>
                </Grid>
                {reviewMode ? (
                    <ReviewSummary
                        appointments={appointmentsWithCosts}
                        startDate={startDate}
                        endDate={endDate}
                    />
                ) : (
                    <AppointmentsSummary
                        appointments={appointmentsWithCosts}
                        startDate={startDate}
                        endDate={endDate}
                        expenses={expenses}
                    />
                )}
            </Grid>
            <AppointmentsTableWrapper
                appointments={appointmentsWithCosts}
                reviewMode={reviewMode}
                onNotification={handleNotification}
                endDate={endDate}
            />
            <Notification
                open={notificationOpen}
                onClose={handleClose}
                message={notification}
            />
        </Grid>
    );
};

function AppointmentsSummary({
    appointments,
    startDate,
    endDate,
    expenses,
}: AppointmentSummaryProps) {
    const classes = useStyles();
    const [uniqueAppointments, postponed] = getUniqueAppointments(
        appointments,
        endDate,
    );
    const [monthExpenses, expenseTotal] = parseMonthExpenses(
        expenses,
        startDate,
        endDate,
    );
    const { subtotal, additional } = getStaffSubtotal(appointments);

    const taxes = calculateTaxes(subtotal + additional);
    const total = subtotal + additional + taxes + expenseTotal;
    const postponedString =
        postponed.length > 0 ? ` (${postponed.length} postponed)` : '';
    return (
        <Grid className={classes.appointmentsSummary}>
            <Typography>{`Total Appointments: ${uniqueAppointments.length}${postponedString}`}</Typography>
            <Typography>{`Subtotal: ${formatCost(subtotal)}`}</Typography>
            <Typography>{`Expenses (${monthExpenses.length}): ${formatCost(
                expenseTotal,
            )}`}</Typography>
            <Typography>{`Additional: ${formatCost(additional)}`}</Typography>
            <Typography>{`GST (${(TAX_RATE * 100).toFixed(0)}%): ${formatCost(
                taxes,
            )}`}</Typography>
            <Typography>{`TOTAL: ${formatCost(total)}`}</Typography>
        </Grid>
    );
}

function ReviewSummary({
    appointments,
    startDate,
    endDate,
}: ReviewSummaryProps) {
    const classes = useStyles();
    useFirestoreConnect(() => [
        { collection: EXPENSES_COLLECTION, storeAs: 'allExpenses' },
    ]);
    const expensesCollection = useSelector(allExpenses);
    const expenses = Object.values(expensesCollection).reduce(
        (acc: ExpenseDTO[], expenseDoc) => {
            return acc.concat(expenseDoc.expenses ?? []);
        },
        [],
    );
    const [monthExpenses, expenseTotal] = parseMonthExpenses(
        expenses,
        startDate,
        endDate,
    );

    const [uniqueAppointments, postponed] = getUniqueAppointments(
        appointments,
        endDate,
    );
    const postponedString =
        postponed.length > 0 ? ` (${postponed.length} postponed)` : '';

    const { subtotal: staffSubtotal, additional } =
        getStaffSubtotal(appointments);
    const staffTaxes = calculateTaxes(staffSubtotal + additional);
    const staffTotal = staffSubtotal + additional + staffTaxes;

    const clientSubtotal = getClientSubtotal(appointments);
    const clientTaxes = calculateTaxes(clientSubtotal);
    const clientTotal = clientSubtotal + clientTaxes;

    return (
        <Grid className={classes.appointmentsSummary}>
            <Typography>{`Total Appointments: ${uniqueAppointments.length}${postponedString}`}</Typography>
            <Typography>{`Expenses (${monthExpenses.length}): ${formatCost(
                expenseTotal,
            )}`}</Typography>
            <Typography>{`Additional: ${formatCost(additional)}`}</Typography>
            <Divider />
            <Typography>Staff</Typography>
            <Grid className={classes.appointmentsSummarySection}>
                <Typography>{`Subtotal: ${formatCost(
                    staffSubtotal,
                )}`}</Typography>
                <Typography>{`GST (${(TAX_RATE * 100).toFixed(
                    0,
                )}%): ${formatCost(staffTaxes)}`}</Typography>
                <Typography>{`TOTAL: ${formatCost(staffTotal)}`}</Typography>
            </Grid>

            <Divider />
            <Typography>Clients</Typography>
            <Grid className={classes.appointmentsSummarySection}>
                <Typography>{`Subtotal: ${formatCost(
                    clientSubtotal,
                )}`}</Typography>
                <Typography>{`GST (${(TAX_RATE * 100).toFixed(
                    0,
                )}%): ${formatCost(clientTaxes)}`}</Typography>
                <Typography>{`TOTAL: ${formatCost(clientTotal)}`}</Typography>
            </Grid>
        </Grid>
    );
}

function getUniqueAppointments(appointments: Appointment[], endDate: Date) {
    const uniques: Record<string, Appointment> = {};
    const postponed: Record<string, Appointment> = {};

    appointments.forEach((appointment) => {
        if (isAppointmentPostponed(appointment, endDate)) {
            postponed[appointment.id] = appointment;
            return;
        }

        uniques[appointment.id] = appointment;
    });
    return [Object.values(uniques), Object.values(postponed)];
}

function getStaffSubtotal(appointments: Appointment[]) {
    return appointments.reduce(
        (acc, appointment) => {
            const { total, travel, overnight } =
                getCalculatedCosts(appointment);
            return {
                subtotal: acc.subtotal + total,
                additional: acc.additional + travel + overnight,
            };
        },
        { subtotal: 0, additional: 0 },
    );
}

function getClientSubtotal(appointments: Appointment[]) {
    const feesTable = getFeesTable(appointments);
    return appointments.reduce((acc, appointment) => {
        if (isEmpty(appointment) || appointment.roleType === RoleType.lead) {
            return acc;
        }
        const includeAdditionalFees = feesTable[appointment.id] === appointment;
        return acc + getAppointmentCost(appointment, includeAdditionalFees);
    }, 0);
}

function AppointmentsTableWrapper({
    appointments,
    reviewMode,
    onNotification,
    endDate,
}: AppointmentsTableWrapperProps) {
    const classes = useStyles();

    if (isEmpty(appointments)) {
        return (
            <Grid className={classes.root}>
                <Typography className={classes.noAppointmentsMessage}>
                    No Appointments for Selected Month
                </Typography>
            </Grid>
        );
    }
    return (
        <Grid container direction="column">
            <AppointmentsTable
                data={Object.values(appointments)}
                reviewMode={reviewMode}
                onNotification={onNotification}
                endDate={endDate}
            />
        </Grid>
    );
}

function InvoiceErrorMessage({
    numAppointmentsMissingApproval,
    numAppointmentsWithErrors,
    numAppointmentsPostponed,
    isAdmin,
}: {
    isAdmin?: boolean;
    numAppointmentsMissingApproval: number;
    numAppointmentsWithErrors: number;
    numAppointmentsPostponed: number;
}) {
    const classes = useStyles();
    const adminMessage = isAdmin ? '' : ', please contact the admin.';
    const errorsMessage = `${numAppointmentsWithErrors} appointments have errors${adminMessage}`;
    const postponedMessage =
        numAppointmentsPostponed > 0
            ? ` (${numAppointmentsPostponed} postponed)`
            : '';
    const approvalsMessage = `${numAppointmentsMissingApproval} appointments require approval${postponedMessage}`;

    const hasErrors = numAppointmentsWithErrors > 0;
    const needsApprovals = numAppointmentsMissingApproval > 0;
    return (
        <Grid>
            {hasErrors && (
                <Typography className={classes.staffErrorMessage}>
                    {errorsMessage}
                </Typography>
            )}
            {needsApprovals && (
                <Typography className={classes.staffErrorMessage}>
                    {approvalsMessage}
                </Typography>
            )}
        </Grid>
    );
}

function FinalizeInvoiceButton({
    onConfirm,
    numAppointments,
    numAppointmentsMissingApproval,
    numAppointmentsWithErrors,
    numAppointmentsPostponed,
    invoiceDate,
}: {
    onConfirm: () => void;
    numAppointments: number;
    numAppointmentsMissingApproval: number;
    numAppointmentsWithErrors: number;
    numAppointmentsPostponed: number;
    invoiceDate: Date;
}) {
    const hasErrors = numAppointmentsWithErrors > 0;
    const needsApprovals = numAppointmentsMissingApproval > 0;
    if (hasErrors || needsApprovals) {
        return (
            <InvoiceErrorMessage
                numAppointmentsMissingApproval={numAppointmentsMissingApproval}
                numAppointmentsWithErrors={numAppointmentsWithErrors}
                numAppointmentsPostponed={numAppointmentsPostponed}
            />
        );
    }

    const postponedMessage =
        numAppointmentsPostponed > 0
            ? ` (${numAppointmentsPostponed} postponed)`
            : '';
    const numApproved = numAppointments - numAppointmentsPostponed;

    return (
        <ButtonWithConfirmation
            buttonText="Finalize Invoice"
            dialogTitle="Finalize Invoice"
            dialogText={`${numApproved} appointments were approved${postponedMessage}. Finalize the invoice for ${
                MONTHS_FULL[invoiceDate.getMonth()]
            }?`}
            confirmText="Confirm"
            onConfirm={onConfirm}
            startIcon={<Publish />}
        />
    );
}

function UploadInvoicesButton({
    numAppointmentsMissingApproval,
    onConfirm,
}: {
    numAppointmentsMissingApproval: number;
    onConfirm: () => void;
}) {
    return (
        <ButtonWithConfirmation
            buttonText="Review Invoices"
            dialogTitle="Start reviewing Freshbooks invoices?"
            dialogText={getUploadInvoicesDialogText(
                numAppointmentsMissingApproval,
            )}
            confirmText="Review"
            onConfirm={onConfirm}
            startIcon={<Publish />}
        />
    );
}

function getUploadInvoicesDialogText(numAppointmentsMissingApprovals: number) {
    if (numAppointmentsMissingApprovals > 0) {
        return `${numAppointmentsMissingApprovals} appointments require approval.\nAre you sure you want to upload the invoices now?`;
    }
    return `All appointments are approved, upload invoices to Freshbooks?`;
}

function ViewAsUserFilter({
    selectedUser = 'NONE',
    onUserSelected,
}: {
    selectedUser?: string;
    onUserSelected: (user: string) => void;
}) {
    const classes = useStyles();
    const users: Record<string, UserDTO> = useSelector(userList, isEqual) || {};

    const handleChange = React.useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            const value = event.target.value;
            onUserSelected(value);
        },
        [onUserSelected],
    );

    return (
        <Grid container item direction="row" wrap="nowrap">
            <Typography variant="h6" className={classes.monthFilterTitle}>
                View As
            </Typography>
            <FormControl className={classes.monthFilterFormControl}>
                <Select
                    className={classes.monthFilterSelect}
                    labelId="month-filter"
                    id="month-filter"
                    value={selectedUser}
                    onChange={handleChange}
                >
                    <MenuItem key="none" value="NONE">
                        None
                    </MenuItem>
                    {Object.entries(users).map(([userId, user]) => (
                        <MenuItem key={userId} value={userId}>
                            {formatUsername(user)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}

function MonthFilter({
    start,
    onFilterChange,
    numMonths = 3,
}: {
    start: Date;
    onFilterChange: (startDate: Date, endDate: Date) => void;
    numMonths: number;
}) {
    const classes = useStyles();
    const startOfCurrentMonth = getMonthStart();
    const dateOptions = [...Array(numMonths).keys()].map((numMonthsPrior) => {
        const deltaMonths = numMonthsPrior % 12;
        const deltaYears = (numMonthsPrior - deltaMonths) / 12;

        return new Date(
            startOfCurrentMonth.getFullYear() - deltaYears,
            startOfCurrentMonth.getMonth() - deltaMonths,
        );
    });

    const handleChange = React.useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            const value = event.target.value;
            const selectedDate = new Date(Number.parseInt(value, 10));
            onFilterChange(
                getMonthStart(selectedDate),
                getMonthEnd(selectedDate),
            );
        },
        [onFilterChange],
    );

    return (
        <Grid container item direction="row" wrap="nowrap">
            <Typography variant="h6" className={classes.monthFilterTitle}>
                Month
            </Typography>
            <FormControl className={classes.monthFilterFormControl}>
                <Select
                    className={classes.monthFilterSelect}
                    labelId="month-filter"
                    id="month-filter"
                    value={start.valueOf()}
                    onChange={handleChange}
                >
                    {dateOptions.map((date) => (
                        <MenuItem key={date.valueOf()} value={date.valueOf()}>
                            {formatFilterOption(date)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}

const MONTHS = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];
const MONTHS_FULL = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

function formatFilterOption(date: Date) {
    const monthString = MONTHS[date.getMonth()];
    return `${monthString} ${date.getFullYear()}`;
}

export function getMonthStart(date: Date = new Date()) {
    return new Date(date.getFullYear(), date.getMonth());
}

export function getMonthEnd(date: Date = new Date()) {
    const monthStart = getMonthStart(date);
    const numMonths = monthStart.getMonth() + 1;
    const newMonth = numMonths % 12;
    const deltaYears = (numMonths - newMonth) / 12;
    const endDate = new Date(date.getFullYear() + deltaYears, newMonth);
    const endDateTime = new Date(endDate.valueOf() - 1);
    return endDateTime;
}

export default Appointments;
