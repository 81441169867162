import { makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        title: {
            padding: `${theme.spacing(2)}px 0px`,
        },
        tooltip: {
            fontSize: 16,
        },
    });

export const useStyles = makeStyles(styles);
