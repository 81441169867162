import * as React from 'react';
import { Snackbar } from '@material-ui/core';
import { NotificationProps } from './Notification.types';
import { useStyles } from './Notification.styles';

const Notification = ({open, onClose, message}:NotificationProps) => {
    const classes = useStyles();

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
            open={open}
            onClose={onClose}
            ContentProps={{
                "aria-describedby": "message-id",
                className: classes.notification
              }}
            message={message}
        />
    )
}

export default Notification