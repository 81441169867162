import { filter, fromPairs, isEmpty, pick, toPairs } from 'lodash-es';
import { Dictionary, isLoaded } from 'react-redux-firebase';
import { createSelector } from 'reselect';

import {
    APPOINTMENTS_COLLECTION,
    CALENDAR_COLLECTION,
    CALENDAR_ROLES_COLLECTION,
    CLIENTS_COLLECTION,
    CONVERSIONS_COLLECTION,
    EXPENSES_COLLECTION,
    INVOICES_COLLECTION,
    LEADS_COLLECTION,
    RATES_COLLECTION,
    ROLES_COLLECTION,
    USERS_COLLECTION,
} from '../../constants';
import {
    AppointmentDTO,
    AppState,
    CalendarDTO,
    CalendarRoleMapObject,
    ClientDTO,
    Conversion as ConversionDTO,
    LeadDTO,
    RateObject as RateDTO,
    Role as RoleDTO,
    UserDTO,
    UserExpensesDTO,
    UserInvoicesDTO,
} from '../../types';
import { translateRates } from '../../utils';

export const firestoreState = (state: AppState) => state.firestore;

const appointmentList = ({
    reviewMode,
    postponed,
    viewAsUser,
}: {
    reviewMode: boolean;
    postponed?: boolean;
    viewAsUser?: string;
}) =>
    createSelector(
        firestoreState,
        (firestoreState): Dictionary<AppointmentDTO> => {
            const storeAsPrefix = viewAsUser ? `${viewAsUser}-` : '';
            const appointmentsPrefix = `${storeAsPrefix}${APPOINTMENTS_COLLECTION}`;

            if (reviewMode) {
                if (postponed) {
                    return (
                        firestoreState.data[
                            `${appointmentsPrefix}-postponed`
                        ] || {}
                    );
                }

                return firestoreState.data[APPOINTMENTS_COLLECTION] || {};
            }

            const appointmentBucketKeys = postponed
                ? Object.keys(firestoreState.data).filter(
                      (key) =>
                          key.startsWith(appointmentsPrefix) &&
                          key.endsWith('postponed'),
                  )
                : Object.keys(firestoreState.data).filter(
                      (key) =>
                          key.startsWith(appointmentsPrefix) &&
                          !key.endsWith('postponed'),
                  );
            return appointmentBucketKeys.reduce(
                (acc: Dictionary<AppointmentDTO>, bucketKey) => {
                    if (bucketKey === APPOINTMENTS_COLLECTION) {
                        return acc;
                    }
                    const bucketData = firestoreState.data[bucketKey] || {};
                    return { ...acc, ...bucketData };
                },
                {},
            );
        },
    );

export const reducedAppointmentList = (props: {
    reviewMode: boolean;
    postponed?: boolean;
    viewAsUser?: string;
}) =>
    createSelector(
        appointmentList(props),
        (appointmentList): Dictionary<AppointmentDTO> =>
            Object.entries(appointmentList).reduce((result, [key, value]) => {
                return {
                    ...result,
                    [key]: pick(value, [
                        'displayName',
                        'firstName',
                        'lastName',
                        'email',
                        'phone',
                        'calendar',
                        'datetime',
                        'postponedDate',
                        'time',
                        'duration',
                        'type',
                        'approved',
                        'id',
                        'calculations',
                        'calendarID',
                        'formFields',
                        'price',
                        'priceSold',
                        'certificate',
                    ]),
                };
            }, {}),
    );

export const appointmentById = (appointmentId: number, reviewMode: boolean) =>
    createSelector(
        appointmentList(reviewMode),
        (appointmentList) => appointmentList?.[appointmentId],
    );

export const calendarList = createSelector(
    firestoreState,
    (firestoreState): Dictionary<CalendarDTO> => {
        const calendars = (firestoreState.data[CALENDAR_COLLECTION] ||
            {}) as Dictionary<CalendarDTO>;
        return fromPairs(
            filter(
                toPairs(calendars),
                ([key, calendar]) => !isEmpty(calendar.name),
            ),
        );
    },
);

export const calendarRoleList = createSelector(
    firestoreState,
    (firestoreState): Dictionary<CalendarRoleMapObject> =>
        firestoreState.data[CALENDAR_ROLES_COLLECTION] || {},
);

export const isLoadingAppointments = createSelector(
    firestoreState,
    (firestoreState) => {
        const requests = Object.entries(
            firestoreState.status.requesting || [],
        ) as any[];
        return requests.some(([key, value]) => {
            const calendarRequesting =
                (key as string).startsWith(CALENDAR_COLLECTION) &&
                value === true;
            const appointmentsRequesting =
                (key as string).startsWith(APPOINTMENTS_COLLECTION) &&
                value === true;
            return calendarRequesting || appointmentsRequesting;
        });
    },
);

export const userById = (userId = '') =>
    createSelector(userList, (userList): UserDTO => {
        return userList?.[userId] as UserDTO;
    });

export const userList = createSelector(
    firestoreState,
    (firestoreState): Record<string, UserDTO> => {
        return (firestoreState?.data?.[USERS_COLLECTION] || {}) as Record<
            string,
            UserDTO
        >;
    },
);

export const userListReady = createSelector(
    userList,
    (userList: Dictionary<UserDTO>) => isLoaded(userList) && !isEmpty(userList),
);

export const isAdminUser = (userId = '') =>
    createSelector(userById(userId), (user) => {
        return user?.admin === true;
    });

export const rateList = createSelector(
    firestoreState,
    (firestoreState): Dictionary<RateDTO> =>
        translateRates(firestoreState.data?.[RATES_COLLECTION] || {}),
);

export const conversionList = createSelector(
    firestoreState,
    (firestoreState): Dictionary<ConversionDTO> =>
        firestoreState.data[CONVERSIONS_COLLECTION] || {},
);

export const roleList = createSelector(
    firestoreState,
    (firestoreState): Dictionary<RoleDTO> =>
        firestoreState.data[ROLES_COLLECTION] || {},
);

export const leadsList = createSelector(
    firestoreState,
    (firestoreState): Dictionary<LeadDTO> => {
        return firestoreState.data[LEADS_COLLECTION] || {};
    },
);

export const clientsList = createSelector(
    firestoreState,
    (firestoreState): Dictionary<ClientDTO> => {
        return firestoreState.data[CLIENTS_COLLECTION] || {};
    },
);

export const invoicesList = createSelector(
    firestoreState,
    (firestoreState): Dictionary<UserInvoicesDTO> => {
        return firestoreState.data[INVOICES_COLLECTION] || {};
    },
);

export const allExpenses = createSelector(
    firestoreState,
    (firestoreState): Dictionary<UserExpensesDTO> => {
        return firestoreState.data.allExpenses || {};
    },
);

export const expensesList = createSelector(
    firestoreState,
    (firestoreState): Dictionary<UserExpensesDTO> => {
        return firestoreState.data[EXPENSES_COLLECTION] || {};
    },
);

export const userExpenses = (userId: string) =>
    createSelector(
        expensesList,
        (expensesList: Dictionary<UserExpensesDTO>) => {
            return expensesList?.[userId]?.expenses ?? [];
        },
    );

export const myExpenseList = createSelector(
    firestoreState,
    (firestoreState): UserExpensesDTO => {
        return (firestoreState.data as any).myExpenses || {};
    },
);
