import { createMuiTheme } from '@material-ui/core/styles';
import GillSansTtf from '../../fonts/gillsans/truetype.ttf';
import GillSansWoff from '../../fonts/gillsans/font-gill-sans.woff';

const gillSans = {
    fontFamily: 'GillSans',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 300,
    src: `
    local('GillSans'),
    local('GillSans-Regular'),
    url(${GillSansTtf}) format('truetype')
    url(${GillSansWoff}) format('woff')
  `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const spacing = 8;
const appTheme = createMuiTheme({
    spacing: 8,
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [gillSans],
            },
        },
        MuiIconButton: {
            root: {
                padding: spacing,
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '1rem',
            },
        },
        MuiTypography: {
            body1: {
                fontWeight: 300,
            },
        },
    },
    typography: {
        fontFamily: 'GillSans, Arial',
    },
});

export default appTheme;
