import {
    ExtendedFirebaseInstance,
    ExtendedFirestoreInstance,
} from 'react-redux-firebase';
import { isEmpty, round, orderBy } from 'lodash-es';
import { Timestamp } from '@google-cloud/firestore';
import { INVOICES_COLLECTION, USERS_COLLECTION } from '../../constants';
import {
    formatCost,
    formatDate,
    formatDiscounts,
    getCalculatedCosts,
    timestampToDate,
} from './utils';
import {
    Appointment,
    ExpenseDTO,
    InvoiceAppointmentRow,
    RoleType,
    UserDTO,
} from '../../types';

export const TAX_RATE = Number.parseFloat(
    process.env.GATSBY_TAX_RATE || '0.05',
);

export const generateInvoice = async (
    appointments: Appointment[],
    profile: UserDTO,
    userId: string,
    expenses: ExpenseDTO[],
    startDate: Date,
    endDate: Date,
    firebase: ExtendedFirebaseInstance,
    firestore: ExtendedFirestoreInstance,
) => {
    const windowDefined = typeof window !== 'undefined';
    const { default: JsPDF } = windowDefined
        ? await import('jspdf')
        : ({} as any);
    const { default: injectAutoTable } = windowDefined
        ? await import('jspdf-autotable')
        : ((() => {}) as any);

    const invoicePeriod = endDate.toLocaleDateString();
    const [monthExpenses, expenseTotal] = parseMonthExpenses(
        expenses,
        startDate,
        endDate,
    );

    const { subtotal, additional } = appointments.reduce(
        (acc, appointment) => {
            const { total, travel, overnight } =
                getCalculatedCosts(appointment);
            return {
                subtotal: acc.subtotal + total,
                additional: acc.additional + travel + overnight,
            };
        },
        { subtotal: 0, additional: 0 },
    );

    const taxes = calculateTaxes(subtotal + additional);
    const total = subtotal + additional + taxes + expenseTotal;

    /// ///////////////////////////////////////
    const {
        firstName = '',
        lastName = '',
        streetAddress = '',
        streetAddressLineTwo = '',
        city = '',
        province = '',
        country = '',
        postalCode = '',
        gstNumber = '',
    } = profile;
    const lineTwoYOffset = isEmpty(streetAddressLineTwo) ? 0 : 5;

    const doc = new JsPDF({ format: 'letter', orientation: 'l' });
    injectAutoTable(doc, {});

    // draw meta
    const headerColOneX = 10;
    const headerColTwoX = 80;
    const headerColThreeX = 100;
    const headerY = 10;
    doc.setFontSize(12);
    doc.setFont(undefined, 'bold');
    doc.text('SUBCONTRACTOR', headerColOneX, headerY);
    doc.text('Invoice Date', headerColThreeX, headerY);
    doc.text('GST #', headerColThreeX, headerY + 15);

    doc.setFont(undefined, 'regular');
    doc.text(invoicePeriod, headerColThreeX, headerY + 5);
    if (gstNumber)
        doc.text(gstNumber ?? 'NO GST #', headerColThreeX, headerY + 20);

    if (firstName && lastName)
        doc.text(`${firstName} ${lastName}`, headerColOneX, headerY + 5);
    if (streetAddress) doc.text(streetAddress, headerColOneX, headerY + 10);
    if (streetAddressLineTwo)
        doc.text(streetAddressLineTwo ?? '', headerColOneX, headerY + 15);
    if (city && province)
        doc.text(
            `${city}, ${province}`,
            headerColOneX,
            headerY + 15 + lineTwoYOffset,
        );
    if (country)
        doc.text(country, headerColOneX, headerY + 20 + lineTwoYOffset);
    if (postalCode)
        doc.text(postalCode, headerColOneX, headerY + 25 + lineTwoYOffset);

    // draw summary
    const metaY = headerY + 40;
    doc.setFont(undefined, 'bold');
    doc.text('Total Appointments', headerColOneX, metaY);
    doc.text('Subtotal', headerColOneX, metaY + 5);
    doc.text('EXPENSES', headerColOneX, metaY + 10);
    doc.text('ADDITIONAL', headerColOneX, metaY + 15);
    doc.text('GST', headerColOneX, metaY + 20);
    doc.text('TOTAL', headerColOneX, metaY + 25);
    doc.text('APPOINTMENTS', headerColOneX, metaY + 35);

    doc.setFont(undefined, 'regular');
    doc.text(
        `${appointments.length}`,
        headerColTwoX,
        metaY,
        undefined,
        undefined,
        'right',
    );
    doc.text(
        formatCost(subtotal),
        headerColTwoX,
        metaY + 5,
        undefined,
        undefined,
        'right',
    );
    doc.text(
        formatCost(expenseTotal),
        headerColTwoX,
        metaY + 10,
        undefined,
        undefined,
        'right',
    );
    doc.text(
        formatCost(additional),
        headerColTwoX,
        metaY + 15,
        undefined,
        undefined,
        'right',
    );
    doc.text(
        formatCost(taxes),
        headerColTwoX,
        metaY + 20,
        undefined,
        undefined,
        'right',
    );
    doc.text(
        formatCost(total),
        headerColTwoX,
        metaY + 25,
        undefined,
        undefined,
        'right',
    );

    // draw appointments table
    doc.setFontSize(10);
    const appointmentColumns = getAppointmentColumns();
    const appointmentHead = getHeaderRows(appointmentColumns);

    (doc as any).autoTable({
        head: appointmentHead,
        body: formatAppointments(appointments),
        columns: appointmentColumns,
        // Default for all columns
        // styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
        // Override the default above for the text column
        // columnStyles: { text: { cellWidth: 'auto' } },
        rowPageBreak: 'avoid',
        bodyStyles: { valign: 'top' },
        startY: metaY + 40,
        margin: 10,
        columnStyles: {
            datetime: { minCellWidth: 25, halign: 'right' },
            fee: { halign: 'right' },
        },
        didParseCell: (data: any) => {
            if (data.cell.section === 'head') {
                if (data.column.dataKey === 'fee')
                    // eslint-disable-next-line no-param-reassign
                    data.cell.styles.halign = 'right';
            }
        },
    });

    console.log(doc.lastAutoTable.finalY);
    // draw expenses header
    doc.setFont(undefined, 'bold');
    doc.setFontSize(12);
    doc.text('EXPENSES', headerColOneX, doc.lastAutoTable.finalY + 10);

    // draw expenses table
    doc.setFont(undefined, 'regular');
    doc.setFontSize(10);
    const expenseColumns = getExpenseColumns();
    const expenseHead = getHeaderRows(expenseColumns);
    (doc as any).autoTable({
        head: expenseHead,
        body: formatExpenses(monthExpenses),
        columns: expenseColumns,
        // Default for all columns
        // styles: { overflow: 'ellipsize', cellWidth: 'wrap' },
        // Override the default above for the text column
        // columnStyles: { text: { cellWidth: 'auto' } },
        rowPageBreak: 'avoid',
        bodyStyles: { valign: 'top' },
        startY: doc.lastAutoTable.finalY + 15,
        margin: 10,
        headStyles: { gst: { halign: 'center' } },
        columnStyles: {
            datetime: { cellWidth: 50, halign: 'left' },
            gst: { halign: 'center' },
            amount: { halign: 'right' },
        },
        didParseCell: (data: any) => {
            if (data.cell.section === 'head') {
                switch (data.column.dataKey) {
                    case 'amount':
                        // eslint-disable-next-line no-param-reassign
                        data.cell.styles.halign = 'right';
                        break;
                    case 'gst':
                        // eslint-disable-next-line no-param-reassign
                        data.cell.styles.halign = 'center';
                        break;
                }
            }
        },
    });

    const pdfBlob = doc.output('blob');
    const folderName = `${firstName}_${lastName}`;
    const date = new Date();
    const formattedDate = `${
        date.getMonth() + 1
    }_${date.getDate()}_${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`;
    const invoiceFileName = `${folderName}_${formattedDate}.pdf`;
    uploadInvoice(
        firebase,
        firestore,
        userId,
        pdfBlob,
        invoiceFileName,
        folderName,
        date,
    );

    doc.save(invoiceFileName);
};

function uploadInvoice(
    firebase: ExtendedFirebaseInstance,
    firestore: ExtendedFirestoreInstance,
    userId: string,
    file: any,
    fileName: string,
    folderId: string,
    date: Date,
) {
    const INVOICE_FOLDER = 'staff_invoices';
    const filePath = `${INVOICE_FOLDER}/${folderId}/${fileName}`;
    const storageRef = firebase.storage().ref();
    const invoiceRef = storageRef.child(filePath);

    // upload the blob
    invoiceRef.put(file).then(async function (snapshot) {
        const sizeKB = snapshot.totalBytes / 1024;
        // eslint-disable-next-line no-console
        console.log(`Uploaded invoice (${sizeKB.toFixed(1)}KB)!`);

        const invoicesCollection = firestore.collection(INVOICES_COLLECTION);
        const userInvoicesDoc = invoicesCollection.doc(userId);
        const userRef = firestore.collection(USERS_COLLECTION).doc(userId);
        userInvoicesDoc.get().then(async function (userInvoicesSnapshot) {
            if (!userInvoicesSnapshot.exists) {
                await userInvoicesDoc.set({
                    userId,
                    user: userRef,
                    invoices: [],
                });
            }

            const existingInvoices =
                userInvoicesSnapshot.data()?.invoices ?? [];
            userInvoicesDoc.update({
                invoices: [
                    ...existingInvoices,
                    {
                        invoiceUrl: await snapshot.ref.getDownloadURL(),
                        datetime: date,
                    },
                ],
            });
        });
    });
}

export function formatExpenses(expenses: ExpenseDTO[]): any[] {
    return orderBy(expenses, ['datetime'], ['desc']).map((expense) => {
        const { datetime, amount, gst, description } = expense;
        return {
            datetime: formatDate(datetime as Timestamp, false, false),
            amount: formatCost(amount),
            gst: gst ? 'Yes' : 'No',
            description,
        };
    });
}

export function formatAppointments(
    appointments: Appointment[],
): InvoiceAppointmentRow[] {
    return orderBy(appointments, ['datetime'], ['desc']).map((appointment) => {
        const {
            datetime,
            postponedDate,
            displayName,
            type = 'not found',
            notes,
            formFields = {},
            roleType = RoleType.unknown,
            approved,
            calendar,
            calculations,
            preferred,
            staffId,
            rate,
            price,
            priceSold,
            certificate,
        } = appointment;
        const {
            hoursOne,
            hoursTwo,
            numImages,
            sqft,
            sqftAux,
            address = 'N/A',
        } = formFields;

        const rateDescription = rate?.meta?.label;
        const descriptionParts = [`${rateDescription || type}`];

        if (hoursOne) {
            const unit = hoursOne === `1` ? 'hr' : 'hrs';
            descriptionParts.push(`Shooting: ${hoursOne}${unit}`);
        }
        if (hoursTwo) {
            const unit = hoursTwo === `1` ? 'hr' : 'hrs';
            descriptionParts.push(`Editing: ${hoursTwo}${unit}`);
        }
        if (numImages) {
            descriptionParts.push(`# of Images: ${numImages}`);
        }
        if (sqft || sqftAux) {
            const sqftRmsNum = Number.parseFloat(sqft || '0') ?? 0;
            const sqftAuxNum = Number.parseFloat(sqftAux || '0') ?? 0;
            const totalSqft = sqftRmsNum + sqftAuxNum;

            descriptionParts.push(
                `${sqftRmsNum} RMS + ${sqftAuxNum} Aux = ${totalSqft.toFixed(
                    1,
                )} sqft`,
            );
        }

        const { reason: formDiscount } = formatDiscounts(appointment);
        if (formDiscount) {
            descriptionParts.push(formDiscount);
        }

        const {
            total: fee,
            travel,
            overnight,
        } = getCalculatedCosts(appointment);
        const formattedFee = formatCost(fee);
        const formattedTravel = formatCost(travel);
        const formattedOvernight = formatCost(overnight);
        const formattedAdditional = formatCost(travel + overnight);
        if (travel > 0) {
            descriptionParts.push(`Travel Fee: ${formattedTravel}`);
        }
        if (overnight > 0) {
            descriptionParts.push(`Overnight Fee: ${formattedOvernight}`);
        }

        if (notes) {
            descriptionParts.push(`Notes: ${notes}`);
        }

        const newLine = '\n';
        const description = descriptionParts.join(newLine);

        const result = {
            id: appointment.id,
            datetime: formatDate(datetime, true),
            rawDatetime: timestampToDate(datetime),
            postponedDate: postponedDate
                ? timestampToDate(postponedDate)
                : undefined,
            displayName,
            description,
            address,
            fee: formattedFee,
            roleType,
            approved,
            travel: formattedTravel,
            overnight: formattedOvernight,
            additional: formattedAdditional,
            calendar,
            calculations,
            preferred,
            staffId,
            price,
            priceSold,
            certificate,
        };
        return result;
    });
}

function getAppointmentColumns() {
    return [
        { dataKey: 'datetime', header: 'Date' },
        { dataKey: 'displayName', header: 'Client' },
        { dataKey: 'description', header: 'Description' },
        { dataKey: 'address', header: 'Address' },
        { dataKey: 'fee', header: 'Fee' },
        { dataKey: 'travel', header: 'Travel & Delivery' },
    ];
}

function getExpenseColumns() {
    return [
        { dataKey: 'datetime', header: 'Date' },
        { dataKey: 'description', header: 'Description' },
        { dataKey: 'gst', header: 'GST' },
        { dataKey: 'amount', header: 'Amount' },
    ];
}

function getHeaderRows(columns: { dataKey: string; header: string }[]) {
    const headRow = columns.reduce((acc, field) => {
        const { dataKey, header } = field;
        return { ...acc, [dataKey]: header };
    }, {});
    return [headRow];
}

export function calculateTaxes(amount: number) {
    const taxes = amount * TAX_RATE;
    return round(taxes, 2);
}

export function parseMonthExpenses(
    expenses: ExpenseDTO[],
    startDate: Date,
    endDate: Date,
): [monthExpenses: ExpenseDTO[], expenseTotal: number] {
    const monthExpenses = expenses.filter((expense) => {
        const date = timestampToDate(expense.datetime as Timestamp);
        return date !== undefined && date <= endDate && date >= startDate;
    });
    const expenseTotal = monthExpenses.reduce((acc, expense) => {
        const addGst = expense.gst ?? false;
        const expenseAmount = addGst
            ? expense.amount * (1 + TAX_RATE)
            : expense.amount;
        return acc + expenseAmount;
    }, 0);

    return [monthExpenses, expenseTotal];
}
