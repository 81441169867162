import { Button, Grid, Typography } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { isEmpty, sortBy } from 'lodash-es';
import * as React from 'react';
import { useBeforeunload } from 'react-beforeunload';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import {
    Dictionary,
    isLoaded,
    useFirestoreConnect,
} from 'react-redux-firebase';

import { CLIENTS_COLLECTION } from '../../constants';
import { clientsList } from '../../store/selectors';
import { Appointment, FreshbooksClientDTO, RoleType } from '../../types';
import { useCalculatedAppointments } from '../../utils';
import { getMonthEnd, getMonthStart } from '../Appointments';
import { formatCost, isAppointmentPostponed } from '../Appointments/utils';
// import { appointments as testData } from './testData';
// import { appointments as testData } from './testDataDev';
import { ButtonWithConfirmation } from '../ButtonWithConfirmation';
import { findMatchingFreshbooksClient } from '../ClientManagement';
import Spinner from '../Spinner';
import { ClientSummaryRow } from './ClientSummaryRow';
import { useStyles } from './Freshbooks.styles';
import {
    ACCESS_TOKEN_KEY,
    generateInvoices,
    getFbAuth,
    getFbClients,
    getFbMe,
    getFeesTable,
    getPaymentOptions,
    REFRESH_TOKEN_KEY,
    setPaymentOptionsForInvoice,
    uploadToFreshbooks,
    verifyAuth,
} from './freshbooksAdapter';
import { InvoiceUploadStatus, PageStatus } from './types';
import { UploadProgressBar } from './UploadProgressBar';
import {
    appointmentMatch,
    ensureInvoicesHavePaymentOptions,
    getAcuityClientFromAppointment,
    getAppointmentCost,
    onDebugInfo,
} from './utils';

console.log(process.env.GATSBY_ENV ?? 'PROD');

function Freshbooks() {
    const classes = useStyles();
    const [isFreshbooksAuthenticated, setIsFreshbooksAuthenticated] =
        React.useState(false);

    const location = useLocation();
    const authenticateFreshbooks = React.useCallback(() => {
        const fbAuth = getFbAuth();
        const uri = fbAuth.code.getUri();
        navigate(uri);
    }, []);

    React.useEffect(() => {
        let isSubscribed = true;
        const fbAuth = getFbAuth();
        const searchParams = new URLSearchParams(location.search);
        const fbAuthCode = searchParams.get('code');
        if (fbAuthCode) {
            fbAuth.code
                .getToken(location)
                .then(({ accessToken, refreshToken }) => {
                    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
                    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
                    if (isSubscribed) {
                        setIsFreshbooksAuthenticated(true);
                    }
                    window.history.replaceState(
                        null,
                        document.title,
                        location.pathname,
                    );
                })
                .catch((reason) =>
                    console.error(
                        `Failed to authenticate Freshbooks: ${reason}`,
                    ),
                );
        } else {
            verifyAuth(fbAuth)
                .then((_user) => {
                    if (isSubscribed) {
                        setIsFreshbooksAuthenticated(true);
                    }
                })
                .catch((reason) => {
                    console.error('Failed to verify authentication', reason);
                    if (isSubscribed) {
                        setIsFreshbooksAuthenticated(false);
                    }
                });
        }
        return () => {
            isSubscribed = false;
        };
    }, []);

    const onSignOutFreshbooks = () => {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        setIsFreshbooksAuthenticated(false);
    };

    if (!isFreshbooksAuthenticated) {
        return (
            <Grid
                container
                item
                xs
                justify="center"
                className={classes.errorRoot}
                direction="column"
            >
                <Button onClick={authenticateFreshbooks}>
                    Sign in to Freshbooks
                </Button>
            </Grid>
        );
    }

    return (
        <Grid container className={classes.root}>
            <Grid item container justify="flex-end">
                <Button onClick={onSignOutFreshbooks}>
                    Sign out of Freshbooks
                </Button>
            </Grid>
            <InvoiceList onError={onSignOutFreshbooks} />
        </Grid>
    );
}

function InvoiceList({ onError }: { onError: () => void }) {
    const classes = useStyles();
    const acuityClients = useSelector(clientsList);
    const acuityClientsLoaded = isLoaded(acuityClients);
    useFirestoreConnect(() => [{ collection: CLIENTS_COLLECTION }]);

    const [fbMe, setFbMe] = React.useState<any>(null);
    const [error, setError] = React.useState<string | null>(null);
    const [accountId, setAccountId] = React.useState<string | null>(null);
    const [freshbooksClients, setFreshbooksClients] = React.useState<
        FreshbooksClientDTO[] | null
    >(null);
    const [invoiceUploadStatuses, setInvoiceUploadsStatuses] = React.useState<
        Record<string, InvoiceUploadStatus>
    >({});
    const [pageStatus, setPageStatus] = React.useState(PageStatus.Idle);
    const [gatewayName, setGatewayName] = React.useState<string>();

    const now = new Date();
    const storedStart = Number.parseInt(
        localStorage.getItem('invoiceStartDate') || '',
        10,
    );
    const storedEnd = Number.parseInt(
        localStorage.getItem('invoiceEndDate') || '',
        10,
    );
    const startDate = Number.isFinite(storedStart)
        ? new Date(storedStart)
        : getMonthStart(now);
    const endDate = Number.isFinite(storedEnd)
        ? new Date(storedEnd)
        : getMonthEnd(now);

    const { appointmentsWithCosts, isLoading } = useCalculatedAppointments(
        startDate,
        endDate,
        true,
    );
    // const { appointmentsWithCosts: appointments, isLoading } = { appointmentsWithCosts: testData, isLoading: false }; // for testing without calls to Firestore

    // exclude postponed appointments when generating invoices
    const appointments = React.useMemo(() => {
        return appointmentsWithCosts.filter(
            (appointment) => !isAppointmentPostponed(appointment, endDate),
        );
    }, [appointmentsWithCosts]);

    const groupedAppointmentsByClientName = React.useMemo(
        () =>
            appointments.reduce(
                (acc: Dictionary<Appointment[]>, appointment) => {
                    if (!appointment) {
                        return acc;
                    }

                    const clientName = appointment.displayName || 'NO NAME';
                    const existingAppointments = acc[clientName] || [];
                    return {
                        ...acc,
                        [clientName]: [...existingAppointments, appointment],
                    } as Dictionary<Appointment[]>;
                },
                {},
            ),
        [appointments],
    );

    const feesTable = getFeesTable(appointments);
    const grandTotal = React.useMemo(
        () =>
            appointments.reduce((acc: number, appointment) => {
                if (
                    isEmpty(appointment) ||
                    appointment.roleType === RoleType.lead
                ) {
                    return acc;
                }
                const includeAdditionalFees =
                    feesTable[appointment.id] === appointment;
                return (
                    acc + getAppointmentCost(appointment, includeAdditionalFees)
                );
            }, 0),
        [appointments],
    );

    const TAX_RATE = 0.05;
    const formattedGrandTotal = formatCost(grandTotal);
    const formattedTax = formatCost(grandTotal * TAX_RATE);
    const formattedGrandTotalWithTax = formatCost(grandTotal * (1 + TAX_RATE));

    useBeforeunload((event: any) => {
        if (pageStatus) {
            event.preventDefault();
        }
    });

    React.useEffect(() => {
        getFbMe()
            .then(async (result) => {
                const { response } = await result.json();
                setFbMe(response);
                const { subscription_statuses = {} } = response;
                const activeAccount =
                    Object.entries(subscription_statuses).find(
                        ([_, accountStatus]) => {
                            return (
                                accountStatus === 'active' ||
                                accountStatus === 'active_trial'
                            );
                        },
                    ) || [];
                const [accountKey] = activeAccount;
                if (accountKey) {
                    setAccountId(accountKey);
                } else {
                    setError('Failed to load account subscription.');
                }
            })
            .catch((reason) => {
                setError(`Failed to load your account details: ${reason}`);
                onError();
            });
    }, []);

    React.useEffect(() => {
        if (isEmpty(accountId)) {
            return;
        }

        getFbClients(accountId!)
            .then((clients: FreshbooksClientDTO[]) => {
                setFreshbooksClients(clients);
            })
            .catch((reason: string) => {
                setError(`Failed to load client details: ${reason}`);
            });

        // get payment options
        getPaymentOptions(accountId!, auth).then((paymentOptions) => {
            const {
                payment_options: { gateway_name },
            } = paymentOptions;
            console.debug(paymentOptions, gatewayName);
            setGatewayName(gateway_name);
        });
    }, [accountId]);

    if (!isEmpty(error)) {
        return <Typography>{error}</Typography>;
    }

    if (!accountId) {
        return <Spinner message="Loading your profile..." />;
    }

    if (!acuityClientsLoaded) {
        return <Spinner message="Loading Acuity client details..." />;
    }

    if (!freshbooksClients) {
        return <Spinner message="Loading Freshbooks client details..." />;
    }

    if (isLoading || !appointments) {
        return <Spinner message="Loading appointments..." />;
    }

    const onUploadToFreshbooks = async () => {
        setInvoiceUploadsStatuses({});
        console.log('uploading invoices', appointments);
        const invoices = generateInvoices(
            appointments,
            freshbooksClients!,
            acuityClients,
        );
        setInvoiceUploadsStatuses(
            Object.keys(invoices).reduce((acc, clientEmail) => {
                return {
                    ...acc,
                    [clientEmail]: InvoiceUploadStatus.Pending,
                };
            }, {}),
        );
        const auth = getFbAuth();

        console.log('FB invoices', invoices);
        console.log(Object.keys(invoices).length);

        const chainedInvoiceUploads: Promise<any> = Object.entries(
            invoices,
        ).reduce(
            async (
                prevOrderPromise: Promise<any>,
                [clientEmail, invoice],
                index,
            ) => {
                console.debug('Starting invoice upload', index);
                await prevOrderPromise;
                console.debug(`Invoice upload ${index} ended`);

                try {
                    const response = await uploadToFreshbooks(
                        accountId,
                        invoice,
                        auth,
                    );

                    const { status, statusText } = response as Response;
                    console.debug('status', status, statusText);
                    const result = await response.json();
                    console.debug('result', result);
                    if (status < 400) {
                        const invoiceId = result?.response?.result?.invoice?.id;

                        console.debug(invoiceId, gatewayName);
                        if (gatewayName) {
                            const paymentsOptionsResult =
                                await setPaymentOptionsForInvoice(
                                    accountId,
                                    invoiceId,
                                    auth,
                                    gatewayName,
                                );
                            if (paymentsOptionsResult.status >= 400) {
                                throw new Error(
                                    `Failed to set the payment options on invoice #${invoiceId}`,
                                );
                            }
                        } else {
                            console.warn(
                                'Missing gateway information',
                                gatewayName,
                                invoiceId,
                            );
                        }

                        setInvoiceUploadsStatuses((prev) => ({
                            ...prev,
                            [clientEmail]: InvoiceUploadStatus.Success,
                        }));
                    } else {
                        const _result = await response.json();
                        const errorMessage =
                            _result?.response?.errors?.[0]?.message ??
                            'Unknown Error';
                        throw new Error(errorMessage);
                    }
                } catch (_reason: unknown) {
                    setInvoiceUploadsStatuses((prev_1) => ({
                        ...prev_1,
                        [clientEmail]: InvoiceUploadStatus.Error,
                    }));
                }
            },
            Promise.resolve(),
        );

        setPageStatus(PageStatus.Idle);

        const [firstAppointment] = appointments;
        const acuityClient = getAcuityClientFromAppointment(
            acuityClients,
            firstAppointment,
        );
        const freshbooksClient = findMatchingFreshbooksClient(
            acuityClient,
            freshbooksClients,
        );

        if (!freshbooksClient?.id) {
            toast.error(
                'Failed to find a matching Freshbooks client, could not connect to Freshbooks.',
            );
            return;
        }
        if (!gatewayName) {
            toast.error(
                'Failed to find a valid payment gateway, could not connect to Freshbooks.',
            );
            return;
        }

        setPageStatus(PageStatus.Uploading);
        return chainedInvoiceUploads.then(async () => {
            setPageStatus(PageStatus.SettingPaymentOptions);
            await ensureInvoicesHavePaymentOptions(
                accountId,
                freshbooksClient.id,
                auth,
                gatewayName,
            );

            setPageStatus(PageStatus.UploadComplete);
        });
    };

    // const accountIdDisplay = accountId === 'AQE5kQ' ? `${accountId}: DEV [ALL GOOD]` : `${accountId}: WARNING PRODUCTION [BE CAREFUL]`;

    const numAppointmentMismatches = Object.entries(
        groupedAppointmentsByClientName,
    ).reduce((acc, [, clientAppointments]) => {
        const clientAppointmentMismatches = clientAppointments.reduce(
            (mistmatchesForClient, clientAppointment) => {
                const freshbooksMatch = appointmentMatch(
                    freshbooksClients,
                    acuityClients,
                    clientAppointment,
                );
                if (freshbooksMatch) {
                    return mistmatchesForClient;
                }

                return mistmatchesForClient + 1;
            },
            0,
        );

        return acc + clientAppointmentMismatches;
    }, 0);
    const hasMismatches = numAppointmentMismatches > 0;

    const numClientMismatches = Object.entries(
        groupedAppointmentsByClientName,
    ).reduce((acc, [, clientAppointments]) => {
        const clientMismatch = clientAppointments.some((appointment) => {
            const freshbooksMatch = appointmentMatch(
                freshbooksClients,
                acuityClients,
                appointment,
            );
            return freshbooksMatch === undefined;
        });

        if (clientMismatch) {
            return acc + 1;
        }
        return acc;
    }, 0);

    const auth = getFbAuth();

    return (
        <Grid>
            {pageStatus === PageStatus.Uploading && (
                <>
                    <Typography style={{ color: 'red' }}>
                        UPLOADING INVOICES, DO NOT LEAVE THIS PAGE
                    </Typography>
                    <UploadProgressBar
                        invoiceUploadStatuses={invoiceUploadStatuses}
                        pageStatus={pageStatus}
                    />
                </>
            )}
            {/* TODO: Add uploads status message */}
            {/* {uploadsComplete && (
                <Typography style={{ color: 'green' }}>
                    {uploadsCompleteMessage}
                </Typography>
            )} */}
            <Typography
                variant="h5"
                onClick={() =>
                    onDebugInfo(
                        appointments,
                        fbMe,
                        acuityClients,
                        freshbooksClients,
                    )
                }
            >
                {`Overview - ${formattedGrandTotal} + ${formattedTax} GST = ${formattedGrandTotalWithTax}`}
            </Typography>
            {hasMismatches && (
                <Typography className={classes.errorStatus}>
                    {`${numClientMismatches} of ${
                        Object.values(groupedAppointmentsByClientName).length
                    } clients could not be matched to Freshbooks. Please fix these mismatches before attempting to generate Freshbooks invoices.`}
                </Typography>
            )}
            {!hasMismatches && (
                <ButtonWithConfirmation
                    buttonText="Upload Invoices"
                    dialogTitle="Upload Invoices to Freshbooks?"
                    dialogText="Are you sure you want to upload invoices to Freshbooks? This cannot be undone."
                    confirmText="Upload"
                    onConfirm={onUploadToFreshbooks}
                    startIcon={<Publish />}
                />
            )}
            {/* <Typography>{`Account ID: ${accountIdDisplay}`}</Typography> */}
            <Typography>{`Invoice Period: ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}</Typography>

            <hr />
            <Typography variant="h5">Invoices</Typography>
            {sortBy(
                Object.entries(groupedAppointmentsByClientName),
                (clientAppointments) => clientAppointments?.[0]?.toLowerCase(),
            ).map(([clientName, clientAppointments]) => {
                const props = {
                    clientName,
                    appointments: clientAppointments,
                    freshbooksClients,
                    uploadStatus:
                        invoiceUploadStatuses?.[clientName] ||
                        InvoiceUploadStatus.None,
                };
                return (
                    <ClientSummaryRow
                        {...props}
                        key={`${clientName}`}
                        auth={auth}
                        accountId={accountId}
                    />
                );
            })}
        </Grid>
    );
}

export default Freshbooks;
