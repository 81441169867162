import { makeStyles, createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            width: '100%',
            marginBottom: 0,
        },
        clientsListPanel: {
            height: '100%',
            background: `rgba(255, 255, 255, 0.9)`,
            padding: theme.spacing(0.5),
            paddingTop: theme.spacing(2),
            margin: `${theme.spacing(2)}px 0px`,
        },
        clientsListPanelHeaderContainer: {
            height: theme.spacing(8),
            padding: theme.spacing(2),
            width: '100%',
        },
        clientsListTitle: {
            fontWeight: theme.typography.fontWeightBold,
        },
        errorMessage: {
            color: 'red',
            fontSize: 14,
        },
        clientsListContainer: {
            height: `calc(100% - ${theme.spacing(25)}px)`,
            width: '100%',
            padding: theme.spacing(),
            overflowX: 'hidden',
            overflowY: 'auto',
            textOverflow: 'ellipsis',
        },
        isMissingFreshbooksMatch: {
            backgroundColor: theme.palette.error.light,
            '&:hover': {
                backgroundColor: theme.palette.error.dark,
            },
        },
        clientDetailsPanelContainer: {
            height: '100%',
            margin: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
            padding: theme.spacing(2),
            textOverflow: 'ellipsis',
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            background: `rgba(255, 255, 255, 0.9)`,
        },
        clientDetailsContents: {
            padding: theme.spacing(2),
        },
        formRoot: {
            width: '100%',
        },
        formColumn: {
            width: '100%',
            padding: theme.spacing(2),
            overflow: 'hidden',
        },
        sectionHeader: {
            padding: `${theme.spacing()}px 0`,
        },
        textFieldContainer: {
            paddingLeft: theme.spacing(),
        },
        inputLabel: {
            position: 'relative',
            transform: 'none',
            paddingRight: theme.spacing(),
            width: 150,
        },
        textField: {
            marginTop: theme.spacing(0.5),
            marginLeft: theme.spacing(),
        },
        fieldError: {
            color: 'red',
            paddingLeft: theme.spacing(2),
        },
        updateButtonContainer: {
            padding: theme.spacing(2),
        },
        formButton: {
            margin: theme.spacing(2),
        },
        formTextContainer: {
            paddingBottom: theme.spacing(2),
        },
        formTextTitle: {
            fontWeight: theme.typography.fontWeightBold,
        },
        formTextValue: {
            paddingLeft: theme.spacing(),
        },
        billingEmailContainer: {
            paddingLeft: theme.spacing(2),
        },
        title: {
            paddingLeft: theme.spacing(2),
        },
        tooltip: {
            fontSize: theme.spacing(2),
        },
        inlineTooltip: {
            padding: 0,
            paddingLeft: theme.spacing(),
        },
        freshbooksNoMatchMessage: {
            color: theme.palette.error.main,
            whiteSpace: 'pre',
        },
        freshbooksSelectorAutocomplete: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(),
        },
        radioGroup: {
            paddingLeft: theme.spacing(3),
            paddingTop: theme.spacing(2)
        },
        radioOption: {
            paddingLeft: theme.spacing()
        },
        radioOptionLabel: {
            paddingLeft: theme.spacing()
        }
    });

export const useStyles = makeStyles(styles);
