import { Grid, LinearProgress, Typography } from '@material-ui/core';
import React from 'react';

import { InvoiceUploadStatus, PageStatus } from './types';

interface Props {
    invoiceUploadStatuses: Record<string, string>;
    pageStatus: PageStatus;
}

export function UploadProgressBar({
    invoiceUploadStatuses,
    pageStatus,
}: Props) {
    const { numCompletedUploads, numErrors } = React.useMemo(
        () =>
            Object.values(invoiceUploadStatuses).reduce(
                (acc, invoiceUpload) => {
                    if (invoiceUpload === InvoiceUploadStatus.Success) {
                        return {
                            ...acc,
                            numCompletedUploads: acc.numCompletedUploads + 1,
                        };
                    }
                    if (invoiceUpload === InvoiceUploadStatus.Error) {
                        return {
                            ...acc,
                            numErrors: acc.numErrors + 1,
                        };
                    }
                    return acc;
                },
                { numCompletedUploads: 0, numErrors: 0 },
            ),
        [invoiceUploadStatuses],
    );
    const numTotalUploads = Object.keys(invoiceUploadStatuses).length;

    let statusText = '';
    switch (pageStatus) {
        case PageStatus.Uploading:
            statusText = `Uploading (${numCompletedUploads}/${numTotalUploads})`;
            break;
        case PageStatus.SettingPaymentOptions:
            statusText = 'Setting Payment Options on each invoice';
            break;
        case PageStatus.UploadComplete:
            statusText = `Uploads Complete (${numCompletedUploads} uploaded, ${numErrors} errors)`;
            break;
    }

    return (
        <Grid direction="column">
            <Typography>{statusText}</Typography>
            <LinearProgress
                value={Math.max(
                    0,
                    Math.min(
                        100,
                        Math.round(
                            (numCompletedUploads / numTotalUploads) * 100,
                        ),
                    ),
                )}
                variant="determinate"
            />
        </Grid>
    );
}
