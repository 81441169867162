import { DocumentReference, Timestamp } from '@google-cloud/firestore';
import { FirebaseReducer } from 'react-redux-firebase';

import { MatchMethod } from '../components/ClientManagement/ClientManage.types';
import { RoleType } from './frontend';

export type CalendarDTO = {
    appointments: DocumentReference[];
    createdAt: Timestamp;
    description?: string;
    email?: string;
    id?: number;
    image?: boolean;
    location?: string;
    name?: string;
    replyTo?: string;
    thumbnail?: boolean;
    timezone?: string;
};

export type Role = {
    name: string;
    optional?: boolean;
};

export type Conversion = {
    legacyRoleRates?: {
        [roleName: string]: string;
    };
    name: string;
    rates: {
        [roleId: string]: string;
    };
    addon?: string;
};

export type CalendarRoleMap = {
    [staffId: string]: {
        roleId: string;
        roleType?: RoleType;
        optional?: boolean;
    };
};

export type CalendarRoleMapObject = {
    roles: CalendarRoleMap;
};

export type APIRateTab = APIFlatRateTab | APIAreaRateTab | APIHourlyRateTab;
export type APIFlatRateTab = Partial<FlatRateTab>;
export type APIAreaRateTab = Partial<AreaRateTab>;
export type APIHourlyRateTab = Partial<HourlyRateTab>;

export type APIFlatRetailSection = Partial<FlatRetailSection>;
export type APIFlatPayoutSection = Partial<FlatPayoutSection>;
export type APIAreaRetailSection = Partial<AreaRetailSection>;
export type APIAreaPayoutSection = Partial<AreaPayoutSection>;
export type APIHourlyRetailSection = Partial<HourlyRetailSection>;
export type APIHourlyPayoutSection = Partial<HourlyPayoutSection>;

export enum TAB_TYPE {
    FLAT = 'flat',
    AREA = 'area',
    HOURLY = 'hourly',
}

export type RateObject = {
    meta: {
        label: string;
        type: {
            [typeName: string]: boolean;
        };
        description: string;
        prefDiscount: number;
        prefDiscountDollar: number;
        legacyRole?: string;
    };
    rates: {
        [TAB_TYPE.FLAT]?: FlatRateTab;
        [TAB_TYPE.AREA]?: AreaRateTab;
        [TAB_TYPE.HOURLY]?: HourlyRateTab;
    };
};

export type RateTabObject = FlatRateTab | AreaRateTab | HourlyRateTab;

export type FlatRateTab = {
    retail: FlatRetailSection;
    payout: FlatPayoutSection;
};

export type AreaRateTab = {
    retail: AreaRetailSection;
    payout: AreaPayoutSection;
};

export type HourlyRateTab = {
    retail: HourlyRetailSection;
    payout: HourlyPayoutSection;
};

export type RateSectionObject =
    | FlatRetailSection
    | FlatPayoutSection
    | AreaRetailSection
    | AreaPayoutSection
    | HourlyRetailSection
    | HourlyPayoutSection;

export type RateLineObject = {
    [key: string]: number;
};

export type FlatRetailSection = {
    retail: {
        amount?: number;
        adjust1?: number;
        adjust2?: number;
        included?: number;
    };
};

export type FlatPayoutSection = {
    lead: {
        amount?: number;
        adjust1?: number;
        adjust2?: number;
    };
    team: {
        amount?: number;
        adjust1?: number;
        adjust2?: number;
    };
    leadBonus: {
        amount?: number;
        adjust1?: number;
        adjust2?: number;
    };
    supportStaff: {
        amount?: number;
        adjust1?: number;
        adjust2?: number;
    };
};

export type AreaRetailSection = {
    minimumCharge: {
        amount?: number;
        psf1?: number;
        psf2?: number;
        minChargeMaxArea?: number | null;
        includePsf2InMinCharge?: boolean;
    };
};

export type AreaPayoutSection = {
    lead: {
        amount: number;
        psf1?: number;
        psf2?: number;
    };
    team: {
        amount: number;
        psf1?: number;
        psf2?: number;
    };
    leadBonus: {
        amount?: number;
        psf1?: number;
        psf2?: number;
    };
    supportStaff: {
        amount?: number;
        psf1?: number;
        psf2?: number;
    };
};

export type HourlyRetailSection = {
    minimumCharge: {
        amount?: number;
        rate1?: number;
        rate2?: number;
    };
};

export type HourlyPayoutSection = {
    lead: {
        amount?: number;
        rate1?: number;
        rate2?: number;
    };
    team: {
        amount?: number;
        rate1?: number;
        rate2?: number;
    };
    leadBonus: {
        amount?: number;
        rate1?: number;
        rate2?: number;
    };
    supportStaff: {
        amount?: number;
        rate1?: number;
        rate?: number;
    };
};

export type AppointmentDTO = {
    displayName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    certificate?: string | null;
    price: string;
    priceSold: string;
    calendar: string;
    datetime: Timestamp;
    postponedDate: Timestamp;
    time: string;
    duration: string;
    type: string;
    approved: boolean;
    calendarID: number;
    id: number;
    formFields: {
        hoursOne?: string;
        hoursTwo?: string;
        numImages?: string;
        sqft?: string;
        sqftAux?: string;
        address?: string;
        shouldChargeTravelFee?: boolean;
        travelFee?: string;
        overnightFee?: string;
        discountAmount?: string;
        discountPercent?: string;
        discountReason?: string;
    };
    notes: string;
};

export type UserDTO = UserRestrictedFields & {
    firstName: string;
    lastName: string;
    gstNumber: string;
    streetAddress: string;
    streetAddressLineTwo: string;
    city: string;
    province: string;
    postalCode: string;
    country: string;
};

export type UserRestrictedFields = {
    admin: boolean;
};

export type AppState = {
    firestore: FirebaseReducer.Reducer;
};

export type LeadDTO = {
    lead: string;
};

export type ClientDTO = {
    displayName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    notes: string;
    isPreferred: boolean;
    useEmailForBilling: boolean;
    billingEmail?: string;
    matchEmailOnly?: boolean; // deprecated
    matchMethod?: MatchMethod;
};

export type UserInvoicesDTO = {
    invoices: InvoiceDTO[];
    userRef: string;
    userId: string;
};

export type InvoiceDTO = {
    datetime: Timestamp;
    invoiceUrl: string;
};

export type UserExpensesDTO = {
    expenses: ExpenseDTO[];
    userRef: string;
    userId: string;
};

export type ExpenseDTO = {
    datetime: Timestamp | Date;
    amount: number;
    description: string;
    gst?: boolean;
    id: string;
};
