import { Dictionary } from 'react-redux-firebase';
import { ClientDTO, FreshbooksClientDTO } from '../../types';

export interface ClientListPanelProps {
    clients: Dictionary<ClientDTO>;
    selectedClientId: string;
    onSelect: (selectedId: string) => void;
    freshbooksClients: FreshbooksClientDTO[] | null;
}

export interface ClientDetailsPanelProps {
    clients: Dictionary<ClientDTO>;
    clientId: string;
    freshbooksClients: FreshbooksClientDTO[] | null;
    isFreshbooksAuthenticated: boolean;
    handleFreshbooksSignout: () => void;
}

export interface FreshbooksClientMatcherProps {
    acuityClient: ClientDTO;
    clientId: string;
    freshbooksClients: FreshbooksClientDTO[] | null;
    isFreshbooksAuthenticated: boolean;
}

export interface FreshbooksClientSelectorProps {
    freshbooksClients: FreshbooksClientDTO[] | null;
    onClientSelected: (client: FreshbooksClientDTO) => void;
}

export enum MatchMethod {
    EmailAndName='EmailAndName',
    EmailOnly='EmailOnly',
    NameOnly='NameOnly'
}
