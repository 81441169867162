import React from 'react';
import { isEmpty } from 'lodash-es';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { useStyles } from './Spinner.styles';

type SpinnerProps = {
    message?: string;
};

export const Spinner = ({ message = '' }: SpinnerProps) => {
    const classes = useStyles();
    return (
        <Grid container item xs justify="center" className={classes.root} direction="column">
            <CircularProgress />
            {!isEmpty(message) && (
                <Typography className={classes.message} variant="body1">
                    {message}
                </Typography>
            )}
        </Grid>
    );
};
