import { createStyles, makeStyles } from '@material-ui/core';
import { Router } from '@reach/router';
import React from 'react';
import { Toaster } from 'react-hot-toast';

import { Layout } from '../components';
import { Appointments } from './components/Appointments';
import { CalendarManagement } from './components/CalendarManagement';
import { ClientManagement } from './components/ClientManagement';
import { ConversionManagement } from './components/ConversionManagement';
import { Expenses } from './components/Expenses';
import { Freshbooks } from './components/Freshbooks';
import { Invoices } from './components/Invoices';
import { LeadManagement } from './components/LeadManagement';
import { Login } from './components/Login';
import { Main } from './components/Main';
import { NavBar } from './components/NavBar';
import { PrivateRoute } from './components/PrivateRoute';
import { Profile } from './components/Profile';
import { RateManagement } from './components/RateManagement';
import { Review } from './components/Review';
import { RoleManagement } from './components/RoleManagement';
import FirebaseContext from './context';

const App = () => {
    const classes = useStyles();
    return (
        <Layout>
            <FirebaseContext>
                <NavBar />
                <Toaster position="top-center" reverseOrder={false} />
                <Router className={classes.root}>
                    <PublicRoute path="/app">
                        <PrivateRoute path="/home" component={Main} />
                        <Login path="/login" />
                    </PublicRoute>
                    <PrivateRoute path="/app/profile" component={Profile} />
                    <PrivateRoute
                        path="/app/appointments"
                        component={Appointments}
                    />
                    <PrivateRoute path="/app/expenses" component={Expenses} />
                    <PrivateRoute
                        adminOnly
                        path="/app/review"
                        component={Review}
                    />
                    <PrivateRoute
                        adminOnly
                        path="/app/calendars"
                        component={CalendarManagement}
                    />
                    <PrivateRoute
                        adminOnly
                        path="/app/leads"
                        component={LeadManagement}
                    />
                    <PrivateRoute
                        adminOnly
                        path="/app/rates"
                        component={RateManagement}
                    />
                    <PrivateRoute
                        adminOnly
                        path="/app/conversions"
                        component={ConversionManagement}
                    />
                    <PrivateRoute
                        adminOnly
                        path="/app/clients"
                        component={ClientManagement}
                    />
                    <PrivateRoute
                        adminOnly
                        path="/app/roles"
                        component={RoleManagement}
                    />
                    <PrivateRoute
                        adminOnly
                        path="/app/freshbooks"
                        component={Freshbooks}
                    />
                    <PrivateRoute
                        adminOnly
                        path="/app/invoices"
                        component={Invoices}
                    />
                </Router>
            </FirebaseContext>
        </Layout>
    );
};

function PublicRoute(props: any) {
    return <div>{props.children}</div>;
}

const styles = () =>
    createStyles({
        root: {
            height: '95%',
        },
    });

export const useStyles = makeStyles(styles);

export default App;
