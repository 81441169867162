import React from 'react';
import { navigate } from 'gatsby';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { USERS_COLLECTION } from '../../constants';
import { PrivateRouteProps } from './PrivateRoute.types';
import { firebaseAuth, userById, isAdminUser } from '../../store/selectors';
import Spinner from '../Spinner';

const PrivateRoute = ({ component: Component, location, adminOnly = false, ...rest }: PrivateRouteProps) => {
    const auth = useSelector(firebaseAuth);
    const user = useSelector(userById(auth.uid));
    const isAdmin = useSelector(isAdminUser(auth?.uid));
    const authLoaded = isLoaded(auth);
    const userLoaded = isLoaded(user);

    useFirestoreConnect(() => [
        {
            collection: USERS_COLLECTION,
            doc: auth.uid,
        },
    ]);

    const isLoggedIn = authLoaded && !isEmpty(auth);
    const isAdminAuth = !adminOnly || isAdmin;
    const isAuthorized = isLoggedIn && isAdminAuth;

    React.useEffect(() => {
        if (isLoaded(auth) && !isLoggedIn && location?.pathname !== '/app/login') {
            // If the user is not logged in, redirect to the login page.
            navigate(`/app/login?redirect=${location?.pathname}`);
        }
    }, [isLoggedIn, location, auth]);

    if (!authLoaded || !userLoaded) {
        return <Spinner message="Authorizing..." />;
    }

    if (isAuthorized) {
        return <Component {...rest} />;
    }

    if (isLoggedIn && userLoaded) {
        return <div>Unauthorized.</div>;
    }

    return null;
};

export default PrivateRoute;
