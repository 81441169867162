import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: `${theme.spacing(2)}px 0px`,
            background: `rgba(255, 255, 255, 0.9)`,
            padding: theme.spacing(2),
        },
        formRoot: {
            width: '100%',
        },
        formColumn: {
            width: '100%',
            padding: `0px ${theme.spacing(2)}px`,
            overflow: 'hidden',
        },
        sectionHeader: {
            padding: `${theme.spacing()}px 0`,
        },
        textFieldContainer: {
            paddingLeft: theme.spacing(),
        },
        inputLabel: {
            position: 'relative',
            transform: 'none',
            paddingRight: theme.spacing(),
            width: 150,
        },
        textField: {
            width: `calc(100% - 150px)`,
        },
        fieldError: {
            color: 'red',
            paddingLeft: 160,
        },
        updateButtonContainer: {
            padding: theme.spacing(2),
        },
        formButton: {
            margin: theme.spacing(2),
        },
    });

export const useStyles = makeStyles(styles);
