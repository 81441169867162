const fbConfig = {
    apiKey: 'AIzaSyColL1638h7iejGTOcK32EiwAM1P0ud4RI',
    authDomain: 'cp-accounting.firebaseapp.com',
    databaseURL: 'https://cp-accounting.firebaseio.com',
    projectId: 'cp-accounting',
    storageBucket: 'cp-accounting.appspot.com',
    messagingSenderId: '934032263802',
    appId: '1:934032263802:web:ee9ed1900f07b28c6bc9c3',
    measurementId: 'G-CBT6F5E8WF',
};

export default fbConfig;
