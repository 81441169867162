import React from 'react';
import { Link } from 'gatsby';
import { Grid, Typography } from '@material-ui/core';
import { PageTitle } from '../PageTitle';
import { useStyles } from './Main.styles';

function Main() {
    const classes = useStyles();

    return (
        <Grid container direction="column" className={classes.root}>
            <PageTitle title="Home" />
            <Typography>Welcome!</Typography>
            <Typography>
                Please ensure your{' '}
                <Link to="/app/profile">
                    <b>Profile</b>
                </Link>{' '}
                is up to date, including the GST number. Your profile will be used to generate your invoices.
            </Typography>
            <Typography>
                You can review your{' '}
                <Link to="/app/appointments">
                    <b>Appointments</b>
                </Link>{' '}
                anytime. Once you have approved all the appointments for the month, you will be able to generate the invoice.
            </Typography>
        </Grid>
    );
}

export default Main;
