import { Appointment } from '../types';

export function sortAppointments(appointments: Appointment[]) {
    const sorted = appointments.sort((a, b) => {
        return (a?.datetime?.toMillis() ?? 0) - (b?.datetime?.toMillis() ?? 0);
    });
    return sorted;
}

export function sortGroupedAppointments(
    groupedAppointments: Record<string, Appointment[]>,
) {
    const sortedEntries = Object.entries(groupedAppointments).sort((a, b) => {
        const [, aAppointments] = a;
        const [, bAppointments] = b;

        const aValue = aAppointments?.[0]?.datetime?.toMillis() ?? 0;
        const bValue = bAppointments?.[0]?.datetime?.toMillis() ?? 0;

        return aValue - bValue;
    });
    return sortedEntries;
    // const sortedGroupedAppointments = sortedEntries.reduce(
    //     (acc, [key, appointments]) => {
    //         return {
    //             ...acc,
    //             [key]: appointments,
    //         };
    //     },
    //     {} as Record<string, Appointment>,
    // );

    // return sortedGroupedAppointments;
}
