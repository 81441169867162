import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
        },
        title: {
            paddingLeft: theme.spacing(2),
        },
        ratesListPanel: {
            height: '100%',
            background: `rgba(255, 255, 255, 0.9)`,
            padding: theme.spacing(0.5),
            paddingTop: theme.spacing(2),
            margin: `${theme.spacing(2)}px 0px`,
        },
        newRateButtonContainer: {
            padding: theme.spacing(2),
            width: '100%',
        },
        ratesListTitle: {
            fontWeight: theme.typography.fontWeightBold,
        },
        newRateButton: {
            color: 'white',
            backgroundColor: 'green',
            maxHeight: theme.spacing(4),
        },
        addDialogMessage: {
            color: 'red',
        },
        ratesListContainer: {
            height: `calc(100% - ${theme.spacing(25)}px)`,
            width: '100%',
            padding: theme.spacing(),
            overflowX: 'hidden',
            overflowY: 'auto',
            textOverflow: 'ellipsis',
        },
        rateDetailsPanelContainer: {
            height: '100%',
            margin: `${theme.spacing(2)}px 0px ${theme.spacing(
                2,
            )}px ${theme.spacing(2)}px`,
            textOverflow: 'ellipsis',
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            background: `rgba(255, 255, 255, 0.9) content-box`,
        },
        rateDetailsContents: {
            padding: theme.spacing(2),
        },
        actionBar: {
            height: theme.spacing(6),
            width: '100%',
        },
        editNameDialog: {
            minWidth: 600,
        },
        rateTitle: {
            textOverflow: 'ellipsis',
        },
        rateTabTitle: {
            padding: `${theme.spacing()}px 0`,
        },
        rateSectionsContainer: {
            paddingLeft: theme.spacing(2),
        },
        sectionTitle: {
            fontStyle: 'italic',
            textDecoration: 'underline',
        },
        rateLinesContainer: {
            paddingLeft: theme.spacing(2),
        },
        rateLine: {
            padding: `${theme.spacing()}px 0`,
        },
        rateLabel: {
            fontSize: 14,
            fontWeight: theme.typography.fontWeightBold,
            paddingTop: theme.spacing(2),
        },
        valueContainer: {
            padding: theme.spacing(),
        },
        valueLabel: {
            fontSize: 12,
            fontWeight: theme.typography.fontWeightBold,
        },
        valueTextField: {
            fontSize: 12,
        },
        rateSelectionRoot: {
            display: 'flex',
        },
        formControl: {
            margin: theme.spacing(3),
        },
        saveButtonContainer: {
            paddingTop: theme.spacing(5),
        },
        noSelectionMessage: {
            background: 'rgba(255, 255, 255, 0.9) padding-box',
        },
    });

export const useStyles = makeStyles(styles);
