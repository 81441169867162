import ClientOAuth2 from 'client-oauth2';
import { isEmpty } from 'lodash-es';
import { Dictionary } from 'react-redux-firebase';

import { FRESHBOOKS_DEV, FRESHBOOKS_PROD } from '../../../../freshbooks-config';
import { FRESHBOOKS_ACCESS_TOKEN_URI } from '../../config';
import {
    Appointment,
    ClientDTO,
    FreshbooksClientDTO,
    NewFreshbooksInvoice,
    NewInvoiceLine,
    RoleType,
} from '../../types';
import { getMonthEnd } from '../Appointments';
import { formatCost, formatDate, formatDiscounts } from '../Appointments/utils';
import { appointmentMatch, getAppointmentCost } from './utils';

export const ACCESS_TOKEN_KEY = 'FB_ACCESS_TOKEN';
export const REFRESH_TOKEN_KEY = 'FB_REFRESH_TOKEN';

const IS_DEV = process.env.GATSBY_ENV === 'DEV';
const FRESHBOOKS_CONFIG = IS_DEV ? FRESHBOOKS_DEV : FRESHBOOKS_PROD;

export async function getFbMe(auth?: ClientOAuth2): Promise<Response> {
    const fbMeEndpoint = 'https://api.freshbooks.com/auth/api/v1/users/me';
    return makeAuthenticatedRequest(fbMeEndpoint, auth);
}

export async function getFbClients(accountId: string): Promise<any[]> {
    try {
        const auth = getFbAuth();
        const fbClientEndpoint = (page: number) =>
            `https://api.freshbooks.com/accounting/account/${accountId}/users/clients?per_page=100&page=${page}`;

        const firstPage = await makeAuthenticatedRequest(
            fbClientEndpoint(0),
            auth,
        );
        const { response } = await firstPage.json();
        return (response?.result?.clients || []) as any[];
    } catch (error) {
        console.error(error);
        throw new Error('Failed to load clients.');
    }
}

function formatNumImages(numImages?: string) {
    return isEmpty(numImages) ? undefined : `# of Images: ${numImages}`;
}
function formatSqft(sqft?: string, sqftAux?: string) {
    if (isEmpty(sqft) && isEmpty(sqftAux)) {
        return undefined;
    }
    const totalSqft =
        Number.parseFloat(sqft || '0') + Number.parseFloat(sqftAux || '0');
    return `${sqft} RMS + ${sqftAux} Aux = ${totalSqft} sqft`;
}
function formatAdditional(
    shouldChargeClient = false,
    travelFee: string | undefined,
    overnightFee: string | undefined,
) {
    if (!shouldChargeClient) {
        return undefined;
    }
    const parsedTravelFee = Number.parseFloat(travelFee || '0');
    const parsedOvernightFee = Number.parseFloat(overnightFee || '0');
    const travelFeePart = travelFee
        ? `Travel Fee: ${formatCost(parsedTravelFee)} `
        : '';
    const overnightFeePart = overnightFee
        ? `Overnight Fee: ${formatCost(parsedOvernightFee)}`
        : '';
    return travelFeePart + overnightFeePart;
}

function concatLine(...texts: (string | undefined)[]) {
    const parts = texts.reduce((acc: string[], text) => {
        if (!isEmpty(text)) {
            acc?.push(text!);
        }
        return acc;
    }, []);
    return parts?.join('\n');
}

export const getPaymentOptions = async (
    accountId: string,
    auth: ClientOAuth2,
) => {
    const paymentsEndpoint = `https://api.freshbooks.com/payments/account/${accountId}/payment_options?entity_type=invoice`;
    const response = await makeAuthenticatedRequest(paymentsEndpoint, auth);
    return await response.json();
};

export const setPaymentOptionsForInvoice = async (
    accountId: string,
    invoiceId: string,
    auth: ClientOAuth2,
    gatewayName: string,
) => {
    const paymentsEndpoint = `https://api.freshbooks.com/payments/account/${accountId}/invoice/${invoiceId}/payment_options`;
    const requestBody = {
        has_credit_card: true,
        gateway_name: gatewayName,
    };
    return await makeAuthenticatedPostRequest(
        paymentsEndpoint,
        auth,
        JSON.stringify(requestBody),
    );
};

export const uploadToFreshbooks = (
    accountId: string,
    invoice: NewFreshbooksInvoice,
    auth: ClientOAuth2,
) => {
    const invoiceEndpoint = `https://api.freshbooks.com/accounting/account/${accountId}/invoices/invoices`;
    const bodyToUpload = JSON.stringify({ invoice });
    const invoicePromise = makeAuthenticatedPostRequest(
        invoiceEndpoint,
        auth,
        bodyToUpload,
    );
    return invoicePromise;
};

export const getInvoicesForCurrentPeriod = (
    accountId: string,
    customerId: number,
    auth: ClientOAuth2,
) => {
    const now = new Date();
    const storedEnd = Number.parseInt(
        localStorage.getItem('invoiceEndDate') || '',
        10,
    );
    const endDate = Number.isFinite(storedEnd)
        ? new Date(storedEnd)
        : getMonthEnd(now);

    const year = endDate.getFullYear();
    const month = (endDate.getMonth() + 1).toString().padStart(2, '0');
    const day = endDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    const invoiceEndpoint = `https://api.freshbooks.com/accounting/account/${accountId}/invoices/invoices?search[customerid]=${customerId}&search[v3_status]=draft&search[date_min]=${formattedDate}&search[date_max]=${formattedDate}`;
    const invoicePromise = makeAuthenticatedRequest(invoiceEndpoint, auth);
    return invoicePromise;
};

export const generateInvoices = (
    appointments: Appointment[],
    freshbooksClients: FreshbooksClientDTO[],
    acuityClients: Dictionary<ClientDTO>,
) => {
    const now = new Date();
    const storedEnd = Number.parseInt(
        localStorage.getItem('invoiceEndDate') || '',
        10,
    );
    const endDate = Number.isFinite(storedEnd)
        ? new Date(storedEnd)
        : getMonthEnd(now);
    const year = endDate.getFullYear();
    const month = (endDate.getMonth() + 1).toString().padStart(2, '0');
    const day = endDate.getDate().toString().padStart(2, '0');

    const feesTable = getFeesTable(appointments);
    const invoices = appointments.reduce(
        (
            acc: Record<string, NewFreshbooksInvoice>,
            appointment: Appointment,
        ) => {
            if (
                isEmpty(appointment) ||
                appointment.roleType === RoleType.lead
            ) {
                return acc;
            }
            const {
                calculations,
                email: acuityEmail,
                formFields = {},
                type,
                rate,
                notes,
                datetime,
            } = appointment;
            const {
                hoursOne,
                hoursTwo,
                numImages,
                sqft,
                sqftAux,
                address,
                shouldChargeTravelFee,
                travelFee,
                overnightFee,
            } = formFields;
            const error = calculations?.error;

            if (error) {
                console.error(`ERROR FOUND, NOT GENERATING INVOICE: ${error}`);
                console.error(appointment);
                return acc;
            }

            const freshbooksMatch = appointmentMatch(
                freshbooksClients,
                acuityClients,
                appointment,
            );
            const { email, id } = freshbooksMatch || {};

            if (!email) {
                console.error(`No client matched for email: ${acuityEmail}`);
                return acc;
            }

            const invoiceClientIdentifier = {
                email,
                customerid: id,
            };

            const { reason } = formatDiscounts(appointment);
            const discountReason = reason ?? '';

            let hoursOneFormatted: string = '',
                hoursTwoFormatted: string = '';
            if (hoursOne) {
                const unit = hoursOne === `1` ? 'hr' : 'hrs';
                hoursOneFormatted = `Shooting: ${hoursOne}${unit}`;
            }
            if (hoursTwo) {
                const unit = hoursTwo === `1` ? 'hr' : 'hrs';
                hoursTwoFormatted = `Editing: ${hoursTwo}${unit}`;
            }

            const includeAdditionalFees =
                feesTable[appointment.id] === appointment;
            const additionalFees = includeAdditionalFees
                ? formatAdditional(
                      shouldChargeTravelFee,
                      travelFee,
                      overnightFee,
                  )
                : '';

            const description = concatLine(
                formatDate(datetime),
                address,
                hoursOneFormatted,
                hoursTwoFormatted,
                formatNumImages(numImages),
                formatSqft(sqft, sqftAux),
                discountReason,
                additionalFees,
                notes,
            );
            const newLine = {
                type: 0, // 0 for normal, 1 for expense
                name: rate?.meta?.label ?? type,
                description,
                qty: 1,
                taxName1: 'GST',
                taxAmount1: 5,
                unit_cost: {
                    amount: getAppointmentCost(
                        appointment,
                        includeAdditionalFees,
                    ).toString(),
                    code: 'CAD',
                },
            } as NewInvoiceLine;

            const existingInvoice = acc?.[email] || {
                ...invoiceClientIdentifier,
                create_date: `${year}-${month}-${day}`,
                lines: [] as NewInvoiceLine[],
                due_offset_days: 30,
                auto_bill: false,
                late_reminders: [
                    {
                        delay: -20,
                        enabled: true,
                        position: 1,
                    },
                    {
                        delay: -10,
                        enabled: true,
                        position: 2,
                    },
                    {
                        delay: -5,
                        enabled: true,
                        position: 3,
                    },
                ],
                late_fee: {
                    calculation_type: 'total',
                    compounded_tax: false,
                    days: 5,
                    enabled: true,
                    first_tax_name: null,
                    first_tax_percent: '0',
                    repeat: false,
                    second_tax_name: null,
                    second_tax_percent: '0',
                    type: 'percent',
                    value: '2',
                },
            };

            return {
                ...acc,
                [email]: {
                    ...existingInvoice,
                    lines: [...existingInvoice.lines, newLine],
                },
            };
        },
        {} as Record<string, NewFreshbooksInvoice>,
    );

    return invoices;
};

export async function makeAuthenticatedRequest(
    endpoint: string,
    auth = getFbAuth(),
) {
    const user = await verifyAuth(auth).catch((reason) => {
        throw new Error(`Failed to authenticate: ${reason}`);
    });

    return fetch(endpoint, {
        headers: new Headers({
            Authorization: `Bearer ${user.accessToken}`,
        }),
    });
}

export async function makeAuthenticatedPostRequest(
    endpoint: string,
    auth = getFbAuth(),
    jsonBody = '',
) {
    const user = await verifyAuth(auth).catch((reason) => {
        throw new Error(`Failed to authenticate: ${reason}`);
    });

    return fetch(endpoint, {
        method: 'POST',
        body: jsonBody,
        headers: new Headers({
            Authorization: `Bearer ${user.accessToken}`,
            'Content-Type': 'application/json',
        }),
    });
}

export async function verifyAuth(
    auth: ClientOAuth2,
): Promise<ClientOAuth2.Token> {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

    const token = auth.createToken(accessToken || '', refreshToken || '', {});
    if (isEmpty(accessToken) || token.expired()) {
        if (isEmpty(refreshToken)) {
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            localStorage.removeItem(REFRESH_TOKEN_KEY);
            throw new Error(`Token expired and no refresh token found`);
        }

        return token
            .refresh()
            .then((updatedUser) => {
                const { accessToken, refreshToken } = updatedUser;
                localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
                localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
                return updatedUser;
            })
            .catch((reason) => {
                localStorage.removeItem(ACCESS_TOKEN_KEY);
                localStorage.removeItem(REFRESH_TOKEN_KEY);
                throw new Error(`Failed to refresh token: ${reason}`);
            });
    }

    return token;
}

export function getFbAuth(redirectUri?: string, authUri?: string) {
    const {
        GATSBY_FRESHBOOKS_AUTH_LINK,
        GATSBY_FRESHBOOKS_CLIENT_ID,
        GATSBY_FRESHBOOKS_REDIRECT_URI,
    } = FRESHBOOKS_CONFIG;
    const fbAuth = new ClientOAuth2({
        clientId: GATSBY_FRESHBOOKS_CLIENT_ID,
        clientSecret: process.env.GATSBY_FRESHBOOKS_CLIENT_SECRET,
        accessTokenUri: FRESHBOOKS_ACCESS_TOKEN_URI,
        authorizationUri: authUri ?? GATSBY_FRESHBOOKS_AUTH_LINK,
        redirectUri: redirectUri ?? GATSBY_FRESHBOOKS_REDIRECT_URI,
        headers: {
            'Access-Control-Allow-Origin': 'https://localhost',
        },
    });
    return fbAuth;
}

export function getFeesTable(appointments: Appointment[]) {
    const feesTable = appointments.reduce(
        (acc: Record<string, Appointment>, appointment: Appointment) => {
            if (
                isEmpty(appointment) ||
                appointment.roleType === RoleType.lead
            ) {
                return acc;
            }

            if (acc[appointment.id]) {
                return acc;
            }

            return {
                ...acc,
                [appointment.id]: appointment,
            };
        },
        {},
    );
    return feesTable;
}
