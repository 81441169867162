import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorRoot: {
            width: '100%',
            height: '100%',
            minHeight: 500,
            alignItems: 'center',
            margin: `${theme.spacing(2)}px 0`,
            background: 'rgba(255, 255, 255, 0.9)',
            padding: theme.spacing(2),
        },
        root: {
            margin: `${theme.spacing(2)}px 0`,
            background: 'rgba(255, 255, 255, 0.9)',
            padding: theme.spacing(2),
        },
        message: {
            paddingTop: theme.spacing(),
        },
        errorStatus: {
            color: theme.palette.error.dark,
        },
        okayStatus: {
            fontWeight: 300,
            color: theme.palette.success.dark,
        },
        warningStatus: {
            fontWeight: 300,
            color: theme.palette.warning.dark,
        },
        clientStatus: {
            fontWeight: 400,
        },
        appointmentRow: {
            paddingLeft: theme.spacing(2),
        },
        table: {
            minWidth: 650,
        },
    }),
);
