import * as React from 'react';
import { Grid, IconButton, Tooltip, Typography, GridProps } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import { isEmpty } from 'lodash-es';
import classnames from 'classnames';

import { useStyles } from './PageTitle.styles';

interface PageTitleProps {
    title: string;
    tooltip?: string;
    gridProps?: GridProps;
}

function PageTitle({ title, tooltip, gridProps = {} }: PageTitleProps) {
    const classes = useStyles();

    const { className = '' } = gridProps;

    return (
        <Grid container direction="row" className={classnames(className, classes.title)} alignItems="center">
            <Typography variant="h5">{title}</Typography>
            {!isEmpty(tooltip) && (
                <Grid item>
                    <Tooltip title={tooltip ?? ''} className={classes.tooltip} placement="right">
                        <IconButton aria-label="info-rounded">
                            <InfoRounded />
                        </IconButton>
                    </Tooltip>
                </Grid>
            )}
        </Grid>
    );
}

export default PageTitle;
