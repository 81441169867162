export const APPOINTMENTS_COLLECTION = 'appointments';
export const CALENDAR_COLLECTION = 'calendars';
export const CALENDAR_ROLES_COLLECTION = 'calendarRoles';
export const USERS_COLLECTION = 'users';
export const CONVERSIONS_COLLECTION = 'conversions';
export const RATES_COLLECTION = 'rates';
export const ROLES_COLLECTION = 'roles';
export const LEADS_COLLECTION = 'leads';
export const CLIENTS_COLLECTION = 'clients';
export const INVOICES_COLLECTION = 'invoices';
export const EXPENSES_COLLECTION = 'expenses';

export const RATES_DOC_PATH = 'calculations/rates';
export const CONVERSIONS_DOC_PATH = 'calculations/conversions';

export const RATE_TYPES = ['flat', 'adjust1', 'area', 'hourly'];
export const DEFAULT_RATE_TYPES_MAP = RATE_TYPES.reduce((acc, rateType) => {
    return {
        ...acc,
        [rateType]: false,
    };
}, {});
